import { Component, OnInit, OnDestroy, Input, ViewChild } from "@angular/core";
import { GlobalState } from "../../../global.state";
import { UsuarioService } from "app/servicios-backend/usuario.service";
import { Router } from "@angular/router";
import { ActualizacionService } from "app/comun/actualizacion.service";
import { Usuario } from "app/entidades/usuario";
import { Subscription } from "rxjs/Subscription";
import { HttpClient } from "@angular/common/http";
import { PaqueteTimbres } from "app/entidades/paquete-timbres";
import { PaqueteTimbresComponent } from "../paquete-timbres/paquete-timbres.component";
import { isEmpty } from "rxjs-compat/operator/isEmpty";
import { UsersService } from "app/users.service";

@Component({
  selector: "ba-page-top",
  templateUrl: "./baPageTop.html",
  styleUrls: ["./baPageTop.scss"],
})
export class BaPageTop implements OnInit, OnDestroy {
  quitarBoton: boolean = false;
  //empresa: Empresa = new Empresa();
  varCer: boolean = false;
  varReg: boolean = false;
  @Input() totalCertificados: number = 0;
  @Input() totalRegimenes: number = 0;
  _progreso: number = 0;
  valorBoton: string;
  valorProgress: number = 0;
  step: number = 0;
  @Input() noTimbres: number = 0;
  @Input() tipoEmpresa = "";
  @Input() diasFaltantes = 0;
  @Input() enEspera = false;

  condicionDFE: boolean;
  condicionCFDI: boolean;
  condicionCSD: boolean;
  condicionRF: boolean;

  variable1: boolean;
  variable2: boolean;
  variable3: boolean;
  variable4: boolean;
  isLite: boolean;
  showNoti: boolean = false;
  leyenda: string;

  @Input() parent: any;

  set progreso(value: number) {
    this._progreso = value;
    this.progresoCambio();
  }
  get progreso() {
    return this._progreso;
  }
  public isScrolled: boolean = false;
  public isMenuCollapsed: boolean = false;
  subscriptionUsuarioCambios: Subscription;
  _nombre: string;

  @ViewChild(PaqueteTimbresComponent) paqueteTimbres: PaqueteTimbresComponent;

  get nombre(): string {
    return this._nombre;
  }
  set nombre(value: string) {
    this._nombre = value;
  }
  usuarioEmpresa: Usuario = new Usuario();
  url = "";
  constructor(private _state: GlobalState, private router: Router, private actualizacionService: ActualizacionService, private usuarioService: UsuarioService, private http: HttpClient, private userService: UsersService) {
    this._state.subscribe("menu.isCollapsed", (isCollapsed) => {
      this.isMenuCollapsed = isCollapsed;
    });
  }
  ngOnInit() {
    this.nombre = localStorage.getItem("nombreUsuario");
    this.userService.nombreUsuario$.subscribe((nombre) => {
      this.nombre = nombre;
    });
    this.url = localStorage.getItem("urlUsuario") || "https://www.w3schools.com/howto/img_avatar.png";
    this.userService.urlUsuario$.subscribe((url) => {
      this.url = url;
    });

    this.actualizacionService.usuarioCambios.subscribe(
      (usuario: Usuario) => {
        this.usuarioEmpresa = usuario;
      },
      (error) => {
        console.log(error);
      }
    );
    this.checkImageServer();
    document.addEventListener("visibilitychange", function () {
      if (!document.hidden) {
        if (localStorage.getItem("caducidad")) {
          let now = new Date();
          if (now.getTime() > Number(localStorage.getItem("caducidad"))) {
            $("#expire_session_alert").modal("show");
          }
        } else {
          $("#expire_session_alert").modal("show");
        }
      }
    });
  }

  checkImageServer() {
    this.url = localStorage.getItem("urlUsuario") || "https://www.w3schools.com/howto/img_avatar.png";
  }

  ngOnDestroy(): void {
    if (this.subscriptionUsuarioCambios && this.subscriptionUsuarioCambios.closed) {
      this.subscriptionUsuarioCambios.unsubscribe();
    }
  }
  public toggleMenu() {
    this.isMenuCollapsed = !this.isMenuCollapsed;
    this._state.notifyDataChanged("menu.isCollapsed", this.isMenuCollapsed);
    return false;
  }
  public scrolledChanged(isScrolled) {
    setTimeout(() => {
      this.isScrolled = isScrolled;
    });
  }
  Signout() {
    this.usuarioService
      .logout()
      .then((data) => {
        if (data) {
          this.router.navigate(["login"]).then(() => {
            window.location.reload();
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  
  prepareTimbres() {
    $("#paqueteTimbresModal").modal({ backdrop: "static", keyboard: false }, "show");
  }
  progresoCambio() {
    if (this.condicionDFE) {
      $("#dfeModal").modal({ backdrop: "static", keyboard: false }, "show");
      this.quitarBoton = true;
      this.variable1 = true;
    } else if (this.condicionCFDI) {
      $("#cfdiModal").modal({ backdrop: "static", keyboard: false }, "show");
      this.quitarBoton = true;
      this.variable2 = true;
    } else if (this.condicionCSD) {
      $("#csdModal").modal({ backdrop: "static", keyboard: false }, "show");
      this.quitarBoton = true;
      this.variable3 = true;
    } else if (this.condicionRF) {
      $("#rfModal").modal({ backdrop: "static", keyboard: false }, "show");
      this.quitarBoton = true;
      this.variable4 = true;
    } else {
      this.quitarBoton = false;
      if ((this.tipoEmpresa == null || this.tipoEmpresa.length == 0) && !this.enEspera) {
        $("#paqueteTimbresModal").modal({ backdrop: "static", keyboard: false }, "show");
      }
    }
  }

  quitarCompletarFunction() {
    this.quitarBoton = false;
  }

  toHome() {
    this.router.navigate([localStorage.getItem("home")]);
  }

  updateProgrees(dataEmp: any) {
    let dataEmpe = dataEmp["dataEmp"];
    this.totalCertificados = dataEmp["totCer"];
    this.totalRegimenes = dataEmp["totReg"];
    let dRePa = dataEmpe.registroPatronal == undefined || dataEmpe.registroPatronal == null || dataEmpe.registroPatronal == 0 || dataEmpe.registroPatronal == " ";
    let dRiPu = dataEmpe.riesgoPuesto == undefined || dataEmpe.riesgoPuesto == null || dataEmpe.riesgoPuesto.id == undefined || dataEmpe.riesgoPuesto.id == null || dataEmpe.riesgoPuesto.id == 0 || dataEmpe.riesgoPuesto.id == " ";
    let dLuEx = dataEmpe.lugarExpedicion == undefined || dataEmpe.lugarExpedicion == null || dataEmpe.lugarExpedicion == 0 || dataEmpe.lugarExpedicion == " ";
    let dCeId = dataEmpe.cedulaIdentificacionFiscal == undefined || dataEmpe.cedulaIdentificacionFiscal == null || dataEmpe.cedulaIdentificacionFiscal == 0 || dataEmpe.cedulaIdentificacionFiscal == " ";

    this.condicionDFE = /*dRFC &&*/ dRePa && dRiPu && dLuEx;
    this.condicionCFDI = dCeId;
    this.condicionCSD = this.totalCertificados == 0;
    this.condicionRF = this.totalRegimenes == 0;

    let condictions = [this.condicionDFE, this.condicionCFDI, this.condicionCSD, this.condicionRF];
    this.progreso = condictions.filter((val) => !val).length;
    this.valorProgress = (100 * this.progreso) / 4;
    this.valorBoton = this.valorProgress + "%";
    this.step = this.progreso + 1;
  }

  reloadEmpresa() {
    this.parent.reloadEmpresa();
  }

  calcTimbres(tipoEmpresa, noTimbres, diasFaltantes) {
    this.tipoEmpresa = tipoEmpresa;
    this.noTimbres = noTimbres;
    this.diasFaltantes = diasFaltantes;
    this.isLite = this.tipoEmpresa == "Lite";

    if (this.isLite) {
      this.leyenda = this.diasFaltantes == 0 ? "Tu paquete a cadudado" : "Para terminar tu paquete";
    }
    this.showNoti = this.tipoEmpresa != "Premium+" ? true : false;
    this.paqueteTimbres.calcularTimbres(tipoEmpresa, diasFaltantes, noTimbres);
  }
  onCheckEmpty() {
    this.usuarioService
      .authenticated()
      .then((data) => {
        if (!data) {
          this.router.navigate(["login"]);
        }
        this.reloadEmpresa();
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
