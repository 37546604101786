import { Injectable } from "@angular/core";
import { HttpParams, HttpClient } from "@angular/common/http";
import { Concepto } from "../entidades/concepto";
import { Producto } from "../entidades/producto";
import { Unidad } from "../entidades/unidad";
import { HttpHelper } from "../http-helper";
import "rxjs/add/operator/toPromise";

@Injectable()
export class ConceptoService {
  private headers = HttpHelper.headersJSON;
  private conceptoUrl = HttpHelper.url + "conceptos";

  constructor(private http: HttpClient) {}

  private handleError(error: any): Promise<any> {
    let resp = error;
    if (resp.error) {
      console.error("ConceptoService - ERROR: ", resp.error);
      return Promise.reject(resp.error);
    }
    return Promise.reject(error.message || error);
  }

  getDetalle(id: number) {
    const url = `${this.conceptoUrl}/detalle/${id}`;
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .get<any>(url, { headers: this.headers })
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  update(dataConcepto: Concepto): Promise<any> {
    const url = `${this.conceptoUrl}/update`;
    let token = localStorage.getItem("token");
    this.headers = this.headers.set("token", token);
    return this.http
      .post<any>(url, JSON.stringify(dataConcepto), { headers: this.headers })
      .toPromise()
      .then((res) => res as Promise<any>)
      .catch(this.handleError);
  }

  create(dataConcepto: Concepto): Promise<any> {
    const url = `${this.conceptoUrl}/crear`;
    let token = localStorage.getItem("token");
    this.headers = this.headers.set("token", token);
    if (token) {
      this.headers = this.headers.set("token", token);
      console.log("url: " + url);
      return this.http
        .post<any>(url, JSON.stringify(dataConcepto), { headers: this.headers })
        .toPromise()
        .then((res) => res as Promise<any>)
        .catch(this.handleError);
    }
  }

  remove(id: number): Promise<any> {
    const url = `${this.conceptoUrl}/eliminar/${id}`;
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .get<any>(url, { headers: this.headers })
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  getList(filterQuery: string, sortBy: string, sortOrder: string, rowsOnPage: number, page: number) {
    const url = `${this.conceptoUrl}/list`;
    let params = new HttpParams();
    if (filterQuery) params = params.append("filterQuery", filterQuery);

    if (sortBy) params = params.append("sortBy", sortBy);

    if (sortOrder) {
      params = params.append("sortOrder", sortOrder);
    }
    if (rowsOnPage) {
      params = params.append("rowsOnPage", rowsOnPage + "");
    }
    if (page) {
      params = params.append("page", page + "");
    }
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      let options = { headers: this.headers, params: params }; // Create a request option
      return this.http
        .get<any>(url, options)
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  getDummyData(filterQuery: string, sortBy: string, sortOrder: string, rowsOnPage: number, page: number) {
    let params = new HttpParams();
    if (filterQuery) params = params.append("filterQuery", filterQuery);

    if (sortBy) params = params.append("sortBy", sortBy);

    if (sortOrder) {
      params = params.append("sortOrder", sortOrder);
    }
    if (rowsOnPage) {
      params = params.append("rowsOnPage", rowsOnPage + "");
    }
    if (page) {
      params = params.append("page", page + "");
    }
    console.log(params);
  }

  autocomplete(filterQuery: string) {
    let params = new HttpParams();
    const url = `${this.conceptoUrl}/autocomplete`;
    if (filterQuery) {
      params = params.append("filterQuery", filterQuery);
    }
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      let options = { headers: this.headers, params: params }; // Create a request option
      return this.http
        .get<any>(url, options)
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  autocompleteUnidad(filterQuery: string): Promise<any> {
    const url = `${this.conceptoUrl}/autocompleteUnidad/${filterQuery}`;
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .get<any>(url, { headers: this.headers })
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  autocompleteProducto(filterQuery: string): Promise<any> {
    const url = `${this.conceptoUrl}/autocompleteProducto/${filterQuery}`;
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .get<any>(url, { headers: this.headers })
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  autocompleteMaterialPeligroso(filterQuery: string): Promise<any> {
    const url = `${this.conceptoUrl}/autocompleteMaterialPeligroso/${filterQuery}`;
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .get<any>(url, { headers: this.headers })
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  getDetalleProducto(id: number) {
    const url = `${this.conceptoUrl}/detalleProducto/${id}`;
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .get<any>(url, { headers: this.headers })
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  getDetalleMaterialPeligroso(id: number) {
    const url = `${this.conceptoUrl}/detalleMaterialPeligroso/${id}`;
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .get<any>(url, { headers: this.headers })
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  getDetalleUnidad(id: number) {
    const url = `${this.conceptoUrl}/detalleUnidad/${id}`;
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .get<any>(url, { headers: this.headers })
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  getCSVInfo() {
    let token = localStorage.getItem("token");
    if (token) {
      const url = `${this.conceptoUrl}/conceptoCSV/${token}`;
      window.location.href = url;
    }
  }

  getDetalleConcepto(id: number) {
    const url = `${this.conceptoUrl}/detalle/${id}`;
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .get<any>(url, { headers: this.headers })
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }
}
