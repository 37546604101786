import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private nombreUsuarioSource = new BehaviorSubject<string>(localStorage.getItem("nombreUsuario"));
  private urlUsuarioSource = new BehaviorSubject<string>(localStorage.getItem("urlUsuario") || 'https://www.w3schools.com/howto/img_avatar.png');
  
  nombreUsuario$ = this.nombreUsuarioSource.asObservable();
  urlUsuario$ = this.urlUsuarioSource.asObservable();

  updateNombreUsuario(nombre: string) {
    localStorage.setItem("nombreUsuario", nombre);
    this.nombreUsuarioSource.next(nombre);
  }

  updateUrlUsuario(url: string) {
    localStorage.setItem("urlUsuario", url);
    this.urlUsuarioSource.next(url);
  }
}