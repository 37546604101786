import { Routes, RouterModule } from "@angular/router";
import { ModuleWithProviders } from "@angular/core";
import { NgaModule } from "./theme/nga.module";

export const routes: Routes = [
  {
    path: "login",
    loadChildren: () => import("app/pages/login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "register",
    loadChildren: () => import("app/pages/register/register.module").then((m) => m.RegisterModule),
  },

  { path: "", redirectTo: "login", pathMatch: "full" },
  { path: "dashboard", redirectTo: "pages/dashboard" },
  { path: "empresas", redirectTo: "pages/miempresa/empresa" },
  { path: "bancos", redirectTo: "pages/cuentas/cuentasgestion" },
  { path: "usuarios", redirectTo: "pages/usuarios" },
  { path: "facturas", redirectTo: "pages/comprobantesFiscales" },
  { path: "nominas", redirectTo: "pages/nomina/cfdi" },
  { path: "ingresos", redirectTo: "pages/listaIngresos" },
  { path: "egresos", redirectTo: "pages/listaEgresos" },
  { path: "reportes", redirectTo: "pages/dashboard" },
];

export const routing: ModuleWithProviders<NgaModule> = RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' });
