import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from "@angular/forms";
import { ObligacionesFiscalesPerfil } from "app/entidades/obligaciones-fiscales-perfil";
import { PaqueteTimbres } from "app/entidades/paquete-timbres";
import { PerfilEmpresa } from "app/entidades/perfil-empresa";
import { PaqueteTimbresService } from "app/servicios-backend/paquete-timbres.service";
import { SharedService } from "app/shared.service";

@Component({
  selector: "app-paquete-timbres",
  templateUrl: "./paquete-timbres.component.html",
  styleUrls: ["./paquete-timbres.component.scss"],
})
export class PaqueteTimbresComponent implements OnInit {
  @Input() parent: any;
  @Input() tipoEmpresa: string;
  @Input() diasFaltantes = 0;
  @Input() noTimbres = 0;
  disabledPremium = false;
  disabledPyme = false;
  disabledLite = false;
  textLiteButton = "Pagar Plan";
  textPymeButton = "Pagar Plan";
  textPremiumButton = "Pagar Plan";
  paquetesTimbres: PaqueteTimbres[] = [];
  messageLite: String = null;
  messagePyme: String = null;
  messagePremium: String = null;
  paqueteSelected: string;
  textConfimacion: String;
  iswaith: boolean = false;
  personas = ["fisica", "moral"];
  tipoPersona: string = "fisica";
  ingresos = ["PUE", "PPD"];
  checkboxFields = ["declara_iva", "pago_def_men_iva", "pago_pro_men_isr", "ajus_an_isr", "declara_isr_anual", "rete_men_isr", "declara_info_anual", "otra_obl"];
  tipoIngreso: string = "PUE";
  cuestionarioForm: UntypedFormGroup;
  personaFisica = null;
  personaMoral = null;
  selectRegimen = null;
  noFacturas = null;
  noTrabajadores = null;
  selectRotacion = null;
  fisicaList = ["", "Asalariado", "Honorarios o servicios profesionales", "Actividad empresarial (papelería, cafetería, etc.)", "Incorporación fiscal"];
  moralList = ["", "Sociedad por Acciones Simplificada", "Sociedad Anónima", "Sociedad de Responsabilidad Limitada", "Sociedad Cooperativa"];
  regimenList = ["", "Régimen Simplificado de Confianza", "Régimen de Actividades Empresariales con ingresos a través de Plataformas Tecnológicas", "Régimen de Arrendamiento", "Régimen de Actividades Empresariales y Profesionales", "General de Ley Personas Morales", "Personas Morales con Fines No Lucrativos", "Residentes en el Extranjero sin Establecimiento Permanente en México", "Sociedades Cooperativas de Producción que optan por diferir sus ingresos", "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras", "Coordinados", "Ingresos por Dividendos (socios y accionistas)", "Ingresos por intereses", "Régimen de los ingresos por obtención de premios", "Sueldos y Salarios e Ingresos Asimilados a Salarios", "Incorporación Fiscal"];
  noFacturaList = ["", "De 1 a 10", "De 11 a 50", "De 50 a 100", "Mas de 100"];
  noTrabaList = ["", "De 1 a 5", "De 5 a 10", "De 10 a 50", "Mas de 50"];
  rotatioList = ["", "Baja", "Media", "Alta"];

  constructor(private paqueteService: PaqueteTimbresService, private sharedService: SharedService, private fb: UntypedFormBuilder) {
    this.paqueteService = paqueteService;
  }

  ngOnInit(): void {
    this.cuestionarioForm = this.fb.group(
      {
        persona: [""],
        persona_fisica: [""],
        persona_moral: [""],
        regimen: ["", Validators.required],
        actividad_detalle: ["", Validators.required],
        declara_iva: [false],
        pago_def_men_iva: [false],
        pago_pro_men_isr: [false],
        ajus_an_isr: [false],
        declara_isr_anual: [false],
        rete_men_isr: [false],
        declara_info_anual: [false],
        otra_obl: [""],
        no_facturas: ["", Validators.required],
        ingreso: [""],
        is_gral: [false],
        no_trab: ["", Validators.required],
        rotacion: ["", Validators.required],
        is_inventario: [false],
      },
      {
        validator: this.atLeastOneCheckboxSelected(this.checkboxFields),
      }
    );
    this.selectperson();
  }

  preparePaquete(tipo: string) {
    this.paqueteSelected = tipo;
    $("#paqueteTimbresInstrucciones").modal({ backdrop: "static", keyboard: false }, "show");
  }

  selectperson() {
    if (this.tipoPersona == "fisica") {
      this.cuestionarioForm.get("persona_fisica").setValidators([Validators.required]);
      this.cuestionarioForm.get("persona_moral").clearValidators();
    }
    if (this.tipoPersona == "moral") {
      this.cuestionarioForm.get("persona_fisica").clearValidators();
      this.cuestionarioForm.get("persona_moral").setValidators([Validators.required]);
    }
    this.cuestionarioForm.get("persona_fisica").setValue(null);
    this.cuestionarioForm.get("persona_moral").setValue(null);
  }

  atLeastOneCheckboxSelected(checkboxFields: string[]): ValidatorFn {
    return (control: AbstractControl) => {
      const selectedCheckboxes = checkboxFields.some((field) => control.get(field).value);

      if (!selectedCheckboxes) {
        return { atLeastOneCheckboxSelected: true };
      }
      return null;
    };
  }

  getCheckboxFieldsetClass(): string {
    const atLeastOneSelected = this.checkboxFields.some((field) => this.cuestionarioForm.get(field).value);
    return atLeastOneSelected ? "success-shadow-border" : "alert-shadow-border";
  }

  onSubmitAgregar(tipo: string) {
    $("#paqueteTimbresInstrucciones").modal("hide");
    let paqueteTimbres = new PaqueteTimbres();
    paqueteTimbres.tipo = tipo;
    paqueteTimbres.vigente = false;
    paqueteTimbres.enEspera = true;
    this.paqueteService
      .create(paqueteTimbres)
      .then((res) => {
        $("#paqueteTimbresModal").modal("hide");
        this.parent.reloadEmpresa();
      })
      .catch((error) => {
        $("#paqueteTimbresModal").modal("hide");
        this.parent.reloadEmpresa();
      });
  }

  sendPerfil(tipo: string) {
    let perfil = new PerfilEmpresa();
    perfil.persona = this.tipoPersona;
    perfil.tipoPersona = this.tipoPersona == "fisica" ? this.fisicaList[this.personaFisica] : this.moralList[this.personaMoral];
    perfil.regimenFiscal = this.regimenList[this.selectRegimen];
    perfil.actividadEconomica = this.cuestionarioForm.get("actividad_detalle").value;
    perfil.obligacionesFiscalesPerfilList = [];
    this.checkboxFields.forEach((field) => {
      if (this.cuestionarioForm.get(field).value == true && field != "otra_obl") {
        let obliga = new ObligacionesFiscalesPerfil();
        obliga.llave = field;
        obliga.obligacion = document.getElementById(field + "_lab").innerText;
        perfil.obligacionesFiscalesPerfilList.push(obliga);
      } else if (field == "otra_obl") {
        if (!this.isEmpty(this.cuestionarioForm.get(field).value)) {
          let obliga = new ObligacionesFiscalesPerfil();
          obliga.llave = field;
          obliga.obligacion = this.cuestionarioForm.get(field).value;
          perfil.obligacionesFiscalesPerfilList.push(obliga);
        }
      }
    });
    perfil.noFacturas = this.noFacturaList[this.noFacturas];
    perfil.tipoIngresos = this.tipoIngreso;
    perfil.facturasPublicoGral = this.cuestionarioForm.get("is_gral").value;
    perfil.noTrabajadores = this.noTrabaList[this.noTrabajadores];
    perfil.rotacionPersonal = this.rotatioList[this.selectRotacion];
    perfil.manejaInventario = this.cuestionarioForm.get("is_inventario").value;
    this.paqueteService
      .createPerfil(perfil)
      .then((res) => {
        this.onSubmitAgregar(tipo);
      })
      .catch((error) => {
        $("#paqueteTimbresModal").modal("hide");
        this.parent.reloadEmpresa();
      });
  }
  isEmpty(value) {
    return value == null || value.length === 0 || value === undefined;
  }

  getPaquetesEnEspera(completed) {
    this.paqueteService
      .getPaquetesEnEspera()
      .then((dataPaquetes) => {
        this.paquetesTimbres = dataPaquetes.data;
        completed();
      })
      .catch((error) => {
        console.log(error);
        completed();
      });
  }

  calcularTimbres(tipoEmpresa, diasFaltantes, noTimbres) {
    this.iswaith = false;
    this.messageLite = null;
    this.messagePyme = null;
    this.messagePremium = null;
    this.disabledPremium = false;
    this.disabledPyme = false;
    this.disabledLite = false;
    this.textLiteButton = "Pagar Plan";
    this.textPymeButton = "Pagar Plan";
    this.textPremiumButton = "Pagar Plan";
    this.getPaquetesEnEspera(() => {
      this.tipoEmpresa = tipoEmpresa;
      this.diasFaltantes = diasFaltantes;
      this.noTimbres = noTimbres;
      switch (this.tipoEmpresa) {
        case "Lite":
          this.disabledLite = true;
          this.disabledPyme = false;
          this.disabledPremium = false;
          if (this.diasFaltantes == 0) {
            this.disabledLite = false;
            this.textLiteButton = "Renovar Plan";
          }
          break;
        case "Pyme":
          this.disabledLite = false;
          this.disabledPyme = true;
          this.disabledPremium = false;
          if (this.noTimbres == 0) {
            this.disabledPyme = false;
            this.textPymeButton = "Renovar Plan";
          }
          break;
        case "Premium":
          this.disabledLite = false;
          this.disabledPyme = false;
          this.disabledPremium = true;
          if (this.noTimbres == 0) {
            this.disabledPremium = false;
            this.textPremiumButton = "Renovar Plan";
          }
          break;
      }
      this.paquetesTimbres.forEach((paquete) => {
        this.iswaith = true;
        switch (paquete.tipo) {
          case "Lite":
            this.messageLite = "Este paquete esta en espera de aprobación.";
            this.disabledLite = true;
            this.textConfimacion = "Usted cuenta con un paquete Lite en espera de respuesta.";
            break;
          case "Pyme":
            this.messagePyme = "Este paquete esta en espera de aprobación.";
            this.disabledPyme = true;
            this.textConfimacion = "Usted cuenta con un paquete Pymes en espera de respuesta.";
            break;
          case "Premium":
            this.messagePremium = "Este paquete esta en espera de aprobación.";
            this.disabledPremium = true;
            this.textConfimacion = "Usted cuenta con un paquete Premium en espera de respuesta.";
            break;
        }
      });
    });
  }
}
