import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { EmpresaService } from "app/servicios-backend/empresa.service";
import { CertificadoService } from "app/servicios-backend/certificado.service";
import { Empresa } from "app/entidades/empresa";
import { NgForm, UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl } from "@angular/forms";
import { RiesgoPuesto } from "app/entidades/riesgo-puesto";
import { AutocompleteGenericComponent } from "app/comun/components/autocomplete-generic";
import { RegimenFiscalService } from "app/servicios-backend/regimen-fiscal.service";
import { CpService } from "app/servicios-backend/cp.service";
import { DatePipe } from "@angular/common";
import { isEmpty } from "rxjs/operator/isEmpty";
import { SharedService } from "app/shared.service";

@Component({
  selector: "app-registra-dfe",
  templateUrl: "./registra-dfe.component.html",
  styleUrls: ["./registra-dfe.component.scss"],
  providers: [DatePipe],
})
export class RegistraDFEComponent implements OnInit {
  empresa: Empresa = new Empresa();
  formDFE: UntypedFormGroup;
  public rfc: AbstractControl;
  public registroPatronal: AbstractControl;
  public riesgoPuesto: AbstractControl;
  public calle: AbstractControl;
  public numInterior: AbstractControl;
  public numExterior: AbstractControl;
  public cp: AbstractControl;
  public colonia: AbstractControl;
  public municipio: AbstractControl;
  public estado: AbstractControl;
  public pais: AbstractControl;
  public submitted: boolean = false;
  public mensajeError: string;
  public error: boolean;
  public showRed: string;
  public isactiveriesgo: boolean = false;
  public isDonatarias: boolean = false;
  public isDonatariasCheck: AbstractControl;
  public isTransporte: boolean = false;
  public isTransportCheck: AbstractControl;
  fechaAut;
  cliente;
  public fechaAutorizacion: AbstractControl;
  public noAutorizacion: AbstractControl;
  public leyendaDon: AbstractControl;
  rowsOnPage: number = 10;
  sortBy: string;
  sortOrder: string = "asc";
  page: number = 1;
  totalCertificados: number = 0;
  totalRegimenes: number = 0;
  query: string = "";
  rfcValid: string;
  sucursal: string;
  colonias = [];
  coloniaEmpresa = null;
  @Input() progress = "";
  @Input() progressVal = 0;
  @Input() step: number = 0;
  @Output() updateProgrees = new EventEmitter<{ dataEmp: any; totCer: number; totReg: number }>();
  public nameButton = "";
  @ViewChild("autocompleteRiesgo")
  autocompleteRiesgo: AutocompleteGenericComponent;
  @ViewChild("autocompleteCodigoPostal")
  autocompleteCodigoP: AutocompleteGenericComponent;
  constructor(private empresaService: EmpresaService, private certificadoService: CertificadoService, fb: UntypedFormBuilder, private regimenFiscalService: RegimenFiscalService, private cpService: CpService, public datepipe: DatePipe, private sharedService: SharedService) {
    this.formDFE = fb.group({
      id: [""],
      nombre: [""],
      colonia: [""],
      localidad: [""],
      referencia: [""],
      municipio: [""],
      estado: [""],
      pais: [""],
      rfc: [
        "",
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(13),
          //Validators.pattern(/^[A-Z]{3,4}\d{6}(?:[A-Z\d]{3})?$/)
        ],
      ],
      registroPatronal: ["", [Validators.minLength(5), Validators.maxLength(20)]],
      riesgoPuesto: [""],
      rvoeEmpresa: [""],
      isDonatariasCheck: [""],
      isTransportCheck: [""],
      fechaAutorizacion: [""],
      noAutorizacion: [""],
      leyendaDon: [""],
      objImpuesto: [""],
      calle: ["", Validators.maxLength(100)],
      numInterior: ["", Validators.maxLength(10)],
      numExterior: ["", Validators.maxLength(10)],
      cp: ["", Validators.required],
    });

    this.rfc = this.formDFE.controls["rfc"];
    this.registroPatronal = this.formDFE.controls["registroPatronal"];
    this.riesgoPuesto = this.formDFE.controls["riesgoPuesto"];
    this.calle = this.formDFE.controls["calle"];
    this.numInterior = this.formDFE.controls["numInterior"];
    this.numExterior = this.formDFE.controls["numExterior"];
    this.cp = this.formDFE.controls["cp"];
    this.colonia = this.formDFE.controls["colonia"];
    this.municipio = this.formDFE.controls["municipio"];
    this.estado = this.formDFE.controls["estado"];
    this.pais = this.formDFE.controls["pais"];
    this.isDonatariasCheck = this.formDFE.controls["isDonatariasCheck"];
    this.fechaAutorizacion = this.formDFE.controls["fechaAutorizacion"];
    this.noAutorizacion = this.formDFE.controls["noAutorizacion"];
    this.leyendaDon = this.formDFE.controls["leyendaDon"];
    this.isTransportCheck = this.formDFE.controls["isTransportCheck"];
  }

  ngOnInit() {
    this.llenarFormDFE();
  }

  rfcValido(rfc, aceptarGenerico = true) {
    const re = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
    let validado = rfc.match(re);

    if (!validado)
      //Coincide con el formato general del regex?
      return false;

    //Separar el dígito verificador del resto del RFC
    const digitoVerificador = validado.pop(),
      rfcSinDigito = validado.slice(1).join(""),
      len = rfcSinDigito.length,
      //Obtener el digito esperado
      diccionario = "0123456789ABCDEFGHIJKLMN&OPQRSTUVWXYZ Ñ",
      indice = len + 1;
    let suma, digitoEsperado;

    if (len == 12) suma = 0;
    else suma = 481; //Ajuste para persona moral

    for (let i = 0; i < len; i++) suma += diccionario.indexOf(rfcSinDigito.charAt(i)) * (indice - i);
    digitoEsperado = 11 - (suma % 11);
    if (digitoEsperado == 11) digitoEsperado = 0;
    else if (digitoEsperado == 10) digitoEsperado = "A";

    //El dígito verificador coincide con el esperado?
    // o es un RFC Genérico (ventas a público general)?

    if ((digitoVerificador != digitoEsperado && (!aceptarGenerico || rfcSinDigito + digitoVerificador != "XAXX010101000")) || (!aceptarGenerico && rfcSinDigito + digitoVerificador == "XEXX010101000")) {
      return false;
    }
    return rfcSinDigito + digitoVerificador;
  }

  onKey(event) {
    const inputValue = event.target.value;
    let rfc = inputValue,
      resultado = document.getElementById("resultRFC");
    let rfcCorrecto = this.rfcValido(rfc);
    if (rfcCorrecto) {
      this.rfcValid = "si";
      resultado.classList.add("ok");
    } else {
      this.rfcValid = "no";
      resultado.classList.remove("ok");
    }
  }

  checkRFC(irfc: string) {
    const inputValue = irfc;
    let rfc = inputValue,
      resultado = document.getElementById("resultRFC");
    let rfcCorrecto = this.rfcValido(rfc);
    if (rfcCorrecto) {
      this.rfcValid = "si";
      resultado.classList.add("ok");
    } else {
      this.rfcValid = "no";
      resultado.classList.remove("ok");
    }
  }

  btnNext() {
    this.empresaService
      .getDetalle()
      .then((dataEmp: any) => {
        this.certificadoService
          .getCertidicados(this.query, this.sortBy, this.sortOrder, this.rowsOnPage, this.page)
          .then((dataCert) => {
            this.totalCertificados = dataCert.total;
            this.regimenFiscalService
              .getRegimenes(this.query, this.sortBy, this.sortOrder, this.rowsOnPage, this.page)
              .then((dataReg) => {
                this.totalRegimenes = dataReg.total;
                this.empresa = dataEmp;
                let dLogo = dataEmp.logo == undefined || dataEmp.logo == null || dataEmp.logo == 0 || dataEmp.logo == " ";
                let dCeId = dataEmp.cedulaIdentificacionFiscal == undefined || dataEmp.cedulaIdentificacionFiscal == null || dataEmp.cedulaIdentificacionFiscal == 0 || dataEmp.cedulaIdentificacionFiscal == " ";
                let condicionCFDI = dLogo && dCeId;
                let condicionCSD = this.totalCertificados == 0;
                let condicionRF = this.totalRegimenes == 0;
                this.updateProgrees.emit({ dataEmp: dataEmp, totCer: this.totalCertificados, totReg: this.totalRegimenes });
                if (condicionCFDI) {
                  $("#dfeModal").modal("hide");
                  $("#cfdiModal").modal({ backdrop: "static", keyboard: false }, "show");
                } else if (condicionCSD) {
                  $("#dfeModal").modal("hide");
                  $("#csdModal").modal({ backdrop: "static", keyboard: false }, "show");
                } else if (condicionRF) {
                  $("#dfeModal").modal("hide");
                  $("#rfModal").modal({ backdrop: "static", keyboard: false }, "show");
                } else {
                  $("#dfeModal").modal("hide");
                  this.sharedService.callParentMethod("reloadEmpresa");
                  //window.location.reload();
                }
              })
              .catch((error) => {
                console.log("Hubo un error", error);
              });
          })
          .catch((error) => {
            console.log("Hubo un error", error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  btnOM() {
    $("#dfeModal").modal("hide");
  }

  onSubmitDFE() {
    this.mensajeError = undefined;
    this.submitted = true;
    let codPost = this.formDFE.get("cp").value;
    if (this.rfcValid == "si") {
      if (codPost != " " && codPost != 0 && this.sucursal != undefined) {
        /*(this.formDFE.valid)*/ let dataEmpresa: Empresa = new Empresa();
        let riesgoPuesto = new RiesgoPuesto();
        dataEmpresa.id = this.formDFE.get("id").value;
        dataEmpresa.nombre = this.formDFE.get("nombre").value;
        dataEmpresa.colonia = this.coloniaEmpresa;
        dataEmpresa.localidad = this.formDFE.get("localidad").value;
        dataEmpresa.referencia = this.formDFE.get("referencia").value;
        dataEmpresa.municipio = this.formDFE.get("municipio").value;
        dataEmpresa.estado = this.formDFE.get("estado").value;
        dataEmpresa.pais = this.formDFE.get("pais").value;
        dataEmpresa.rfc = this.formDFE.get("rfc").value.toUpperCase();
        dataEmpresa.registroPatronal = this.formDFE.get("registroPatronal").value;
        dataEmpresa.rvoeEmpresa = this.formDFE.get("rvoeEmpresa").value;
        if (dataEmpresa.registroPatronal != undefined) {
          dataEmpresa.registroPatronal = dataEmpresa.registroPatronal.toUpperCase();
        }
        console.log(this.formDFE.get("riesgoPuesto").value);
        if (this.formDFE.get("riesgoPuesto").value >= 1 && this.formDFE.get("riesgoPuesto").value <= 5) {
          riesgoPuesto.id = this.formDFE.get("riesgoPuesto").value;
        } else {
          riesgoPuesto.id = 0;
        }
        dataEmpresa.riesgoPuesto = riesgoPuesto;
        dataEmpresa.calle = this.formDFE.get("calle").value;
        dataEmpresa.numInterior = this.formDFE.get("numInterior").value;
        dataEmpresa.numExterior = this.formDFE.get("numExterior").value;
        dataEmpresa.lugarExpedicion = this.formDFE.get("cp").value;
        dataEmpresa.esDonataria = this.isDonatarias;
        if (this.isDonatarias) {
          let offset = new Date().getTimezoneOffset();
          let fechaPago = new Date(this.formDFE.get("fechaAutorizacion").value);
          let now = new Date();
          fechaPago.setHours(fechaPago.getHours() + now.getHours());
          fechaPago.setMinutes(fechaPago.getMinutes() + now.getMinutes());
          fechaPago.setSeconds(fechaPago.getSeconds() + now.getSeconds());
          dataEmpresa.fechaDonatarias = new Date(fechaPago.getTime() + offset * 60000).getTime();
          dataEmpresa.noDonatarias = this.formDFE.get("noAutorizacion").value;
          dataEmpresa.leyendaDonatarias = this.formDFE.get("leyendaDon").value;
        } else {
          dataEmpresa.fechaDonatarias = null;
          dataEmpresa.noDonatarias = null;
          dataEmpresa.leyendaDonatarias = null;
        }
        dataEmpresa.objImpuesto = this.formDFE.get("objImpuesto").value;
        dataEmpresa.esEquipoTransporte = this.isTransporte;
        dataEmpresa.ver = true;
        this.empresaService
          .update(dataEmpresa)
          .then((data: any) => {
            this.btnNext();
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.mensajeError = "Favor de llenar los campos obligatorios marcados con *";
        this.showRed = "si";
        this.error = true;
      }
    } else if (this.rfcValid == "no") {
      this.mensajeError = "El RFC no es valido";
      this.showRed = "no";
      this.error = true;
    }
  }

  llenarFormDFE() {
    this.empresaService.getDetalle().then((dataEmp: any) => {
      this.formDFE.get("id").setValue(dataEmp.id);
      this.formDFE.get("nombre").setValue(dataEmp.nombre);
      this.coloniaEmpresa = dataEmp.colonia;
      this.formDFE.get("localidad").setValue(dataEmp.localidad);
      this.formDFE.get("referencia").setValue(dataEmp.referencia);
      this.formDFE.get("pais").setValue(dataEmp.pais);
      this.formDFE.get("rfc").setValue(dataEmp.rfc);
      this.formDFE.get("registroPatronal").setValue(dataEmp.registroPatronal);
      this.formDFE.get("calle").setValue(dataEmp.calle);
      this.formDFE.get("numInterior").setValue(dataEmp.numInterior);
      this.formDFE.get("numExterior").setValue(dataEmp.numExterior);
      this.formDFE.get("riesgoPuesto").setValue(dataEmp.riesgoPuesto);
      if (dataEmp.lugarExpedicion) {
        this.autocompleteCodigoP.getLugarExpedicion(dataEmp.lugarExpedicion);
        this.formDFE.get("cp").setValue(dataEmp.lugarExpedicion);
      }
      this.checkRFC(dataEmp.rfc);
      if ((dataEmp.fechaDonatarias != undefined && dataEmp.fechaDonatarias != null) || (dataEmp.noDonatarias != undefined && dataEmp.noDonatarias != null)) {
        this.isDonatarias = true;
        this.formDFE.get("isDonatariasCheck").setValue(1);
        this.fechaAut = this.datepipe.transform(new Date(dataEmp.fechaDonatarias), "yyyy-MM-dd");
        this.formDFE.get("fechaAutorizacion").setValue(this.fechaAut);
        this.formDFE.get("noAutorizacion").setValue(dataEmp.noDonatarias);
        this.formDFE.get("leyendaDon").setValue(dataEmp.leyendaDonatarias);
      }
      if (dataEmp.objImpuesto != undefined && dataEmp.objImpuesto != null) {
        this.formDFE.get("objImpuesto").setValue(dataEmp.objImpuesto);
      } else {
        this.formDFE.get("objImpuesto").setValue(0);
      }
    });
  }

  onCodigoPostalSelected(cp: any) {
    this.colonias = [];
    if (cp !== undefined && cp != null && cp > 0) {
      this.cpService
        .consultarLocalizacion(cp)
        .then((data) => {
          this.formDFE.get("cp").setValue(cp);
          this.formDFE.get("municipio").setValue(data.data.municipio);
          this.formDFE.get("estado").setValue(data.data.estado);
          this.formDFE.get("pais").setValue("México");
          for (let colonia of data.data.colonia) {
            this.colonias.push(colonia);
          }
          if (this.colonias.length == 1) {
            this.coloniaEmpresa = this.colonias[0];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.formDFE.get("cp").setValue(0);
    }
  }

  checkDonatarias(respuestacheck: any) {
    this.isDonatarias = respuestacheck.target.checked;
    if (this.isDonatarias) {
      this.formDFE.get("objImpuesto").setValue(1);
    }
  }

  checkTransporte(respuestacheck: any) {
    this.isTransporte = respuestacheck.target.checked;
  }

  checkSucursal() {
    this.sucursal = "checado";
  }

  activeRiesgo(text: any) {
    let texto = text.target.value;
    if (texto.replace(/(^\s+|\s+$)/g, "").length == 0) {
      this.isactiveriesgo = false;
      this.formDFE.get("registroPatronal").setValue(null);
      this.formDFE.get("riesgoPuesto").setValue(null);
      this.formDFE.get("riesgoPuesto").disable();
    } else {
      this.isactiveriesgo = true;
      this.formDFE.get("riesgoPuesto").enable();
    }
  }
  // onIdRiesgoSelected(id: any) {
  //   if (id !== undefined && id != null && id > 0) {
  //     this.formDFE.get('riesgoPuesto').setValue(id);
  //   } else {
  //     this.formDFE.get('riesgoPuesto').setValue(0);
  //   }
  // }
}
