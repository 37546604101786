export const PAGES_MENU = [
  {
    path: "pages",
    children: [
      {
        path: "dashboard",
        data: {
          menu: {
            title: "general.menu.dashboard",
            icon: "flaticon-dashboard",
            selected: false,
            expanded: false,
            order: 0,
          },
        },
      },
      {
        path: "miempresa",
        data: {
          menu: {
            title: "general.menu.miempresa",
            icon: "flaticon-office-building",
            selected: false,
            expanded: false,
            order: 1,
          },
        },
        children: [
          {
            path: "empresa",
            data: {
              menu: {
                title: "general.menu.informacion",
                icon: "flaticon-configuraciones",
              },
            },
          },
          {
            path: "sucursales",
            data: {
              menu: {
                title: "Sucursales",
                icon: "flaticon-agency",
              },
            },
          },
          {
            path: "conceptos",
            data: {
              menu: {
                title: "general.menu.conceptos",
                icon: "flaticon-envio",
              },
            },
          },
          {
            path: "clientes",
            data: {
              menu: {
                title: "general.menu.clientes",
                icon: "flaticon-cliente",
              },
            },
          },
          {
            path: "proveedores",
            data: {
              menu: {
                title: "general.menu.proveedores",
                icon: "flaticon-distribucion",
              },
            },
          },
          {
            path: "parqueVehicular",
            data: {
              menu: {
                title: "general.menu.parqueVehicular",
                icon: "flaticon-pequeno-camion",
              },
            },
          },
        ],
      },
      {
        path: "bancos",
        data: {
          menu: {
            title: "general.menu.bancos",
            icon: "flaticon-bank-1",
            selected: false,
            expanded: false,
            order: 2,
          },
        },
        children: [
          {
            path: "cuentas",
            data: {
              menu: {
                title: "Cuentas",
                icon: "flaticon-mobile-banking",
              },
            },
            children: [
              {
                path: "cuentasgestion",
                data: {
                  menu: {
                    title: "Gestión de cuentas",
                    icon: "flaticon-invoice",
                  },
                },
              },
              {
                path: "tarjetasgestion",
                data: {
                  menu: {
                    title: "Gestión de tarjetas",
                    icon: "flaticon-credit-card",
                  },
                },
              },
              {
                path: "chequerasgestion",
                data: {
                  menu: {
                    title: "Gestión de chequeras",
                    icon: "flaticon-cheque",
                  },
                },
              },
              {
                path: "estadoscuenta",
                data: {
                  menu: {
                    title: "Estados de cuenta",
                    icon: "flaticon-accounting",
                  },
                },
              },
            ],
          },
          {
            path: "cajas",
            data: {
              menu: {
                title: "Cajas",
                icon: "flaticon-caja-registradora",
              },
            },
          },
        ],
      },
      {
        path: "comprobantesFiscales",
        data: {
          menu: {
            title: "general.menu.comprobantesfiscales",
            icon: "flaticon-bill",
            selected: false,
            expanded: false,
            order: 4,
          },
        },
      },
      {
        path: "nomina",
        data: {
          menu: {
            title: "general.menu.nomina",
            icon: "flaticon-cuenta-bancaria",
            selected: false,
            expanded: false,
            order: 5,
          },
        },
        children: [
          {
            path: "trabajadores",
            data: {
              menu: {
                title: "Trabajadores",
                icon: "flaticon-trabajo-en-equipo",
              },
            },
          },
          {
            path: "cfdi",
            data: {
              menu: {
                title: "Generar y Consultar CFDI",
                icon: "flaticon-salary-1",
              },
            },
          },
          {
            path: "percepciones",
            data: {
              menu: {
                title: "Percepciones",
                icon: "flaticon-salary",
              },
            },
          },
          {
            path: "deducciones",
            data: {
              menu: {
                title: "Deducciones",
                icon: "flaticon-tax",
              },
            },
          },
        ],
      },
      {
        path: "listaIngresos",
        data: {
          menu: {
            title: "general.menu.ingresos",
            icon: "flaticon-loan",
            selected: false,
            expanded: false,
            order: 6,
          },
        },
      },
      {
        path: "listaEgresos",
        data: {
          menu: {
            title: "general.menu.egresos",
            icon: "flaticon-payment-method",
            selected: false,
            expanded: false,
            order: 7,
          },
        },
      },
      {
        path: "usuarios",
        data: {
          menu: {
            title: "general.menu.usuarios",
            icon: "flaticon-cliente-1",
            selected: false,
            expanded: false,
            order: 3,
          },
        },
      },
    ],
  },
];
