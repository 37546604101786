import { Injectable } from "@angular/core";
import { HttpHelper } from "app/http-helper";
import { Subject } from "rxjs/Subject";
declare var Dropzone: any;
@Injectable()
export class DropzoneService {
  public islisto: boolean = false;

  renombrar(nombre) {
    let fecha = new Date();
    let segundos = fecha.getSeconds();
    let minutos = fecha.getMinutes();
    let horas = fecha.getHours();
    let milisegundos = fecha.getMilliseconds();
    let dia = fecha.getDate();
    let mes = fecha.getMonth();
    let anio = fecha.getFullYear();
    let chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let lon = 4;
    let nombreCompleto = milisegundos + this.rand_code(chars, lon) + segundos + this.rand_code(chars, lon) + minutos + this.rand_code(chars, lon) + horas + this.rand_code(chars, lon) + dia + this.rand_code(chars, lon) + mes + this.rand_code(chars, lon) + anio;
    return nombreCompleto;
  }
  rand_code(chars, lon) {
    let code = "";
    for (let x = 0; x < lon; x++) {
      let rand = Math.floor(Math.random() * chars.length);
      code += chars.substr(rand, 1);
    }
    return code;
  }

  dropzone(idDZ, tArchivo, arAcept, tipo, modulo, submodulo, eventosDropzone: Subject<{ id: string; evento: string; valor: string }>, cond, autoUpload?: boolean) {
    var self: any = this;
    $(document).ready(function () {
      let token = localStorage.getItem("token");
      $(idDZ).empty();
      $(idDZ).dropzone({
        url: HttpHelper.urlFILEREST + tipo,
        headers: { token: localStorage.getItem("token") },
        autoProcessQueue: autoUpload,
        maxFiles: 1,
        parallelUploads: 1,
        maxThumbnailFiles: 1,
        acceptedFiles: arAcept,
        addRemoveLinks: true,
        dictDefaultMessage: "Arrasta o has clic para subir un archivo (1 a la vez)",
        dictRemoveFile: "Remover archivo",
        renameFile: function (file) {
          if (cond == true) {
            let nombre = self.renombrar(file.name) + tArchivo;
            eventosDropzone.next({
              id: idDZ,
              evento: "rename",
              valor: nombre + "",
            });
            return nombre;
          } else {
            return file.name;
          }
        },
        success: function (file, response) {
          if (autoUpload) {
            eventosDropzone.next({
              id: idDZ,
              evento: "success",
              valor: response,
            });
          }
          file.previewElement.classList.add("dz-success");
          this.subirIMG1 = true;
          this.emit("complete", file);
          if (idDZ == "#dZUpload") {
            self.addingArchive("#dZUpload3", token, file, 2);
          } else if (idDZ == "#dZUpload3") {
            self.addingArchive("#dZUpload", token, file, 2);
          } else if (idDZ == "#dZUpload1") {
            self.addingArchive("#dZUpload2", token, file, 1);
          } else if (idDZ == "#dZUpload2") {
            self.addingArchive("#dZUpload1", token, file, 1);
          }
          this.disable();
        },
        error: function (file, response) {
          file.previewElement.classList.add("dz-error");
          eventosDropzone.next({ id: idDZ, evento: "error", valor: response });
        },
        sending: function (file, xhr, formData) {
          let extenxionFile: string[] = file.type.split("/");
          formData.set("token", localStorage.getItem("token"));
          formData.set("tipo", 3); //idProyecto  3->EMPRO
          formData.set("subtipo", modulo); // carpeta file configuration
          formData.set("id", submodulo); //idEmpresa
          eventosDropzone.next({
            id: idDZ,
            evento: "sending",
            valor: file.name,
          });
        },
        removedfile: function (file) {
          var urlImage;
          eventosDropzone.next({
            id: idDZ,
            evento: "removedfile",
            valor: file.name,
          });
          if (idDZ == "#dZUpload2" || idDZ == "#dZUpload1") {
            urlImage = `${HttpHelper.url}files/delete/1`;
          } else if (idDZ == "#dZUpload3" || idDZ == "#dZUpload") {
            urlImage = `${HttpHelper.url}files/delete/2`;
          }
          $.ajaxSetup({
            headers: { token: localStorage.getItem("token") },
          });
          $.get(urlImage).done(function () {
            if (file.previewElement) {
              $(idDZ).find(".dz-preview").remove();
              $(idDZ).find(".dz-message").show();
            }
          });
          this.enable();
        },
        //funcion para agregar preview de archivo en dropZone
        init: function () {
          this.on("removedfile", function (file) {
            if (idDZ == "#dZUpload") {
              self.removeArchive("#dZUpload3");
            } else if (idDZ == "#dZUpload3") {
              self.removeArchive("#dZUpload");
            } else if (idDZ == "#dZUpload1") {
              self.removeArchive("#dZUpload2");
            } else if (idDZ == "#dZUpload2") {
              self.removeArchive("#dZUpload1");
            }
          });
          this.addCustomFile = function (file, thumbnail_url, response) {
            if (response.status == "success") {
              this.emit("addedfile", file);
              this.emit("thumbnail", file, thumbnail_url);
              this.emit("complete", file);
            }
          };
          this.on("maxfilesexceeded", function (file) {
            $(file.previewElement).addClass("dz-error").find(".dz-error-message").text("Solo se puede subir un solo archivo.");
            $(file.previewElement).find(".dz-error-message").fadeTo("normal", 1);
            setTimeout(() => {
              file.previewElement.remove();
            }, 2000);
          });
          this.on("addedfile", function (file) {
            if (!autoUpload) {
              eventosDropzone.next({
                id: idDZ,
                evento: "addedfile",
                valor: file.name,
              });
            }
          });
          let dro = this;
          if (idDZ == "#dZUpload2" || idDZ == "#dZUpload1") {
            let urlImage = `${HttpHelper.url}files/download/1/${token}`;
            $.ajax({
              url: urlImage,
              success: function (data, status, jqXHR) {
                var blob = new Blob([data], { type: "application/jpg" });
                dro.addCustomFile(
                  {
                    processing: true,
                    accepted: true,
                    name: "Cedula de Identificación Fiscal",
                    size: blob.size,
                    type: "image",
                    status: Dropzone.SUCCESS,
                  },
                  urlImage,
                  {
                    status: "success",
                  }
                );
                dro.disable();
              },
              error: function () {
                console.log("No se ha podido obtener la información");
              },
            });
          } else if (idDZ == "#dZUpload3" || idDZ == "#dZUpload") {
            let urlImage = `${HttpHelper.url}files/download/2/${token}`;
            $.ajax({
              url: urlImage,
              success: function (data, status, jqXHR) {
                var blob = new Blob([data], { type: "application/jpg" });
                dro.addCustomFile(
                  {
                    processing: true,
                    accepted: true,
                    name: "Logo Empresa",
                    size: blob.size,
                    type: "image",
                    status: Dropzone.SUCCESS,
                  },
                  urlImage,
                  {
                    status: "success",
                  }
                );
                dro.disable();
              },
              error: function () {
                console.log("No se ha podido obtener la información");
              },
            });
          }
        },
      });
    });
  }
  getUrlServer(type: string) {
    let token = localStorage.getItem("token");
    if (type == "cedula") {
      return `${HttpHelper.url}files/download/1/${token}`;
    } else if (type == "logo") {
      return `${HttpHelper.url}files/download/2/${token}`;
    }
  }

  removeArchive(name) {
    if ($(name).length) {
      var upload = Dropzone.forElement(name);
      upload.removeAllFiles(true);
      upload.emit("resetFiles");
      upload.emit("reset");
      if (upload.files.length > 0) {
        upload.files.array.forEach(function (file) {
          file.previewElement.remove();
          upload.emit("removedfile", file);
        });
      }
      $(name).find(".dz-preview").remove();
      $(name).find(".dz-message").show();

      upload.options.maxFiles = 1;
    }
  }

  addingArchive(name, token, file, type) {
    
    if ($(name).length) {
      var upload = Dropzone.forElement(name);
      var imgurl = `${HttpHelper.url}files/download/${type}/${token}`;
      upload.emit("addedfile", file);
      upload.emit("thumbnail", file, imgurl);
      upload.emit("complete", file);
    }
  }

  getDropZone(id) {
    return Dropzone.forElement(id);
  }
}
