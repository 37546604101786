<div>
    <ng2-completer [datasource]="dataRemote" [minSearchLength]="_minsearch" [placeholder]="textPlaceHolder"
        [clearSelected]="false" (selected)="onSelected($event)" [textSearching]="'Espere un momento...'" pause="250"
        textNoResults="No hay ningún resultado" [clearUnselected]="true" [autoMatch]="true" matchClass='text-success'
        [(ngModel)]="searchStr" id="searchStr" name='searchStr' [disableInput]="!enabled" (keyup)="onKey()"
        [inputClass]="'form-control'" [fillHighlighted]="false" #componenteAutocomplete="ngModel" [autoHighlight]="true"
        (blur)="onBlur()">
    </ng2-completer>
    <span [hidden]="idSelected||!enabled" style="color:gray">
    </span>
</div>