import { Injectable } from "@angular/core";
import { HttpParams, HttpClient } from "@angular/common/http";
import { Cuenta } from "../entidades/cuenta";
import { Banco } from "../entidades/banco";
import { HttpHelper } from "../http-helper";
import "rxjs/add/operator/toPromise";

@Injectable()
export class CuentaService {
  private headers = HttpHelper.headersJSON;
  private cuentaUrl = HttpHelper.url + "cuenta";
  private empresaUrl = HttpHelper.url + "empresa";

  constructor(private http: HttpClient) {}

  private handleError(error: any): Promise<any> {
    let resp = error;
    if (resp.error) {
      console.error("CuentaService - ERROR: ", resp.error);
      return Promise.reject(resp.error);
    }
    return Promise.reject(error.message || error);
  }

  getDetalle(id: number) {
    const url = `${this.cuentaUrl}/detalle/${id}`;
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .get<any>(url, { headers: this.headers })
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  update(dataCuenta: Cuenta): Promise<any> {
    const url = `${this.cuentaUrl}/update`;
    let token = localStorage.getItem("token");
    this.headers = this.headers.set("token", token);
    return this.http
      .post<any>(url, JSON.stringify(dataCuenta), { headers: this.headers })
      .toPromise()
      .then((res) => res as Promise<any>)
      .catch(this.handleError);
  }

  updateStatus(id: number, estatus: number): Promise<any> {
    const url = `${this.cuentaUrl}/updateStatus/${id}`;
    let token = localStorage.getItem("token");
    let params = new HttpParams();
    // this.headers = this.headers.set('token', token);
    params = params.append("estatus", estatus + "");
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .get<any>(url, { headers: this.headers, params: params })
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  create(dataCuenta: Cuenta): Promise<any> {
    const url = `${this.cuentaUrl}/crear`;
    let token = localStorage.getItem("token");
    this.headers = this.headers.set("token", token);
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .post<any>(url, JSON.stringify(dataCuenta), { headers: this.headers })
        .toPromise()
        .then((res) => res as Promise<any>)
        .catch(this.handleError);
    }
  }

  remove(id: number): Promise<any> {
    const url = `${this.cuentaUrl}/eliminar/${id}`;
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .get<any>(url, { headers: this.headers })
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  getActiveCuentas(sortOrder: string) {
    const url = `${this.cuentaUrl}/getActiveCuentas`;
    let params = new HttpParams();
    if (sortOrder) {
      params = params.append("sortOrder", sortOrder);
    }
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      let options = { headers: this.headers, params: params };
      return this.http
        .get<any>(url, options)
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  getCuentas(filterQuery: string, sortBy: string, sortOrder: string, rowsOnPage: number, page: number) {
    const url = `${this.cuentaUrl}/list`;
    let params = new HttpParams();
    if (filterQuery) {
      params = params.append("filterQuery", filterQuery);
    }

    if (sortBy) {
      params = params.append("sortBy", sortBy);
    }

    if (sortOrder) {
      params = params.append("sortOrder", sortOrder);
    }
    if (rowsOnPage) {
      params = params.append("rowsOnPage", rowsOnPage + "");
    }
    if (page) {
      params = params.append("page", page + "");
    }

    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      let options = { headers: this.headers, params: params }; // Create a request option
      return this.http
        .get<any>(url, options)
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  autocomplete(filterQuery: string): Promise<any> {
    const url = `${this.cuentaUrl}/autocomplete/${filterQuery}`;
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .get<any>(url, { headers: this.headers })
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  autocompleteBancos(filterQuery: string) {
    let params = new HttpParams();
    const url = `${this.cuentaUrl}/autocompleteBancos`;
    if (filterQuery) {
      params = params.append("filterQuery", filterQuery);
    }
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      let options = { headers: this.headers, params: params }; // Create a request option
      return this.http
        .get<any>(url, options)
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  getCSVInfo() {
    let token = localStorage.getItem("token");
    if (token) {
      const url = `${this.cuentaUrl}/cuentaCSV/${token}`;
      window.location.href = url;
    }
  }

  getDetalleBanco(id: number) {
    const url = `${this.empresaUrl}/detalleBanco/${id}`;
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .get<any>(url, { headers: this.headers })
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }
}
