import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { RegimenFiscal } from "app/entidades/regimen-fiscal";
import { RegimenFiscalService } from "app/servicios-backend/regimen-fiscal.service";
import { AutocompleteGenericComponent } from "app/comun/components/autocomplete-generic";
import { UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl } from "@angular/forms";
import { TipoRegimen } from "app/entidades/tipo-regimen";
import { Empresa } from "app/entidades/empresa";
import { CertificadoService } from "app/servicios-backend/certificado.service";
import { EmpresaService } from "app/servicios-backend/empresa.service";
import { MailService } from "../../../servicios-backend/mail.service";
import { ActualizacionService } from "../../../comun/actualizacion.service";
import { Usuario } from "../../../entidades/usuario";
import { SharedService } from "app/shared.service";

@Component({
  selector: "app-registra-rf",
  templateUrl: "./registra-rf.component.html",
  styleUrls: ["./registra-rf.component.scss"],
})
export class RegistraRFComponent implements OnInit {
  public form: UntypedFormGroup;
  public tipoRegimen: AbstractControl;
  regimenFiscal: RegimenFiscal = new RegimenFiscal();
  public valor: number;
  public submitted: boolean = false;
  public mensajeError: string;
  public error: boolean;
  empresa: Empresa = new Empresa();
  rowsOnPage: number = 10;
  sortBy: string;
  sortOrder: string = "asc";
  page: number = 1;
  totalCertificados: number = 0;
  totalRegimenes: number = 0;
  query: string = "";
  empresaSesion: Empresa;
  @Input() progress = "";
  @Input() progressVal = 0;
  @Input() step: number = 0;
  @Output() updateProgrees = new EventEmitter<{ dataEmp: any; totCer: number; totReg: number }>();
  public nameButton = "";
  @ViewChild("autocompleteRegimenes")
  autocompleteRegimen: AutocompleteGenericComponent;
  usuarioEmpresa: Usuario;
  constructor(fb: UntypedFormBuilder, private regimenFiscalService: RegimenFiscalService, private certificadoService: CertificadoService, private empresaService: EmpresaService, private mailService: MailService, private actualizacionService: ActualizacionService, private sharedService: SharedService) {
    this.form = fb.group({
      tipoRegimen: ["", Validators.required],
    });
    this.tipoRegimen = this.form.controls["tipoRegimen"];
  }

  ngOnInit() {
    this.actualizacionService.usuarioCambios.subscribe(
      (usuario: Usuario) => {
        localStorage.setItem("emailDefault", usuario.correo);
        this.usuarioEmpresa = usuario;
      },
      (error) => {
        console.log(error);
      }
    );
  }
  btnNext() {
    $("#rfModal").modal("hide");
    //window.location.reload();
    this.sharedService.callParentMethod("reloadEmpresa");
  }
  btnPrev() {
    let csdCondition: boolean = false;
    this.empresaService
      .getDetalle()
      .then((dataEmp: any) => {
        this.certificadoService
          .getCertidicados(this.query, this.sortBy, this.sortOrder, this.rowsOnPage, this.page)
          .then((dataCert) => {
            this.totalCertificados = dataCert.total;
            this.regimenFiscalService
              .getRegimenes(this.query, this.sortBy, this.sortOrder, this.rowsOnPage, this.page)
              .then((dataReg) => {
                this.totalRegimenes = dataReg.total;
                this.empresa = dataEmp;
                this.updateProgrees.emit({
                  dataEmp: dataEmp,
                  totCer: this.totalCertificados,
                  totReg: this.totalRegimenes,
                });
                let certificates: any[] = dataCert.data;
                if (certificates[0].archivoCertificado != undefined && certificates[0].archivoLlave != undefined) {
                  csdCondition = true;
                  $("#rfModal").modal("hide");
                  setTimeout(() => {
                    $("#csdModal").modal({ backdrop: "static", keyboard: false }, "show");
                  }, 800);
                } else {
                  $("#rfModal").modal("hide");
                  this.sharedService.callParentMethod("reloadEmpresa");
                  //window.location.reload();
                }
              })
              .catch((error) => {
                console.log("Hubo un error", error);
              });
          })
          .catch((error) => {
            console.log("Hubo un error", error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  btnOM() {
    $("#rfModal").modal("hide");
  }
  onSubmitRegimenes() {
    this.mensajeError = undefined;
    this.submitted = true;
    if (this.form.valid) {
      let datosRegimen: RegimenFiscal = new RegimenFiscal();
      datosRegimen.tipoRegimen = new TipoRegimen();
      datosRegimen.empresa = new Empresa();
      datosRegimen.regimenDefault = true;
      datosRegimen.tipoRegimen.id = this.form.get("tipoRegimen").value;
      this.regimenFiscalService
        .create(datosRegimen)
        .then(() => {
          console.log("El regimen se ha agregado correctamente");

          this.mailService
            .sendMailRegistroAviso()
            .then(() => {
              $("#rfModal").modal("hide");
              this.sharedService.callParentMethod("reloadEmpresa");
              //window.location.reload();
            })
            .catch((error) => {
              console.log("Algo salió mal: ", error);
            });
        })
        .catch((error) => {
          console.log("Algo salió mal: ", error);
        });
    } else {
      console.log("Favor de llenar los datos");
      this.error = true;
    }
  }
  onIdRegimenesSelected(tipoRegimen: any) {
    if (tipoRegimen !== undefined && tipoRegimen != null && tipoRegimen > 0) {
      this.form.get("tipoRegimen").setValue(tipoRegimen);
      //console.log("Obtengo el CP "+ tipoRegimen);
    } else {
      this.form.get("tipoRegimen").setValue(0);
    }
  }
}
