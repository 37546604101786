<div class="modal fade" id="csdModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle"
  aria-hidden="true" style="overflow-y: auto;">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close color" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="progress">
            <div class="progress-bar progress-bar-info progress-bar-striped active" role="progressbar"
              [attr.aria-valuenow]="progressVal" aria-valuemin="0" aria-valuemax="100" [style.width.%]="progressVal">
              {{ progress }}
            </div>
          </div>
          <br>
          <h1>
            <span class="color">
              {{ step }}. Registra tu certificado y sello digital</span>
          </h1>
          <br>
          <h4>Puedes subir más de uno posteriormente, si es que lo requieres.
            <br>
            <u>Me gustaría recibir asesoría para llenar estos datos.</u>
          </h4>
          <br>
          <form [formGroup]="formCertificado" (ngSubmit)="onSubmitAgregar()" novalidate>
            <hr>
            <div class="modal-body">
              <dl class="row">
                <dt class="col-sm-12">
                  <div class="form-group">
                    Subir el archivo del Certificado o sello digital
                    <span class="required-input">*</span>
                    <br>
                    <small>Archivo con extensión .cer</small>
                    <div id="dZUploadCSDCER" class="dropzone">
                      <div class="dz-default dz-message"></div>
                    </div>
                  </div>
                </dt>
                <dt class="col-sm-12">
                  <div class="form-group">
                    Subir el archivo de la llave privada de sello digital
                    <span class="required-input">*</span>
                    <br>
                    <small>Archivo con extensión .key</small>
                    <div id="dZUploadCSDKEY" class="dropzone">
                      <div class="dz-default dz-message"></div>
                    </div>
                  </div>
                </dt>
                <dt class="col-sm-12">
                  <div class="form-group">
                    Escribe la contraseña de acceso de tu llave
                    <span class="required-input">*</span>
                    <input type="password" [formControl]="password" size="61" class="form-control" required>
                  </div>
                </dt>
              </dl>
            </div>
            <div class="align-middle">
              <button type="button" class="btn btn-warning" (click)="btnPrev()" di>Regresar</button>
              <button type="submit" class="btn btn-primary" *ngIf="!readyUpload">Subir</button>
              <button type="button" class="btn btn-primary" (click)="next()"
                *ngIf="readyUpload">{{ step < 4 ? 'Continuar' : 'Finalizar'}}</button>
            </div>
            <br>
            <div class="align-middle">
              <button type="submit" class="btn btn-cancelar" (click)="btnOM()">Hacerlo en otro momento</button>
            </div>
          </form>
          <br>
          <div class="alert alert-warning" *ngIf="error == true">
            <label for="">Favor de llenar los campos obligatorios marcados con
              <span class="required-input">*</span>
            </label>
          </div>
        </div>
        <div class="modal-footer">
        </div>
      </div>
    </div>
  </div>