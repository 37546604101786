<div class="modal " id="rfModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" style="overflow-y: auto;">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close color" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="progress">
            <div class="progress-bar progress-bar-success progress-bar-striped active" role="progressbar"
              [attr.aria-valuenow]="progressVal" aria-valuemin="0" aria-valuemax="100" [style.width.%]="progressVal">
            </div>
          </div>
          <br>
          <h1>
            <span class="color">
              {{ step }}. Registra tu régimen fiscal</span>
          </h1>
          <br>
          <h4>Puedes subir más de uno posteriormente, si es que lo requieres.
            <br>
            <u>Me gustaría recibir asesoría para llenar estos datos.</u>
          </h4>
          <br>
          <form [formGroup]="form" (ngSubmit)="onSubmitRegimenes()" novalidate>
            <hr>
            <div class="modal-body">
              <dl class="row">
                <dt class="col-sm-12">
                  <div class="form-group">
                    Elegir el tipo de régimen
                    <span class="required-input">*</span>
                    <autocomplete-generic minsearch='2' entidad='regimenes' required
                      (onIdSelected)="onIdRegimenesSelected($event)" [enabled]="true" textplaceholder='Buscar regimen'
                      #autocompleteRegimenes></autocomplete-generic>
                  </div>
                </dt>
              </dl>
            </div>
            <div class="align-middle">
              <button type="button" class="btn btn-warning" (click)="btnPrev()">Regresar</button>
              <button type="submit" class="btn btn-primary">{{ step < 4 ? 'Continuar' : 'Finalizar'}}</button>
            </div>
            <br>
            <div class="align-middle">
              <button type="submit" class="btn btn-cancelar" (click)="btnOM()">Hacerlo en otro momento</button>
            </div>
          </form>
          <br>
          <div class="alert alert-warning" *ngIf="error == true">
            <label for="">Favor de llenar los campos obligatorios marcados con
              <span class="required-input">*</span>
            </label>
          </div>
        </div>
        <div class="modal-footer">
        </div>
      </div>
    </div>
  </div>