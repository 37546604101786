import { Empresa } from "./empresa";
import { Timbres } from "./timbres";

export class PaqueteTimbres {
  id: number;
  fechaSolicitud: number;
  empresa: Empresa;
  costo: number;
  vigente: boolean;
  tipo: string;
  fechaCaducidad: number;
  timbresList: Array<Timbres>;
  totales: number;
  usados: number;
  enEspera: boolean;
  fechaActivacion: boolean;
}
