<app-registra-dfe [progress]="valorBoton" [progressVal]="valorProgress" [step]="step" ></app-registra-dfe>
<app-registra-cfdi [progress]="valorBoton" [progressVal]="valorProgress" [step]="step" ></app-registra-cfdi>
<app-registra-csd [progress]="valorBoton" [progressVal]="valorProgress" [step]="step" ></app-registra-csd>
<app-registra-rf [progress]="valorBoton" [progressVal]="valorProgress" [step]="step" ></app-registra-rf>

<div class="modal fade" id="expire_session_alert" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="exampleModalLabel">Su sessión ha caducado</h2>
      </div>
      <div class="modal-body mx-auto">
        <h4>El tiempo de sessión ha expirado; vuelva a iniciar sesion.</h4>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-colorblue btn-raised" data-dismiss="modal" (click)="Signout()">Salir</button>
      </div>
    </div>
  </div>
</div>

<div class="page-top clearfix" baScrollPosition maxHeight="50" (scrollChange)="scrolledChanged($event)" [ngClass]="{scrolled: isScrolled}">
  <a (click)="toHome()" class="al-logo clearfix"> <img class="top-logo" src="assets/images/logoempro.svg" /> </a>
  <a href (click)="toggleMenu()" class="collapse-menu-link ion-navicon"></a>
  <div class="tipo"><h2>{{ tipoEmpresa }}</h2></div>
  <div *ngIf="quitarBoton == true" id="completar-data" class="clearfix pull-left espacioSup">
    <button _ngcontent-c7="" aria-label="Close" class="close color btn parpadea" (click)="quitarCompletarFunction()" type="button">
      <span _ngcontent-c7="" aria-hidden="true">×</span>
    </button>
    <button class="btn parpadea" [ngClass]="{'btn-danger': variable1===true, 'btn-warning': variable2===true, 'btn-info': variable3===true, 'btn-success': variable4===true}" type="button" (click)="onCheckEmpty()">Completar datos de mi empresa: {{valorBoton}}</button>
  </div>

  <div class="user-profile clearfix">
    <div>
      <div id="timber-notification" (click)="prepareTimbres()" *ngIf="showNoti">
        <input type="radio" id="radio-1" name="tabs" checked />
        <label class="tab" for="radio-1">
          <span id="noti-label" *ngIf="!isLite">Timbres</span>
          <span id="noti-label" *ngIf="isLite">Faltan</span>
          <span [ngClass]="{'notification-empty': noTimbres == 0}" class="notification" *ngIf="!isLite">{{ noTimbres }}</span>
          <span [ngClass]="{'notification-empty': diasFaltantes == 0}" class="notification" *ngIf="isLite">{{ diasFaltantes }}</span>
          <span id="noti-label" class="left-margin" *ngIf="isLite">dias</span>
        </label>
      </div>
      <div class="text-timbre" [ngClass]="{'text-error': diasFaltantes == 0 && isLite}"  [ngClass]="{'text-error': noTimbres == 0 && !isLite}">{{ leyenda }}</div>
    </div>
    <div class="dropdown al-user-profile">
      <span class="pull-right">
        <a class="profile-toggle-link dropdown-toggle" id="user-profile-dd" data-toggle="dropdown" aria-expanded="false">
          <span class="pull-right profile-toggle-link" id="user-profile-name">
            {{nombre}}
            <em class="fa fa-angle-down" aria-hidden="true"></em>
          </span>
          <img [src]="url" />
        </a>
        <ul class="dropdown-menu top-dropdown-menu profile-dropdown" aria-labelledby="user-profile-dd">
          <li class="dropdown-item">
            <a routerLink="/pages/miempresa/perfil"> <em class="fa fa-user"></em>Perfil</a>
          </li>
          <li class="dropdown-item">
            <a href="#" (click)="Signout()" class="signout"> <em class="fa fa-power-off"></em>Cerrar Sesión</a>
          </li>
        </ul>
      </span>
    </div>
    <ba-msg-center></ba-msg-center>
  </div>
</div>
<app-paquete-timbres [parent]="this" [tipoEmpresa]="tipoEmpresa" [diasFaltantes]="diasFaltantes"></app-paquete-timbres>
