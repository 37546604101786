import { Component, ViewChild } from "@angular/core";
import { Routes, Router } from "@angular/router";

import { BaMenuService } from "../theme";
import { PAGES_MENU } from "./pages.menu";
import { UsuarioService } from "app/servicios-backend/usuario.service";
import { EmpresaService } from "app/servicios-backend/empresa.service";
import { CertificadoService } from "app/servicios-backend/certificado.service";
import { RegimenFiscalService } from "app/servicios-backend/regimen-fiscal.service";
import { Empresa } from "app/entidades/empresa";
import { BaPageTop } from "app/theme/components/baPageTop/baPageTop.component";
import { SharedService } from "../shared.service";

@Component({
  selector: "pages",
  templateUrl: "./pages.html",
})
export class PagesComponent {
  public currentYear: Number;
  empresa: Empresa = new Empresa();
  query: string = "";
  sortBy: string;
  sortOrder: string = "asc";
  rowsOnPage: number = 10;
  totalCertificados: number = 0;
  totalRegimenes: number = 0;
  noTimbres: number = 0;
  tipoEmpresa: string = "";
  enEspera: boolean = false;
  diasFaltantes: number = 0;
  page: number = 1;
  @ViewChild(BaPageTop) pageTop: BaPageTop;

  constructor(private _menuService: BaMenuService, private router: Router, private usuarioService: UsuarioService, private empresaService: EmpresaService, private certificadoService: CertificadoService, private regimenFiscalService: RegimenFiscalService, private sharedService: SharedService) {}

  ngOnInit() {
    this.sharedService.callParentMethod$.subscribe((metodo) => {
      if (metodo == "reloadEmpresa") {
        this.reloadEmpresa();
      }
    });
    this.currentYear = new Date().getFullYear();
    const token = localStorage.getItem("token");

    if (token) {
      this._menuService.updateMenuByRoutes(<Routes>PAGES_MENU);
      this.onCheckEmpty();
    } else {
      this.router.navigate(["login"]);
    }
  }

  onCheckEmpty() {
    this.usuarioService
      .authenticated()
      .then((data) => {
        if (!data) {
          this.router.navigate(["login"]);
        }
        this.reloadEmpresa();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  reloadEmpresa() {
    this.empresaService
      .getDetalle()
      .then((dataEmp: any) => {
        this.certificadoService
          .getCertidicados(this.query, this.sortBy, this.sortOrder, this.rowsOnPage, this.page)
          .then((dataCert) => {
            this.totalCertificados = dataCert.total;
            this.regimenFiscalService
              .getRegimenes(this.query, this.sortBy, this.sortOrder, this.rowsOnPage, this.page)
              .then((dataReg) => {
                this.totalRegimenes = dataReg.total;
                this.empresa = dataEmp;
                this.noTimbres = this.empresa.timbresRestantes;
                this.tipoEmpresa = this.empresa.nivel;
                this.enEspera = this.empresa.waitPaquete;
                let caducidad = this.empresa.caducidad != null ? this.empresa.caducidad : 0;
                let caduci = new Date(caducidad);
                let now = new Date();
                let diferenciaEnMilisegundos = caduci.getTime() - now.getTime();
                this.diasFaltantes = Math.ceil(diferenciaEnMilisegundos / 86400000);
                this.diasFaltantes = this.diasFaltantes < 0 ? 0 : this.diasFaltantes;
                if (this.pageTop != null && this.pageTop != undefined) {
                  this.pageTop.calcTimbres(this.tipoEmpresa, this.noTimbres, this.diasFaltantes);
                  let emData = {
                    dataEmp: this.empresa,
                    totCer: this.totalCertificados,
                    totReg: this.totalRegimenes,
                  };
                  this.pageTop.updateProgrees(emData);
                }

                this.sharedService.setEmpresa(this.empresa);
                this.sharedService.callParentMethod("checkUsuarioEmpresa");
                this.sharedService.callParentMethod("checkSucursalEmpresa");
                this.sharedService.callParentMethod("checkComprobanteEmpresa");
                this.sharedService.callParentMethod("checkPerfilEmpresa");
              })
              .catch((error) => {
                console.log("Hubo un error", error);
              });
          })
          .catch((error) => {
            console.log("Hubo un error", error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
