import { Injectable } from "@angular/core";
import { HttpHelper } from "../http-helper";
import { HttpClient, HttpParams } from "@angular/common/http";

@Injectable()
export class MailService {
  private headers = HttpHelper.headersJSON;
  private mailURL = HttpHelper.url + "mail";

  constructor(private http: HttpClient) {}

  private handleError(error: any): Promise<any> {
    let resp = error;
    if (resp.error) {
      console.error("IngresoEgresoService - ERROR: ", resp.error);
      return Promise.reject(resp.error);
    }
    return Promise.reject(error.message || error);
  }
  //servicio para enviar mail al concluir registro de ususario
  sendMailRegistro(mail: string): Promise<any> {
    const url = `${this.mailURL}/correoRegistro?to=${mail}`; //AGREGAR PARAMETROS AL TERMINAR EL SERVICIO

    return this.http
      .post<any>(url, {}, { headers: this.headers })
      .toPromise()
      .then((response) => response as Promise<any>)
      .catch(this.handleError);
  }

  sendMailBienvenida(mail: string, empresaId: number): Promise<any> {
    const url = `${this.mailURL}/correoBienvenida?to=${mail}&empresaId=${empresaId}`; //AGREGAR PARAMETROS AL TERMINAR EL SERVICIO
    return this.http
      .get<any>(url, { headers: this.headers })
      .toPromise()
      .then((response) => response as Promise<any>)
      .catch(this.handleError);
  }

  sendMailIngreso(idIngreso: number, listMail: string): Promise<any> {
    const url = `${this.mailURL}/ingreso/${idIngreso}/${listMail}`; //AGREGAR PARAMETROS AL TERMINAR EL SERVICIO
    let token = localStorage.getItem("token");

    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .get<any>(url, { headers: this.headers })
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  sendMailRegistroAviso(): Promise<any> {
    const url = `${this.mailURL}/avisoRegistro`;
    let token = localStorage.getItem("token");

    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .get<any>(url, { headers: this.headers })
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  sendMailNomina(idNomina: number, listMail: string): Promise<any> {
    const url = `${this.mailURL}/nomina/${idNomina}/${listMail}`; //AGREGAR PARAMETROS AL TERMINAR EL SERVICIO
    let token = localStorage.getItem("token");

    if (token) {
      this.headers = this.headers.set("token", token);

      return this.http
        .get<any>(url, { headers: this.headers })
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  sendMailFilesComprobante(mail: string, id: string): Promise<any> {
    const url = `${this.mailURL}/enviarComprobante/${id}?correos=${mail}`; //AGREGAR PARAMETROS AL TERMINAR EL SERVICIO
    let token = localStorage.getItem("token");
    let params = new HttpParams();

    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .get<any>(url, { headers: this.headers })
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  sendMailFilesComplemento(mail: string, id: string): Promise<any> {
    const url = `${this.mailURL}/enviarComplemento/${id}?correos=${mail}`; //AGREGAR PARAMETROS AL TERMINAR EL SERVICIO
    let token = localStorage.getItem("token");
    let params = new HttpParams();

    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .get<any>(url, { headers: this.headers })
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }
}
