import { Injectable } from "@angular/core";
import { HttpParams, HttpClient } from "@angular/common/http";
import { ConfiguracionNomina } from "../entidades/configuracion-nomina";
import { HttpHelper } from "../http-helper";
import "rxjs/add/operator/toPromise";

@Injectable()
export class ConfiguracionNominaService {
  private headers = HttpHelper.headersJSON;
  private configuracionNominaUrl = HttpHelper.url + "configuracionNomina";

  constructor(private http: HttpClient) {}

  private handleError(error: any): Promise<any> {
    let resp = error;
    if (resp.error) {
      console.error("ConfiguracionNominaService - ERROR: ", resp.error);
      return Promise.reject(resp.error);
    }
    return Promise.reject(error.message || error);
  }

  getPeriodo(periodicidad: any) {
    const url = `${this.configuracionNominaUrl}/getPeriodo/${periodicidad}`;
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .get<any>(url, { headers: this.headers })
        .toPromise()
        .then((response) => response as Promise<ConfiguracionNomina>)
        .catch(this.handleError);
    }
  }

  siguientePeriodo(idPeriodicidad: any) {
    const url = `${this.configuracionNominaUrl}/calculaFechaProxima`;
    let params = new HttpParams();
    console.log(idPeriodicidad, url);
    if (idPeriodicidad != undefined) {
      params = params.append("idPeriodicidad", idPeriodicidad + "");
    }

    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      console.log(params);
      return this.http
        .get<any>(url, { headers: this.headers, params: params })
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  getDetalle(id: number): Promise<any> {
    const url = `${this.configuracionNominaUrl}/detalle/${id}`;
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .get<any>(url, { headers: this.headers })
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  existeConfiguracion(idPeriodicidad: number, idEmpresa: number): Promise<any> {
    const url = `${this.configuracionNominaUrl}/existeConfiguracion/${idEmpresa}/${idPeriodicidad}`;
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .get<any>(url, { headers: this.headers })
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  update(dataConfiguracionNomina: ConfiguracionNomina): Promise<any> {
    const url = `${this.configuracionNominaUrl}/update`;
    let token = localStorage.getItem("token");
    this.headers = this.headers.set("token", token);
    return this.http
      .post<any>(url, JSON.stringify(dataConfiguracionNomina), { headers: this.headers })
      .toPromise()
      .then((res) => res as Promise<any>)
      .catch(this.handleError);
  }

  create(dataConfiguracionNomina: ConfiguracionNomina): Promise<any> {
    const url = `${this.configuracionNominaUrl}/crear`;
    let token = localStorage.getItem("token");
    this.headers = this.headers.set("token", token);
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .post<any>(url, JSON.stringify(dataConfiguracionNomina), { headers: this.headers })
        .toPromise()
        .then((res) => res as Promise<any>)
        .catch(this.handleError);
    }
  }

  remove(id: number): Promise<any> {
    const url = `${this.configuracionNominaUrl}/eliminar/${id}`;
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .get<any>(url, { headers: this.headers })
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  autocompletePeriodicidad(filterQuery: string) {
    let params = new HttpParams();
    let token = localStorage.getItem("token");
    const url = `${this.configuracionNominaUrl}/autocompletePeriodicidad`;
    params = params.append("filterQuery", filterQuery);
    if (token) {
      this.headers = this.headers.set("token", token);
      let queryParams = { headers: this.headers, params: params };
      return this.http.get<any>(url, queryParams).map((response) => {
        response;
      });
    }
  }

  autocompletePeriodicidadTrabajador(filterQuery: string) {
    let params = new HttpParams();
    let token = localStorage.getItem("token");
    const url = `${this.configuracionNominaUrl}/autocompletePeriodicidadTrabajador`;
    params = params.append("filterQuery", filterQuery);
    if (token) {
      this.headers = this.headers.set("token", token);
      // parametros para la peticion HTTP
      let queryParams = { headers: this.headers, params: params };
      return this.http.get<any>(url, queryParams).map((response) => {
        response;
      });
    }
  }
}
