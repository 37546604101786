import { Injectable } from "@angular/core";
import { HttpParams, HttpClient } from "@angular/common/http";
import { Cobro } from "app/entidades/cobro";
import { ComprobanteCancelacion } from "app/entidades/comprobante-cancelacion";
import { Ingreso } from "app/entidades/ingreso";
import { HttpHelper } from "app/http-helper";

@Injectable()
export class IngresoService {
  private headers = HttpHelper.headersJSON;
  private ingresoUrl = HttpHelper.url + "ingreso";
  private downloadFileURL = HttpHelper.urlFILEREST + "/download/6";

  constructor(private http: HttpClient) {}

  private handleError(error: any): Promise<any> {
    let resp = error;
    if (resp.error) {
      console.error("IngresoEgresoService - ERROR: ", resp.error);
      return Promise.reject(resp.error);
    }
    return Promise.reject(error.message || error);
  }

  getDetalleCobro(id: number) {
    const url = `${this.ingresoUrl}/detalleCobro/${id}`;
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http.get<any>(url, { headers: this.headers }).map((response) => response);
    }
  }

  //sin uso
  getDetalleComprobante(id: number) {
    const url = `${this.ingresoUrl}/detalleComprobante/${id}`;
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http.get<any>(url, { headers: this.headers }).map((response) => response);
    }
  }

  update(dataIngresoEgreso: any): Promise<any> {
    const url = `${this.ingresoUrl}/update`;
    let token = localStorage.getItem("token");
    this.headers = this.headers.set("token", token);

    return this.http
      .post<any>(url, JSON.stringify(dataIngresoEgreso), { headers: this.headers })
      .toPromise()
      .then((res) => res as Promise<any>)
      .catch(this.handleError);
  }

  //sin uso
  updateMonto(dataIngresoEgreso: any): Promise<any> {
    const url = `${this.ingresoUrl}/updateMonto/${dataIngresoEgreso.id}`;
    let token = localStorage.getItem("token");
    this.headers = this.headers.set("token", token);
    let params = new HttpParams();
    params = params.append("monto", dataIngresoEgreso.monto);
    params = params.append("fecha", dataIngresoEgreso.fecha);
    //console.log(JSON.stringify(dataIngresoEgreso));
    return this.http
      .get(url, { headers: this.headers, params: params })
      .toPromise()
      .then((res) => res as Promise<any>)
      .catch(this.handleError);
  }

  //sin uso
  create(dataIngresoEgreso: Ingreso): Promise<any> {
    const url = `${this.ingresoUrl}/crear`;
    let token = localStorage.getItem("token");
    this.headers = this.headers.set("token", token);
    console.log(dataIngresoEgreso);
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .post<any>(url, JSON.stringify(dataIngresoEgreso), { headers: this.headers })
        .toPromise()
        .then((res) => res as Promise<any>)
        .catch(this.handleError);
    }
  }

  //sin uso
  removeCobro(id: number): Promise<any> {
    const url = `${this.ingresoUrl}/eliminarCobro/${id}`;
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .get<any>(url, { headers: this.headers })
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  getIngresos(filterQuery: string, sortBy: string, sortOrder: string, rowsOnPage: number, page: number, pendienteCobrar?: boolean) {
    const url = `${this.ingresoUrl}/list`;
    let params = new HttpParams();
    params = params.append("filterQuery", filterQuery);

    if (sortBy) {
      params = params.append("sortBy", sortBy);
    }

    if (sortOrder) {
      params = params.append("sortOrder", sortOrder);
    }
    if (rowsOnPage) {
      params = params.append("rowsOnPage", rowsOnPage + "");
    }
    if (page) {
      params = params.append("page", page + "");
    }
    if (pendienteCobrar) {
      params = params.append("pendienteCobrar", pendienteCobrar + "");
    }

    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      let options = { headers: this.headers, params: params }; // Create a request option
      return this.http
        .get<any>(url, options)
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  crearComprobante(id: number) {
    const url = `${this.ingresoUrl}/crearComprobante/${id}`;
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .get<any>(url, { headers: this.headers })
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  crearComprobanteUpload(id: number, nameXml: string) {
    let params = new HttpParams();
    if (nameXml) {
      params = params.append("nameXml", nameXml);
    }

    const url = `${this.ingresoUrl}/crearComprobante/${id}`;
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      let options = { headers: this.headers, params: params };
      return this.http
        .get<any>(url, options)
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  timbrarComprobante(id: number) {
    const url = `${this.ingresoUrl}/timbrar/${id}`;
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .get<any>(url, { headers: this.headers })
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }
  //sin uso
  autocomplete(filterQuery: string, tipo: boolean) {
    let params = new HttpParams();
    const url = `${this.ingresoUrl}/autocomplete`;
    if (filterQuery) {
      params = params.append("filterQuery", filterQuery);
    }
    if (tipo) {
      params = params.append("tipo", tipo + "");
    }
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      let options = { headers: this.headers, params: params }; // Create a request option
      return this.http
        .get<any>(url, options)
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  //sin uso
  autocompleteTComprobanteEgresoConCfdi(filterQuery: string) {
    let params = new HttpParams();
    const url = `${this.ingresoUrl}/autocompleteTComprobanteEgresoConCfdi`;
    if (filterQuery) {
      params = params.append("filterQuery", filterQuery);
    }

    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      let options = { headers: this.headers, params: params }; // Create a request option
      return this.http
        .get<any>(url, options)
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  //sin uso
  autocompleteTComprobanteEgresoSinComprobante(filterQuery: string) {
    let params = new HttpParams();
    const url = `${this.ingresoUrl}/autocompleteTComprobanteEgresoSinComprobante`;
    if (filterQuery) {
      params = params.append("filterQuery", filterQuery);
    }
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      let options = { headers: this.headers, params: params }; // Create a request option
      return this.http
        .get<any>(url, options)
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  //sin uso
  autocompleteTComprobanteOtroEgreso(filterQuery: string) {
    let params = new HttpParams();
    const url = `${this.ingresoUrl}/autocompleteTComprobanteOtroEgreso`;
    if (filterQuery) {
      params = params.append("filterQuery", filterQuery);
    }
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      let options = { headers: this.headers, params: params }; // Create a request option
      return this.http
        .get<any>(url, options)
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  //sin uso
  autocompleteTComprobanteIngreso(filterQuery: string) {
    let params = new HttpParams();
    const url = `${this.ingresoUrl}/autocompleteTComprobanteIngreso`;
    if (filterQuery) {
      params = params.append("filterQuery", filterQuery);
    }
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      let options = { headers: this.headers, params: params }; // Create a request option
      return this.http
        .get<any>(url, options)
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  crearCobro(cobro: Cobro) {
    const url = `${this.ingresoUrl}/crearCobro`;
    let token = localStorage.getItem("token");
    this.headers = this.headers.set("token", token);
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .post<any>(url, JSON.stringify(cobro), { headers: this.headers })
        .toPromise()
        .then((res) => res as Promise<any>)
        .catch(this.handleError);
    }
  }

  cancelar(id: number, comprobanteC: ComprobanteCancelacion) {
    const url = `${this.ingresoUrl}/cancelar/${id}`;
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .post<any>(url, JSON.stringify(comprobanteC), { headers: this.headers })
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  //sin uso
  getCSVIngreso() {
    let token = localStorage.getItem("token");
    if (token) {
      const url = `${this.ingresoUrl}/csvIngreso/${token}`;
      window.location.href = url;
    }
  }

  //sin uso
  descargaCSV() {
    let token = localStorage.getItem("token");
    const url = `${this.ingresoUrl}/csvCobroPago/${token}`;
    if (token) {
      window.location.href = url;
    }
  }

  crearXml(id: number, iscomplemento: boolean) {
    const url = `${this.ingresoUrl}/generaXML/${id}`;
    let token = localStorage.getItem("token");
    if (token) {
      let params = new HttpParams();
      params = params.append("iscomplemento", iscomplemento + "");
      this.headers = this.headers.set("token", token);
      return this.http
        .get<any>(url, { headers: this.headers, params: params })
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  downloadXML(id: number) {
    let token = localStorage.getItem("token");
    if (token) {
      const url = `${HttpHelper.url}files/download/4/${id}.xml/${token}`;
      window.location.href = url;
    }
  }

  crearPDF(id: number, iscomplemento: boolean) {
    const url = `${this.ingresoUrl}/crearPDF/${id}`;
    let token = localStorage.getItem("token");
    if (token) {
      let params = new HttpParams();
      params = params.append("iscomplemento", iscomplemento + "");
      this.headers = this.headers.set("token", token);
      return this.http
        .get<any>(url, { headers: this.headers, params: params })
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  getAcuse(id: number, iscomplemento: boolean) {
    const url = `${this.ingresoUrl}/checarAcuse/${id}`;
    let token = localStorage.getItem("token");
    if (token) {
      let params = new HttpParams();
      params = params.append("iscomplemento", iscomplemento + "");
      this.headers = this.headers.set("token", token);
      let options = { headers: this.headers, params: params }; // Create a request option
      return this.http
        .get<any>(url, options)
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  downloadPDF(id: number) {
    let token = localStorage.getItem("token");
    if (token) {
      const url = `${HttpHelper.url}files/download/4/${id}.pdf/${token}`;
      window.location.href = url;
    }
  }

  //sin uso
  xmlToIngresoEgreso(fd: FormData) {
    let token = localStorage.getItem("token");
    if (token) {
      const url = `${this.ingresoUrl}/guardarXMLToIngresoEgreso`;
      this.headers = this.headers.set("token", token);

      return this.http
        .post<any>(url, fd, { headers: this.headers })
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  //sin uso
  listAllCobroPago(filterQuery: string, sortBy: string, sortOrder: string, roswOnPage: number, page: number, idCuenta?: number): Promise<any> {
    let token = localStorage.getItem("token");
    let params = new HttpParams();

    if (filterQuery) {
      params = params.append("filterQuery", filterQuery);
    }
    if (sortBy) {
      params = params.append("sortBy", sortBy);
    }
    if (sortOrder) {
      params = params.append("sortOrder", sortOrder);
    }
    if (roswOnPage) {
      params = params.append("rowsOnPage", roswOnPage + "");
    }
    if (page) {
      params = params.append("page", page + "");
    }
    // parametro para filtrar por id de cuenta
    if (idCuenta) {
      params = params.append("idCuenta", idCuenta + "");
    }

    if (token) {
      const url = `${this.ingresoUrl}/listCobroPago`;
      this.headers = this.headers.set("token", token);

      return this.http
        .get<any>(url, { headers: this.headers, params: params })
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }
}
