import { Injectable } from "@angular/core";

@Injectable()
export class ComprobanteidService {
  editarComprobante: string = "";

  constructor() {}

  public incrementValue(item) {
    this.editarComprobante = item;
  }
}
