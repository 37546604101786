<ba-sidebar></ba-sidebar>
<ba-page-top [totalCertificados]="totalCertificados" [totalRegimenes]="totalRegimenes" [noTimbres]="noTimbres" [parent]="this" [tipoEmpresa]="tipoEmpresa" [diasFaltantes]="diasFaltantes" [enEspera]="enEspera">
</ba-page-top>
<div class="al-main">
    <div class="al-content">
        <ba-content-top></ba-content-top>
        <router-outlet></router-outlet>
    </div>
</div>
<div class="modal fade" id="registroModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="text-center espa">Términos y condiciones</h1>
                <button type="button" class="close color" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" style="padding: 0px;">
                <div>
                    <div class="policy-content">
                        <div class="head-policy">
                            <img class="corner-logo" src="assets/images/logoempro.svg">
                        </div>
                        <div class="body-policy">
                            <h2 class="policy-title"> Aviso de Privacidad</h2>
                            <p> De acuerdo con la Ley Federal de Protección de Datos Personales en
                                Posesión
                                de los Particulares (en lo sucesivo la "<b>LEY</b>) declara Soluciones
                                Estratégicas
                                para las Decisiones Empresariales S.A. de CV. ser legitima propietaria
                                de la marca -<b>EMPRO</b>-
                                (en lo sucesivo “<b>EMPRO</b>”), ser una sociedad legalmente constituida
                                de conformidad con las leyes
                                mexicanas, con domicilio ubicado en Salama No. 546, Colonia Valle del
                                Tepeyac, C.P. 07740, en la
                                Ciudad de México, y que suscribe el presente Aviso de Privacidad. Los
                                datos personales que Usted
                                o aquella persona a la cual Usted represente como titular de los datos
                                personales que ha proporcionado
                                directamente o a través de medios electrónicos en virtud de la
                                contratación de productos o servicios con <b>EMPRO</b>,
                                han sido recabados y serán tratados de forma estrictamente confidencial
                                a través de los sistemas provistos por <b>EMPRO</b>
                                y de conformidad con lo dispuesto por la <b>LEY</b>, siendo usados para
                                la operación y registro de los productos o servicios
                                que Usted ha contratado, así como para ofrecerle, en su caso, otros
                                productos de <b>EMPRO</b> o de cualquiera de sus afiliadas,
                                subsidiarias, controladoras, asociadas, y promociones de otros bienes o
                                servicios relacionados con los citados productos.
                            </p>
                            <p>Mediante el presente Aviso de Privacidad, Usted tiene la oportunidad de
                                escoger los productos y servicios que le ofrece <b>EMPRO</b>, sabiendo
                                que sus
                                datos personales estarán protegidos. La seguridad de su información
                                personal es nuestra prioridad, es por lo que <b>EMPRO</b> protege esta
                                información mediante el mantenimiento de protecciones físicas,
                                electrónicas y de procedimiento, así como con medidas de alta seguridad
                                tecnológica y administrativa, capacitando a nuestros colaboradores en el
                                manejo adecuado de la información personal, a fin de prevenir
                                razonablemente su uso o divulgación indebida o impedir que terceros no
                                autorizados accedan a la misma.</p>
                            <p> <b>EMPRO</b> en la prestación de servicios en general y para efectos de
                                la
                                solución contable administrativa denominada -<b>EMPRO</b>- en cualquiera
                                de sus
                                opciones, no recaba datos personales sensibles, por lo que ninguno de
                                los datos personales proporcionados por Usted, contiene información que
                                sea considerada como datos sensibles en los términos señalados en la
                                fracción VI del artículo 3 de la <b>LEY</b>.</p>
                            <p> El responsable del tratamiento de sus datos personales será
                                <b>EMPRO</b>, por
                                ello le informa que posee, recaba o recabará de Usted, los datos
                                personales necesarios para atender su solicitud, principalmente para la
                                adecuada realización y formal contratación de los servicios y productos
                                de <b>EMPRO</b>. Dichos datos personales pueden haber sido o pueden ser
                                obtenidos de Usted, ya sea personalmente o bien, directamente por
                                cualquier medio electrónico, óptico, sonoro, visual, o a través de
                                cualquier otra tecnología. Asimismo, podemos obtener datos personales de
                                los que Usted es titular, cuando visita nuestro sitio de Internet o
                                utiliza nuestros servicios en línea y a través de terceros o de otras
                                fuentes permitidas por la <b>LEY</b>.
                            </p>
                            <p><b>EMPRO</b> recabará los datos personales necesarios para la
                                formalización del
                                tipo de producto solicitado o requerido por Usted; de manera
                                enunciativa, más no limitativa, <b>EMPRO</b> podrá recabar:</p>
                            <ul>
                                <li>Datos de identificación y de contacto: nombre completo, dirección,
                                    teléfono y/o
                                    celular de trabajo, firma, correo electrónico, así como sus
                                    domicilios, entre otros.</li>
                                <li>Datos laborales: ocupación, puesto, área o departamento, domicilio,
                                    teléfono y
                                    correo de trabajo, referencias laborales, referencias personales y
                                    referencias
                                    comerciales, entre otros.</li>
                                <li>Datos fiscales: Razón social, RFC, Cedula de Identificación Fiscal,
                                    domicilio
                                    fiscal, entre otros.</li>
                            </ul>
                            <p><b>EMPRO</b> hace de su conocimiento que los datos personales
                                proporcionados por
                                Usted serán utilizados para (I) llevar a cabo el proceso y verificación
                                de conflicto de independencia; (II) integrar su expediente como cliente;
                                (III) proveer o prestar los servicios que pudieran llegar a ser o hayan
                                sido contratados; (IV) llevar a cabo la adquisición de los derechos o
                                productos relacionados con los mismos; (V) evaluar la calidad del
                                servicio brindado; (VI) dar cumplimiento a las obligaciones regulatorias
                                emitidas por parte de las autoridades competentes; y (VII) para fines
                                mercadotécnicos, publicitarios o de prospección comercial; esta última
                                conforme a lo dispuesto en los artículos 16, fracción II de la
                                <b>LEY</b> y 30
                                de su Reglamento. De igual forma, dichos datos personales podrán ser
                                transferidos a terceras personas ya sean físicas o morales, nacionales o
                                extranjeras, con el único propósito de cumplir con los fines señalados
                                en el presente Aviso de Privacidad.
                            </p>
                            <p> No obstante, <b>EMPRO</b> podrá revelar sus datos personales, para
                                cumplir con
                                las disposiciones referidas en el párrafo anterior y a efecto de cumplir
                                requerimientos por parte de autoridades judiciales y administrativas.
                            </p>
                            <p> Todos los datos personales son tratados de acuerdo con la <b>LEY</b>,
                                por ello
                                le informamos que Usted como cliente y titular de los datos personales
                                podrá en todo momento oponerse o revocar el consentimiento que ha
                                otorgado a <b>EMPRO</b> para el tratamiento de sus datos personales,
                                ejerciendo
                                sus derechos ARCO, que corresponden al derecho de limitar el uso o
                                divulgación de sus datos personales, así como ejercer sus derechos de
                                acceso, rectificación y cancelación de los mismos; por lo que deberá
                                solicitarlo a través del teléfono 01 800 – 007 3330 o enviando un correo
                                electrónico a la siguiente dirección electrónica: <a
                                    href="mailto: contacto@empro.mx">contacto@empro.mx</a></p>

                            <p>De conformidad con la <b>LEY</b>, el titular de los datos personales
                                tiene el
                                derecho, mediante la presentación de una solicitud debidamente firmada,
                                ya sea en el domicilio del responsable o mediante correo electrónico a
                                la dirección establecida en el presente Aviso, de acceder a los datos
                                que poseemos y a los detalles del tratamiento de los mismos, así como a
                                rectificarlos en caso de ser inexactos o incompletos; cancelarlos cuando
                                resulten ser excesivos o innecesarios para las finalidades que
                                justificaron su obtención u oponerse al tratamiento de los mismos para
                                fines específicos. <b>EMPRO</b> bajo los supuestos establecidos en el
                                artículo
                                34 de la <b>LEY</b>, podrá negar el acceso a los datos personales, o a
                                realizar
                                la rectificación o cancelación o conceder la oposición al tratamiento de
                                estos.</p>
                            <p> Todo tratamiento de datos personales estará sujeto al consentimiento de
                                Usted, salvo las excepciones establecidas en la <b>LEY</b> y su
                                Reglamento.
                                Advertimos que de conformidad con el Artículo 8 de la <b>LEY</b>, Usted
                                está de
                                acuerdo y consiente tácitamente el tratamiento, obtención, recopilación
                                y almacenamiento de sus datos personales para los fines anteriormente
                                expuestos, si no manifiesta su oposición al presente Aviso de
                                Privacidad.</p>
                            <p><b>EMPRO</b> se reserva el derecho de efectuar en cualquier momento
                                modificaciones o actualizaciones al presente Aviso de Privacidad, para
                                la atención de reformas legislativas o jurisprudenciales, políticas
                                internas, nuevos requerimientos para la prestación u ofrecimiento de
                                nuestros servicios o productos y prácticas del mercado; siendo
                                modificado o actualizado unilateralmente por <b>EMPRO</b> e informándole
                                a
                                Usted de dichos cambios a través de la página web: <a
                                    href="www.empro.mx">www.empro.mx</a></p>
                            <p>
                                Fecha de última actualización 18/10/2018</p>
                            <p><b>Contacto</b><br>
                                EMPRO.<br>
                                Miguel Melgarejo Barba.<br>
                                Lada sin costo: 01800-0073330
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<footer class="al-footer clearfix" style="position: fixed">
    <div class="main-footer">
        <hr class="main-lineyellow">
        <div class="main-footer-content">
            <div class="left-footer">
                <span>T. 01800 &#9679; 007 &#9679; 3330</span>
            </div>
            <hr class="vertical-hr">
            <div class="right-footer">
                <div class="contact-footer">
                    <span>Salama # 546, Col. Valle del Tepeyac, 07740. Cuidad de México.</span>
                </div>
                <div class="social-footer">
                    <a href="mailto: contacto@empro.mx" target="blank"><em
                            class="fa fa-envelope"></em>contacto@empro.mx</a>
                    <a href="https://www.facebook.com/1433857256895989/" target="blank"><em
                            class="fa fa-facebook-square"></em>Empro</a>
                    <a href="https://twitter.com/empromx" target="blank"><em class="fa fa-twitter"></em>@empromx</a>
                    <a href="https://www.instagram.com/empromx/?igshid=1wt5j8uv17uzf" target="blank"><em
                            class="fa fa-instagram"></em>empromx</a>
                </div>
            </div>
        </div>
        <div class="bottom-footer">
            <span>© {{currentYear}}, Derechos reservados EMPRO</span>
            <span><a data-toggle="modal" data-target="#registroModal">Términos y condiciones | Aviso de
                    privacidad</a></span>
        </div>
    </div>
</footer>
<ba-back-top position="200"></ba-back-top>