import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Seguro } from "app/entidades/seguro";
import { HttpHelper } from "app/http-helper";

@Injectable()
export class SeguroService {
  private headers = HttpHelper.headersJSON;
  private segurosUrl = HttpHelper.url + "seguro";

  constructor(private http: HttpClient) {}

  private handleError(error: any): Promise<any> {
    let resp = error;
    if (resp.error) {
      console.error("SeguroService - ERROR: ", resp.error);
      return Promise.reject(resp.error);
    }
    return Promise.reject(error.message || error);
  }

  create(dataSeguro: Seguro): Promise<any> {
    const url = `${this.segurosUrl}/crear`;
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .post<any>(url, JSON.stringify(dataSeguro), { headers: this.headers })
        .toPromise()
        .then((res) => res as Promise<any>)
        .catch(this.handleError);
    }
  }

  update(dataSeguro: Seguro): Promise<any> {
    const url = `${this.segurosUrl}/update`;
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .post<any>(url, JSON.stringify(dataSeguro), { headers: this.headers })
        .toPromise()
        .then((res) => res as Promise<any>)
        .catch(this.handleError);
    }
  }

  getSeguros(filterQuery: string, sortBy: string, sortOrder: string, rowsOnPage: number, page: number) {
    const url = `${this.segurosUrl}/list`;
    let params = new HttpParams();
    if (filterQuery) {
      params = params.append("filterQuery", filterQuery);
    }
    if (sortBy) {
      params = params.append("sortBy", sortBy);
    }
    if (sortOrder) {
      params = params.append("sortOrder", sortOrder);
    }
    if (rowsOnPage) {
      params = params.append("rowsOnPage", rowsOnPage + "");
    }
    if (page) {
      params = params.append("page", page + "");
    }
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .get<any>(url, { params: params, headers: this.headers })
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  getListTipoSeguros(sortBy: string, sortOrder: string) {
    const url = `${this.segurosUrl}/listTipoSeguros`;
    let params = new HttpParams();

    if (sortBy) {
      params = params.append("sortBy", sortBy);
    }

    if (sortOrder) {
      params = params.append("sortOrder", sortOrder);
    }

    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      let options = { headers: this.headers, params: params }; // Create a request option
      return this.http
        .get<any>(url, options)
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  remove(id: number): Promise<any> {
    const url = `${this.segurosUrl}/eliminar/${id}`;
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .get<any>(url, { headers: this.headers })
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  autocomplete(filterQuery: string, otherParam: string, otherValue: string) {
    let params = new HttpParams();
    const url = `${this.segurosUrl}/autocomplete`;
    if (filterQuery) {
      params = params.append("filterQuery", filterQuery);
    }
    if (otherParam) {
      params = params.append("otherParam", otherParam);
    }
    if (otherValue) {
      params = params.append("otherValue", otherValue);
    }
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      let options = { headers: this.headers, params: params }; // Create a request option
      return this.http
        .get<any>(url, options)
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  getDetalle(id: number) {
    const url = `${this.segurosUrl}/detalle/${id}`;
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .get<any>(url, { headers: this.headers })
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }
}
