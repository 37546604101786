import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { EmpresaService } from "app/servicios-backend/empresa.service";
import { Empresa } from "app/entidades/empresa";
import { CertificadoService } from "app/servicios-backend/certificado.service";
import { UntypedFormBuilder, Validators, UntypedFormGroup, AbstractControl } from "@angular/forms";
import { RegimenFiscalService } from "app/servicios-backend/regimen-fiscal.service";
import { DropzoneService } from "app/servicios-backend/dropzone.service";
import { Subject } from "rxjs/Subject";
import { SharedService } from "app/shared.service";

@Component({
  selector: "app-registra-cfdi",
  templateUrl: "./registra-cfdi.component.html",
  styleUrls: ["./registra-cfdi.component.scss"],
})
export class RegistraCFDIComponent implements OnInit {
  public form: UntypedFormGroup;
  public cedulaIdentificacionFiscal: AbstractControl;
  public logo: AbstractControl;
  public empresa: Empresa = new Empresa();
  public rowsOnPage: number = 10;
  public sortBy: string;
  public nombreCert: string;
  public nombreLogo: string;
  public sortOrder: string = "asc";
  public page: number = 1;
  public totalCertificados: number = 0;
  public totalRegimenes: number = 0;
  public query: string = "";
  public subirIMG1: boolean = false;
  public subirIMG2: boolean = false;
  public eventosDropzone: Subject<{ id: string; evento: string; valor: string }> = new Subject();
  public tArchivo: string = ".jpg";
  public arAcept: string = ".jpeg,.jpg,.pdf,.png";
  public tipo = "/upload";
  public modulo = "1"; //
  public submodulo = "cfdi"; //carpeta
  public idDZa = "#dZUpload"; // Logotipo de la empresa
  public idDZb = "#dZUpload1"; // Cedula de identificación Fiscal
  // public validacionCert = this.nombreCert == null || this.nombreCert == undefined || this.nombreCert == ' ';
  public validacionLogo;
  public id: any;
  public nombre: any;
  public colonia: any;
  public localidad: any;
  public referencia: any;
  public municipio: any;
  public estado: any;
  public pais: any;
  public rfc: any;
  public registroPatronal: any;
  public riesgoPuesto: any;
  public calle: any;
  public numInterior: any;
  public numExterior: any;
  public lugarExpedicion: any;
  public error: boolean;
  public validancia: boolean = false;
  public cond: boolean = true;
  @Input() progress = "";
  @Input() progressVal = 0;
  @Input() step: number = 0;
  @Output() updateProgrees = new EventEmitter<{ dataEmp: any; totCer: number; totReg: number }>();

  public nameButton = "";
  constructor(private empresaService: EmpresaService, private certificadoService: CertificadoService, fb: UntypedFormBuilder, private regimenFiscalService: RegimenFiscalService, private dropzoneService: DropzoneService, private sharedService: SharedService) {
    this.form = fb.group({
      id: [""],
      nombre: [""],
      colonia: [""],
      localidad: [""],
      referencia: [""],
      municipio: [""],
      estado: [""],
      pais: [""],
      rfc: [""],
      registroPatronal: [""],
      riesgoPuesto: [""],
      calle: [""],
      numInterior: [""],
      numExterior: [""],
      lugarExpedicion: [""],
      cedulaIdentificacionFiscal: ["", Validators.required],
      logo: [""],
    });
    this.cedulaIdentificacionFiscal = this.form.controls["cedulaIdentificacionFiscal"];
    this.logo = this.form.controls["logo"];
  }

  ngOnInit() {
    this.dropzone();
    this.validacionLogo = this.nombreLogo == null || this.nombreLogo == undefined || this.nombreLogo == " ";
    this.eventosDropzone.subscribe((data: { id: string; evento: string; valor: any }) => {
      if (data.evento != undefined) {
        switch (data.evento) {
          case "rename":
            break;
          case "success":
            break;
          case "sending":
            if (data.id == this.idDZb) {
              this.nombreCert = data.valor;
            } else {
              this.nombreLogo = data.valor;
            }
            break;
          case "removedfile":
            break;
          case "addedfile":
            break;
          case "error":
            break;
          default:
            console.log("default: dropzone");
            break;
        }
      }
    });
  }
  // Modulo de archivos
  //   3 - Cedula_Identificacion
  //   1 - Logotipo
  dropzone() {
    this.empresaService
      .getDetalle()
      .then((data) => {
        if (data != undefined && data.id != undefined) {
          let id = data.id;
          this.dropzoneService.dropzone(this.idDZa, this.tArchivo, this.arAcept, this.tipo, 3, id, this.eventosDropzone, false, true);
          this.dropzoneService.dropzone(this.idDZb, this.tArchivo, this.arAcept, this.tipo, 1, id, this.eventosDropzone, false, true);
        }
      })
      .catch();
  }

  btnNext() {
    this.empresaService
      .getDetalle()
      .then((dataEmp: any) => {
        this.certificadoService
          .getCertidicados(this.query, this.sortBy, this.sortOrder, this.rowsOnPage, this.page)
          .then((dataCert) => {
            this.totalCertificados = dataCert.total;
            this.regimenFiscalService
              .getRegimenes(this.query, this.sortBy, this.sortOrder, this.rowsOnPage, this.page)
              .then((dataReg) => {
                let that = $("#cfdiNext");
                that.toggleClass("btn-wait-light");
                that.prop("disabled", false);
                this.totalRegimenes = dataReg.total;
                this.empresa = dataEmp;
                let condicionCSD = this.totalCertificados == 0;
                let condicionRF = this.totalRegimenes == 0;
                this.updateProgrees.emit({ dataEmp: dataEmp, totCer: this.totalCertificados, totReg: this.totalRegimenes });
                if (condicionCSD) {
                  $("#cfdiModal").modal("hide");
                  $("#csdModal").modal({ backdrop: "static", keyboard: false }, "show");
                } else if (condicionRF) {
                  $("#cfdiModal").modal("hide");
                  $("#rfModal").modal({ backdrop: "static", keyboard: false }, "show");
                } else {
                  $("#cfdiModal").modal("hide");
                  //window.location.reload();
                  this.sharedService.callParentMethod("reloadEmpresa");
                }
              })
              .catch((error) => {
                console.log("Hubo un error", error);
              });
          })
          .catch((error) => {
            console.log("Hubo un error", error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  btnPrev() {
    let that = $("#cfdiBack");
    that.toggleClass("btn-wait-light");
    that.prop("disabled", true);
    let dfeCondition: boolean = false;
    this.empresaService
      .getDetalle()
      .then((dataEmp: any) => {
        this.certificadoService
          .getCertidicados(this.query, this.sortBy, this.sortOrder, this.rowsOnPage, this.page)
          .then((dataCert) => {
            this.totalCertificados = dataCert.total;
            this.regimenFiscalService
              .getRegimenes(this.query, this.sortBy, this.sortOrder, this.rowsOnPage, this.page)
              .then((dataReg) => {
                that.toggleClass("btn-wait-light");
                that.prop("disabled", false);
                this.totalRegimenes = dataReg.total;
                let dLogo = dataEmp.logo == undefined || dataEmp.logo == null || dataEmp.logo == 0 || dataEmp.logo == " ";
                let dCeId = dataEmp.cedulaIdentificacionFiscal == undefined || dataEmp.cedulaIdentificacionFiscal == null || dataEmp.cedulaIdentificacionFiscal == 0 || dataEmp.cedulaIdentificacionFiscal == " ";
                if (dataEmp.rfc != undefined && dataEmp.lugarExpedicion != undefined) {
                  dfeCondition = true;
                }
                if (dfeCondition) {
                  $("#cfdiModal").modal("hide");
                  $("#dfeModal").modal({ backdrop: "static", keyboard: false }, "show");
                } else if (this.totalRegimenes) {
                  $("#cfdiModal").modal("hide");
                  $("#rfModal").modal({ backdrop: "static", keyboard: false }, "show");
                } else if (this.totalCertificados) {
                  $("#cfdiModal").modal("hide");
                  $("#csdModal").modal({ backdrop: "static", keyboard: false }, "show");
                } else {
                  $("#cfdiModal").modal("hide");
                }
              })
              .catch((error) => {
                that.toggleClass("btn-wait-light");
                that.prop("disabled", false);
                console.log("Hubo un error", error);
              });
          })
          .catch((error) => {
            that.toggleClass("btn-wait-light");
            that.prop("disabled", false);
            console.log("Hubo un error", error);
          });
      })
      .catch((error) => {
        that.toggleClass("btn-wait-light");
        that.prop("disabled", false);
        console.log(error);
      });
  }
  btnOM() {
    $("#cfdiModal").modal("hide");
  }

  agregarIMG() {
    let that = $("#cfdiNext");
    that.toggleClass("btn-wait-light");
    that.prop("disabled", true);

    this.empresaService
      .getDetalle()
      .then((data) => {
        this.id = data.id;
        this.nombre = data.nombre;
        this.colonia = data.colonia;
        this.localidad = data.localidad;
        this.referencia = data.referencia;
        this.municipio = data.municipio;
        this.estado = data.estado;
        this.pais = data.pais;
        this.rfc = data.rfc;
        this.registroPatronal = data.registroPatronal;
        this.riesgoPuesto = data.riesgoPuesto;
        this.calle = data.calle;
        this.numInterior = data.numInterior;
        this.numExterior = data.numExterior;
        this.lugarExpedicion = data.lugarExpedicion;
        this.nombreLogo = data.logo;
        if (data.cedulaIdentificacionFiscal || data.logo) {
          this.validancia = true;
          if (this.validancia == true) {
            let dataIMG = new Empresa();
            dataIMG.id = this.id;
            dataIMG.nombre = this.nombre;
            dataIMG.colonia = this.colonia;
            dataIMG.localidad = this.localidad;
            dataIMG.referencia = this.referencia;
            dataIMG.municipio = this.municipio;
            dataIMG.estado = this.estado;
            dataIMG.pais = this.pais;
            dataIMG.rfc = this.rfc;
            dataIMG.registroPatronal = this.registroPatronal;
            dataIMG.calle = this.calle;
            dataIMG.numInterior = this.numInterior;
            dataIMG.numExterior = this.numExterior;
            dataIMG.lugarExpedicion = this.lugarExpedicion;
            this.nombreCert = this.nombreCert.replace(/[^\x00-\x7F]/g, "");
            dataIMG.cedulaIdentificacionFiscal = this.nombreCert;
            if (data.cedulaIdentificacionFiscal != null && data.cedulaIdentificacionFiscal != undefined) {
              dataIMG.cedulaIdentificacionFiscal = data.cedulaIdentificacionFiscal;
            }
            if (this.nombreLogo != null && this.nombreLogo != undefined) {
              this.nombreLogo = this.nombreLogo.replace(/[^\x00-\x7F]/g, "");
              dataIMG.logo = this.nombreLogo;
            }
            if (data.logo != null && data.logo != undefined) {
              dataIMG.logo = data.logo;
            }
            dataIMG.ver = true;
            this.empresaService
              .update(dataIMG)
              .then(() => {
                this.btnNext();
              })
              .catch((error) => {
                console.log("ERROR-->: ", error);
              });
          }
        } else {
          that.toggleClass("btn-wait-light");
          that.prop("disabled", false);
          $("#cfdiModal").modal("hide");
          $("#dfeModal").modal({ backdrop: "static", keyboard: false }, "show");
          console.log("Error, Favor de llenar los campos obligatorios.");
          this.error = true;
        }
      })
      .catch((error) => {
        that.toggleClass("btn-wait-light");
        that.prop("disabled", false);
        $("#cfdiModal").modal("hide");
        $("#dfeModal").modal({ backdrop: "static", keyboard: false }, "show");
        console.log("ERROR", error);
      });
  }
}
