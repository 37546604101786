<div class="modal fade" id="cfdiModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div id="completar-registro-modal" class="modal-dialog modal-lg" role="document" style="overflow-y: auto;">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close color" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="progress">
            <div class="progress-bar progress-bar-warning progress-bar-striped active" role="progressbar"
              [attr.aria-valuenow]="progressVal" aria-valuemin="0" aria-valuemax="100" [style.width.%]="progressVal">
              {{ progress }}
            </div>
          </div>
          <br>
          <h1>
            <span class="color">{{ step }}. Sube tu cédula de identificación fiscal y el logotipo de tu empresa</span>
          </h1>
          <br>
          <h4> Al subir tu cédula la podrás consultar cuando quieras y al subir el logotipo lo podrás ver en tus CFDI´s.
            <br>
            <u>Me gustaría recibir asesoría para llenar estos datos.</u>
          </h4>
          <hr>
          <form role="form" autocomplete="off" [formGroup]="form" (ngSubmit)="agregarIMG()" novalidate>
            <div class="padre row">
              <br>
              <div class="col-md-6">
                <div class="card">
                  <div class="card-header">
                    <div class="card-title uploadfile-cardtit">
                      Cédula de identificación fiscal
                      <a class="" tooltip-location="left" tooltip-size="medium" tooltip-animate
                        tooltip="Imagen que contiene el código QR, el RFC, el nombre, denominación o razón social del contribuyente y el número idCIF.">
                        <em class="fa fa-question-circle fa-lg text-primary"></em>
                      </a>
                    </div>
                  </div>
                  <div class="card-block upload-btn-wrapper">
                    <h4 align="center">Arrastra tu imagen para subirla.</h4>
                    <h6 align="center">Formato requerido pdf o jpg.</h6>
                    <div id="dZUpload1" class="dropzone">
                      <div class="dz-default dz-message"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="card">
                  <div class="card-header">
                    <div class="card-title uploadfile-cardtit">Logotipo de la empresa
                      <a class="" tooltip-location="left" tooltip-size="medium" tooltip-animate
                        tooltip="Imagen del logotipo o de la marca de la empresa o la fotografía del usuario.">
                        <em class="fa fa-question-circle fa-lg text-primary"></em>
                      </a>
                    </div>
                  </div>
                  <div class="card-block">
                    <h4 align="center">Arrastra tu imagen para subirla.</h4>
                    <h6 align="center">Formato requerido pdf o jpg.</h6>
                    <div id="dZUpload" class="dropzone">
                      <div class="dz-default dz-message"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br>
            <div class="align-middle">
              <button id="cfdiBack" type="button" class="btn btn-warning" (click)="btnPrev()">Regresar</button>
              <button id="cfdiNext" type="submit" class="btn btn-primary">{{ step < 4 ? 'Continuar' : 'Finalizar'}}</button>
            </div>
          </form>
          <br>
          <div class="align-middle">
            <button type="submit" class="btn btn-cancelar" (click)="btnOM()">Hacerlo en otro momento</button>
            <br>
            <br>
            <br>
            <div class="alert alert-warning" *ngIf="error == true">
              <label for="">Favor de llenar los campos obligatorios marcados con
                <span class="required-input">*</span>
              </label>
            </div>
          </div>
        </div>
        <div class="modal-footer">
        </div>
      </div>
    </div>
  </div>