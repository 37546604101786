import { Component, ViewChild, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from "@angular/forms";
import { Subscription } from "rxjs/Rx";
import { Observable } from "rxjs/Observable";

import { Sucursal } from "../../entidades/sucursal";
import { ClienteProveedorService } from "../../servicios-backend/cliente-proveedor.service";
import { CpService } from "../../servicios-backend/cp.service";
import { ClienteProveedor } from "../../entidades/cliente-proveedor";
import { AutocompleteGenericComponent } from "app/comun/components/autocomplete-generic";
import { GlobalState } from "../../global.state";
import { RegimenFiscal } from "app/entidades/regimen-fiscal";
import { RegimenFiscalService } from "app/servicios-backend/regimen-fiscal.service";

@Component({
  selector: "clientes ",
  templateUrl: "./clientes.html",
  styleUrls: ["./clientes.scss"],
})
export class ClientesComponent implements OnInit {
  form: any;
  FormControl: any;
  formularioClientes: UntypedFormGroup;

  data: ClienteProveedor[];
  rowsOnPage: number = 10;
  sortBy: string = "nombre";
  sortOrder: string = "asc";
  page: number = 1;
  totalCliente: number = 0;
  regimenLisst: RegimenFiscal[];
  regimenCurrent: RegimenFiscal;
  public isMenuCollapsed: boolean = false;

  entidad_elimar: ClienteProveedor;
  valor: number;
  colonias = [];

  isEdit: boolean = false;
  regimenService: RegimenFiscalService;

  /**
   * Variables para filtrar por query
   */
  queryCliente: string = "";
  delayBeforeSearch: number = 10; // Delay in miliseconds
  formularioFilterQuery: UntypedFormGroup;
  querySubscription$: Subscription;
  changeOption: number;
  showIdExtranjero: boolean = false;

  @ViewChild("autocompleteCodigoPostal")
  autocompleteCodigoP: AutocompleteGenericComponent;
  @ViewChild("autocompleteSucursalService")
  autocompleteSucursalService: AutocompleteGenericComponent;
  @ViewChild("autocompleteRegimenes")
  autocompleteRegimen: AutocompleteGenericComponent;

  constructor(private clienteProveedorService: ClienteProveedorService, regimenService: RegimenFiscalService, private _fb: UntypedFormBuilder, private cpService: CpService, private _state: GlobalState) {
    this.regimenService = regimenService;
  }

  ngOnInit() {
    if (window.innerWidth < 1200) {
      this.toggleMenu();
    }
    $(document).ready(function () {
      $(".table-responsive").doubleScroll();
    });
    $("#preloader").show();
    $("#preloader").css("opacity", 0.8);
    this.initFormularioClientes();
    this.initFormQuery(() => {
      console.log("end");
    });
    this.buscarCliente(() => {
      $("#preloader").css("opacity", 1);
      $("#preloader").hide();
    });
  }

  public toggleMenu() {
    this.isMenuCollapsed = !this.isMenuCollapsed;
    this._state.notifyDataChanged("menu.isCollapsed", this.isMenuCollapsed);
    return false;
  }

  initFormularioClientes() {
    this.formularioClientes = new UntypedFormGroup({
      nombre: new UntypedFormControl(),
      correo: new UntypedFormControl(),
      t_cliente: new UntypedFormControl(),
      rfc: new UntypedFormControl(),
      tipoRegimen: new UntypedFormControl(),
      sucursal: new UntypedFormControl(),
      calle: new UntypedFormControl(),
      n_exterior: new UntypedFormControl(),
      n_interior: new UntypedFormControl(),
      cp: new UntypedFormControl(),
      colonia: new UntypedFormControl(),
      localidad: new UntypedFormControl(),
      municipio: new UntypedFormControl(),
      estado: new UntypedFormControl(),
      pais: new UntypedFormControl(),
      comentarios: new UntypedFormControl(),
      idExtranjero: new UntypedFormControl(),
    });
  }

  initFormQuery(completed) {
    this.formularioFilterQuery = this._fb.group({
      queryCliente: ["", [Validators.minLength(1)]],
    });
    let query$: Observable<any> = this.formularioFilterQuery.valueChanges.debounceTime(this.delayBeforeSearch);
    this.querySubscription$ = query$.subscribe((data) => {
      this.queryCliente = data.queryCliente;
      this.buscarCliente(() => {
        completed();
      });
    });
  }

  buscarCliente(completed) {
    this.clienteProveedorService
      .getClienteProveedor(this.queryCliente, this.sortBy, this.sortOrder, this.rowsOnPage, this.page, 1)
      .then((dataCliente) => {
        this.totalCliente = dataCliente.total;
        this.data = dataCliente.data;
        completed();
      })
      .catch((errorCliente) => {
        console.log(errorCliente);
        completed();
      });
  }

  selectTipo(tipoCliente: any) {
    this.showIdExtranjero = tipoCliente.target.value == "2: 2";
  }

  onChangeSize() {
    this.page = 1;
    this.buscarCliente(() => {
      console.log("end");
    });
  }

  onSort(event: { order: string; by: string }) {
    this.sortBy = event.by;
    this.sortOrder = event.order;
    this.buscarCliente(() => {
      console.log("end");
    });
  }

  pageChanged(event: any) {
    this.page = event;
    this.buscarCliente(() => {
      console.log("end");
    });
  }

  prepareAdd() {
    this.formularioClientes.reset();
    this.autocompleteCodigoP.cleanData();
    this.autocompleteSucursalService.cleanData();
    this.autocompleteRegimen.cleanData();
    this.isEdit = false;
    this.regimenLisst = [];
  }

  rfcValido(rfc, aceptarGenerico = true) {
    const re = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
    let validado = rfc.match(re);

    if (!validado)
      //Coincide con el formato general del regex?
      return false;

    //Separar el dígito verificador del resto del RFC
    const digitoVerificador = validado.pop(),
      rfcSinDigito = validado.slice(1).join(""),
      len = rfcSinDigito.length,
      //Obtener el digito esperado
      diccionario = "0123456789ABCDEFGHIJKLMN&OPQRSTUVWXYZ Ñ",
      indice = len + 1;
    let suma, digitoEsperado;

    if (len == 12) suma = 0;
    else suma = 481; //Ajuste para persona moral

    for (let i = 0; i < len; i++) suma += diccionario.indexOf(rfcSinDigito.charAt(i)) * (indice - i);
    digitoEsperado = 11 - (suma % 11);
    if (digitoEsperado == 11) digitoEsperado = 0;
    else if (digitoEsperado == 10) digitoEsperado = "A";
    if ((digitoVerificador != digitoEsperado && (!aceptarGenerico || rfcSinDigito + digitoVerificador != "XAXX010101000")) || (!aceptarGenerico && rfcSinDigito + digitoVerificador == "XEXX010101000")) {
      return false;
    }
    return rfcSinDigito + digitoVerificador;
  }

  onKey(event) {
    const inputValue = event.target.value;
    let rfc = inputValue,
      resultado = document.getElementById("rfc");

    let rfcCorrecto = this.rfcValido(rfc);
    if (rfcCorrecto) {
      resultado.classList.add("ok");
    } else {
      resultado.classList.remove("ok");
    }
  }

  onSubmitAgregar() {
    let agregar_cliente: ClienteProveedor = new ClienteProveedor();
    agregar_cliente.sucursal = new Sucursal();

    agregar_cliente.nombre = this.formularioClientes.get("nombre").value;
    agregar_cliente.correo = this.formularioClientes.get("correo").value;
    agregar_cliente.tipoCliente = this.formularioClientes.get("t_cliente").value;
    if (this.formularioClientes.get("rfc").value != undefined && this.formularioClientes.get("rfc").value != "") {
      agregar_cliente.rfc = this.formularioClientes.get("rfc").value.toUpperCase();
    }
    agregar_cliente.sucursal.id = this.formularioClientes.get("sucursal").value;
    agregar_cliente.calle = this.formularioClientes.get("calle").value;
    agregar_cliente.numeroExterior = this.formularioClientes.get("n_exterior").value;
    agregar_cliente.numeroInterior = this.formularioClientes.get("n_interior").value;
    agregar_cliente.cp = this.formularioClientes.get("cp").value;
    agregar_cliente.colonia = this.formularioClientes.get("colonia").value;
    agregar_cliente.localidad = this.formularioClientes.get("localidad").value;
    agregar_cliente.municipio = this.formularioClientes.get("municipio").value;
    agregar_cliente.estado = this.formularioClientes.get("estado").value;
    agregar_cliente.pais = this.formularioClientes.get("pais").value;
    agregar_cliente.comentarios = this.formularioClientes.get("comentarios").value;
    if (this.formularioClientes.get("idExtranjero").value != undefined && this.formularioClientes.get("idExtranjero").value != "") {
      agregar_cliente.idExtranjero = this.formularioClientes.get("idExtranjero").value.toUpperCase();
    }
    agregar_cliente.regimenFiscalList = this.regimenLisst;

    //Tipo: cliente
    agregar_cliente.tipo = 1;

    this.clienteProveedorService
      .create(agregar_cliente)
      .then((dato) => {
        this.buscarCliente(() => {
          console.log("end");
        });
      })
      .catch((error) => {
        $("#clientError #tituloAlerta").html("Algo salio mal");
        $("#clientError p").html(error.error);
        $("#clientError #buttonAlert").html("OK");
        $("#clientError").modal("show");
      });
    $("#Clientes").modal("hide");
  }

  onSubmitClientes() {
    if (this.isEdit) {
      this.onSubmitEditar();
    } else {
      this.onSubmitAgregar();
    }
  }

  prepareEdit(entity_edit: any) {
    this.onClick_edit(entity_edit);
    this.isEdit = true;
    if (entity_edit.cp) {
      this.autocompleteCodigoP.getLugarExpedicion(entity_edit.cp);
      this.formularioClientes.get("cp").setValue(entity_edit.cp);
    }
    if (entity_edit.sucursal != null && entity_edit.sucursal != undefined && entity_edit.sucursal.id && entity_edit.sucursal.id > 0) {
      this.autocompleteSucursalService.getDetalle(entity_edit.sucursal.id, () => {
        this.formularioClientes.get("sucursal").setValue(entity_edit.sucursal.id);
      });
    }
  }

  onCodigoPostalSelected(cp: any) {
    this.colonias = [];
    if (cp !== undefined && cp != null && cp > 0) {
      this.cpService
        .consultarLocalizacion(cp)
        .then((data) => {
          this.formularioClientes.get("cp").setValue(cp);
          this.formularioClientes.get("municipio").setValue(data.data.municipio);
          this.formularioClientes.get("estado").setValue(data.data.estado);
          this.formularioClientes.get("pais").setValue("México");
          for (let colonia of data.data.colonia) {
            this.colonias.push(colonia);
          }
          if (this.colonias.length == 1) {
            this.formularioClientes.get("colonia").setValue(this.colonias[0]);
          }
        })
        .catch((error) => {
          $("#clientError #tituloAlerta").html("Algo salio mal");
          $("#clientError p").html(error.error);
          $("#clientError #buttonAlert").html("OK");
          $("#clientError").modal("show");
        });
    } else {
      this.formularioClientes.get("cp").setValue(0);
    }
  }

  onClick_edit(entity_edit: any) {
    this.prepareAdd();
    this.isEdit = true;
    this.formularioClientes.get("nombre").setValue(entity_edit.nombre);
    this.formularioClientes.get("correo").setValue(entity_edit.correo);
    this.formularioClientes.get("t_cliente").setValue(entity_edit.tipoCliente);
    this.formularioClientes.get("rfc").setValue(entity_edit.rfc);
    if (entity_edit.sucursal != null && entity_edit.sucursal != undefined) {
      this.formularioClientes.get("sucursal").setValue(entity_edit.sucursal.id);
    }
    this.formularioClientes.get("calle").setValue(entity_edit.calle);
    this.formularioClientes.get("n_exterior").setValue(entity_edit.numeroExterior);
    this.formularioClientes.get("n_interior").setValue(entity_edit.numeroInterior);
    this.formularioClientes.get("cp").setValue(entity_edit.cp);
    this.formularioClientes.get("colonia").setValue(entity_edit.colonia);
    this.formularioClientes.get("localidad").setValue(entity_edit.localidad);
    this.formularioClientes.get("municipio").setValue(entity_edit.municipio);
    this.formularioClientes.get("estado").setValue(entity_edit.estado);
    this.formularioClientes.get("pais").setValue(entity_edit.pais);
    this.formularioClientes.get("comentarios").setValue(entity_edit.comentarios);
    this.formularioClientes.get("idExtranjero").setValue(entity_edit.idExtranjero);
    this.showIdExtranjero = this.formularioClientes.get("t_cliente").value == 2;

    this.regimenLisst = entity_edit.regimenFiscalList;

    this.valor = entity_edit.id;
  }

  onSubmitEditar() {
    let editar_cliente: ClienteProveedor = new ClienteProveedor();
    editar_cliente.sucursal = new Sucursal();

    editar_cliente.id = this.valor;
    editar_cliente.nombre = this.formularioClientes.get("nombre").value;
    editar_cliente.correo = this.formularioClientes.get("correo").value;
    editar_cliente.tipoCliente = this.formularioClientes.get("t_cliente").value;
    if (this.formularioClientes.get("rfc").value != undefined && this.formularioClientes.get("rfc").value != "") {
      editar_cliente.rfc = this.formularioClientes.get("rfc").value.toUpperCase();
    }
    editar_cliente.sucursal.id = this.formularioClientes.get("sucursal").value;
    editar_cliente.calle = this.formularioClientes.get("calle").value;
    editar_cliente.numeroExterior = this.formularioClientes.get("n_exterior").value;
    editar_cliente.numeroInterior = this.formularioClientes.get("n_interior").value;
    editar_cliente.cp = this.formularioClientes.get("cp").value;
    editar_cliente.colonia = this.formularioClientes.get("colonia").value;
    editar_cliente.localidad = this.formularioClientes.get("localidad").value;
    editar_cliente.municipio = this.formularioClientes.get("municipio").value;
    editar_cliente.estado = this.formularioClientes.get("estado").value;
    editar_cliente.pais = this.formularioClientes.get("pais").value;
    editar_cliente.comentarios = this.formularioClientes.get("comentarios").value;
    if (this.formularioClientes.get("idExtranjero").value != undefined && this.formularioClientes.get("idExtranjero").value != "") {
      editar_cliente.idExtranjero = this.formularioClientes.get("idExtranjero").value.toUpperCase();
    }
    editar_cliente.regimenFiscalList = this.regimenLisst;
    editar_cliente.tipo = 1;
    editar_cliente.ver = true;

    this.clienteProveedorService
      .update(editar_cliente)
      .then((dato) => {
        this.buscarCliente(() => {
          console.log("end");
        });
      })
      .catch((error) => {
        $("#clientError #tituloAlerta").html("Algo salio mal");
        $("#clientError p").html(error.error);
        $("#clientError #buttonAlert").html("OK");
        $("#clientError").modal("show");
      });
    this.formularioClientes.reset();
    $("#Clientes").modal("hide");
  }

  onClick_elim(entity: any) {
    this.formularioClientes.reset();
    this.entidad_elimar = entity;
  }

  remove_cliente(id: number) {
    this.clienteProveedorService
      .remove(id)
      .then((dato) => {
        this.buscarCliente(() => {
          console.log("end");
        });
      })
      .catch((error) => {
        if (error.error == "cant_delete") {
          let cliPro = new ClienteProveedor();
          cliPro.id = id;
          cliPro.ver = false;
          this.clienteProveedorService
            .update(cliPro)
            .then((data: any) => {
              this.buscarCliente(() => {
                console.log("end");
              });
            })
            .catch((error) => {
              console.log(error);
              this.buscarCliente(() => {
                console.log("end");
              });
              $("#clientError #tituloAlerta").html("Algo salio mal");
              $("#clientError p").html(error.error);
              $("#clientError #buttonAlert").html("OK");
              $("#clientError").modal("show");
            });
        }
      });
    this.formularioClientes.reset();
    $("#eliminar").modal("hide");
  }

  onIdSucursalSelected(id) {
    if (id !== undefined && id != null && id > 0) {
      this.formularioClientes.get("sucursal").setValue(id);
    } else {
      this.formularioClientes.get("sucursal").setValue(0);
    }
  }

  exportarExcel() {
    this.clienteProveedorService.getCSVInfoCliente();
  }

  closeError() {
    this.change(1);
  }

  change(valor) {
    this.changeOption = valor;
  }

  onIdRegimenesSelected(tipoRegimen: any) {
    if (tipoRegimen !== undefined && tipoRegimen != null && tipoRegimen > 0) {
      this.regimenService
        .getDetalleTipo(tipoRegimen)
        .then((tipoRe) => {
          let regimenF: RegimenFiscal = new RegimenFiscal();
          regimenF.tipoRegimen = tipoRe;
          regimenF.regimenDefault = false;
          regimenF.clienteProveedor = new ClienteProveedor();
          regimenF.clienteProveedor.id = this.valor;
          this.regimenCurrent = regimenF;
        })
        .catch((errorxyz) => {
          console.log(errorxyz);
        });
    } else {
      this.formularioClientes.get("tipoRegimen").setValue(0);
    }
  }

  addRegimen() {
    if (this.regimenCurrent != null) {
      let isrea = false;
      this.regimenLisst.forEach((regi) => {
        if (regi.tipoRegimen.id == this.regimenCurrent.tipoRegimen.id) {
          isrea = true;
        }
      });
      if (!isrea) {
        this.regimenLisst.push(this.regimenCurrent);
        if (this.regimenLisst.length == 1) {
          this.regimenLisst[0].regimenDefault = true;
        }
        this.regimenCurrent = null;
        this.autocompleteRegimen.cleanData();
      }
    }
  }

  eliminarRegimen(index: number) {
    this.regimenLisst.splice(index, 1);
  }

  regimenDefault(index: number, event: any) {
    const isChecked = event.target.checked;
    this.regimenLisst.forEach((regimen, i) => {
      this.regimenLisst[i].regimenDefault = false;
    });
    this.regimenLisst[index].regimenDefault = isChecked;
  }
}
