import { RiesgoPuesto } from "./riesgo-puesto";
import { Certificado } from "./certificado";
import { RegimenFiscal } from "./regimen-fiscal";
import { Sucursal } from "./sucursal";
import { PaqueteTimbres } from "./paquete-timbres";
export class Empresa {
  id: number;
  nombre: string;
  rfc: string;
  calle: string;
  numInterior: string;
  numExterior: string;
  colonia: string;
  localidad: string;
  referencia: string;
  municipio: string;
  estado: string;
  pais: string;
  lugarExpedicion: number;
  registroPatronal: string;
  cedulaIdentificacionFiscal: string;
  logo: string;
  fechaRegistro: number;
  fechaModificacion: number;
  riesgoPuesto: RiesgoPuesto;
  certificadoList: Array<Certificado>;
  regimenFiscalList: Array<RegimenFiscal>;
  sucursalList: Array<Sucursal>;
  fechaDonatarias: number;
  noDonatarias: string;
  leyendaDonatarias: string;
  rvoeEmpresa: string;
  esEquipoTransporte: boolean;
  objImpuesto: number;
  nivel: string;
  timbresRestantes: number;
  caducidad: number;
  waitPaquete: boolean;
  ver: boolean;
  esDonataria: boolean;
}
