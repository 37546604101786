import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { HttpHelper } from "app/http-helper";
import { PaqueteTimbres } from "app/entidades/paquete-timbres";
import { PerfilEmpresa } from "app/entidades/perfil-empresa";

@Injectable()
export class PaqueteTimbresService {
  private headers = HttpHelper.headersJSON;
  private paqueteUrl = HttpHelper.url + "paqueteTimbres";

  constructor(private http: HttpClient) {}

  private handleError(error: any): Promise<any> {
    let resp = error;
    if (resp.error) {
      console.log("PaqueteTimbres -Error: ", resp.error);
      return Promise.reject(resp.error);
    }
    return Promise.reject(error.message || error);
  }

  create(dataTimbrePaquete: PaqueteTimbres) {
    const url = `${this.paqueteUrl}/crear`;
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .post<any>(url, JSON.stringify(dataTimbrePaquete), { headers: this.headers })
        .toPromise()
        .then((res) => res as Promise<any>)
        .catch(this.handleError);
    }
  }
  createPerfil(perfil: PerfilEmpresa) {
    const url = `${this.paqueteUrl}/crearPerfil`;
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .post<any>(url, JSON.stringify(perfil), { headers: this.headers })
        .toPromise()
        .then((res) => res as Promise<any>)
        .catch(this.handleError);
    }
  }

  update(dataTimbrePaquete: PaqueteTimbres) {
    const url = `${this.paqueteUrl}/update`;
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .post<any>(url, JSON.stringify(dataTimbrePaquete), { headers: this.headers })
        .toPromise()
        .then((res) => res as Promise<any>)
        .catch(this.handleError);
    }
  }

  getPerfil(idEmpresa: number) {
    const url = `${this.paqueteUrl}/getPerfil/${idEmpresa}`;
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .get<any>(url, { headers: this.headers })
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  getPaqueteTimbres(filterQuery: string, sortBy: string, sortOrder: string, rowsOnPage: number, page: number, empresaId: number) {
    const url = `${this.paqueteUrl}/list`;
    let params = new HttpParams();
    if (filterQuery) params = params.append("filterQuery", filterQuery);

    if (sortBy) params = params.append("sortBy", sortBy);

    if (sortOrder) {
      params = params.append("sortOrder", sortOrder);
    }
    if (rowsOnPage) {
      params = params.append("rowsOnPage", rowsOnPage + "");
    }
    if (page) {
      params = params.append("page", page + "");
    }
    if (empresaId) {
      params = params.append("empresaId", empresaId + "");
    }
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      let options = { headers: this.headers, params: params }; // Create a request option
      return this.http
        .get(url, options)
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  getPaquetesEnEspera() {
    const url = `${this.paqueteUrl}/enEspera`;
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      let options = { headers: this.headers }; // Create a request option
      return this.http
        .get(url, options)
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }
}
