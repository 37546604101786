<div class="modal fade" id="paqueteTimbresModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" style="overflow-y: auto">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="exampleModalLabel">Adquiere paquete de timbres</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
          <section id="prices-cards">
            <a class="pricing-card" id="card-lite">
              <div>
                <em class="fa fa-bell"></em>
                <h3>Lite</h3>
                <ul>
                  <li>CFDI's ilimitados durante 3 meses</li>
                  <li>1 usuario administrador.</li>
                  <li>Reporte de ingresos</li>
                  <li>Soporte técnico.</li>
                </ul>
                <h4>$499</h4>
                <p>Por mes</p>
                <Button class="btn primary" (click)="preparePaquete('Lite')" [attr.disabled]="disabledLite ? true : null">{{ textLiteButton }}</Button>
              </div>
              <label class="error-message">{{ messageLite }}</label>
            </a>

            <a class="pricing-card" id="card-pyme">
              <div>
                <em class="fa fa-bell"></em>
                <h3>PyME</h3>
                <ul>
                  <li>50 CFDI's.</li>
                  <li>1 usuario administrador y 1 usuario adicional</li>
                  <li>2 Sucursales.</li>
                  <li>Carga manual de egresos.</li>
                  <li>Reporte de ingresos.</li>
                  <li>Presentación de 1 declaración al mes.</li>
                  <li>Control externo de nómina 2 trabajadores.</li>
                  <li>Balanza general.</li>
                  <li>1 asesoría en finanzas personales y patrimoniales.</li>
                  <li>Soporte técnico y 2 asistencias al mes.</li>
                </ul>
                <h5>A partir de $900 - $1400</h5>
                <p>Por mes</p>
                <Button class="btn primary" (click)="preparePaquete('Pyme')" [attr.disabled]="disabledPyme ? true : null">{{ textPymeButton }}</Button>
              </div>
              <label class="error-message">{{ messagePyme }}</label>
            </a>

            <a class="pricing-card" id="card-premium">
              <div>
                <em class="fa fa-bell"></em>
                <h3>Premium</h3>
                <ul>
                  <li>100 o más CFDI's.</li>
                  <li>Usuarios ilimitados.</li>
                  <li>Sucursales ilimitadas.</li>
                  <li>Carga manual de egresos.</li>
                  <li>Reporte de ingresos.</li>
                  <li>Presentación de 3 declaraciones al mes.</li>
                  <li>Presentación de declaración anual del ejercicio.</li>
                  <li>Control externo de nómina 5 a 10 trabajadores.</li>
                  <li>Balanza general, estado de resultados y flujo de efectivo.</li>
                  <li>Conciliación bancaria.</li>
                  <li>Asesorías patrimoniales y planes de previsión social.</li>
                  <li>Soporte técnico y asistencias ilimitadas.</li>
                </ul>
                <h5>a partir de $2500 - $3800</h5>
                <p>Por mes</p>
                <Button class="btn primary" (click)="preparePaquete('Premium')" [attr.disabled]="disabledPremium ? true : null">{{ textPremiumButton }}</Button>
              </div>
              <label class="error-message">{{ messagePremium }}</label>
            </a>
          </section>
        </div>
        <div class="modal-footer"></div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="paqueteTimbresInstrucciones" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="exampleModalLabel">Confirmar plan</h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <form [formGroup]="cuestionarioForm" class="align-middle" (ngSubmit)="sendPerfil(paqueteSelected)" novalidate>
        <div class="modal-body">
          <div class="container" *ngIf="paqueteSelected == 'Lite'">
            <h2 *ngIf="!iswaith">Está solicitando un nuevo plan.</h2>
            <h2 *ngIf="iswaith">{{ textConfimacion }}</h2>
            <h4 *ngIf="iswaith">¿Desea descartarlo y cambiar de plan a {{ paqueteSelected }}?</h4>
            <h4 *ngIf="!iswaith">¿Desea pedir el plan {{ paqueteSelected }}?</h4>
            <div>Espere a que un asesor se ponga en contacto para poder activar su nuevo plan.</div>
          </div>
          <div class="container" *ngIf="paqueteSelected != 'Lite'">
            <div>
              <dl class="row">
                <dt class="col-sm-6">
                  <div class="form-group">
                    <p>¿Eres Persona Física o Persona Moral?</p>
                    <fieldset class="form-control shadow-border">
                      <ng-container *ngFor="let value of personas">
                        <label> <input type="radio" [value]="value" name="persona" [(ngModel)]="tipoPersona" [ngModelOptions]="{ standalone: true }" (change)="selectperson()" />{{ value === "fisica" ? "Física" : "Moral" }} </label></ng-container
                      >
                    </fieldset>
                  </div>
                </dt>
                <dt class="col-sm-6" *ngIf="tipoPersona == 'fisica'">
                  <div class="form-group">
                    <p>¿Que tipo de persona eres? <span class="required-input">*</span></p>
                    <div class="content-select" style="margin-bottom: 1em">
                      <select formControlName="persona_fisica" class="form-control" id="persona_fisica" [ngClass]="{ 'alert-shadow-border': !cuestionarioForm.controls.persona_fisica.value, 'success-shadow-border': cuestionarioForm.controls.persona_fisica.value }" [(ngModel)]="personaFisica">
                        <option [ngValue]="null" selected disabled>{{ "general.selectOption" | translate }}</option>
                        <option [ngValue]="1">Asalariado</option>
                        <option [ngValue]="2">Honorarios o servicios profesionales</option>
                        <option [ngValue]="3">Actividad empresarial (papelería, cafetería, etc.)</option>
                        <option [ngValue]="4">Incorporación fiscal</option>
                      </select>
                    </div>
                  </div>
                </dt>
                <dt class="col-sm-6" *ngIf="tipoPersona == 'moral'">
                  <div class="form-group">
                    <p>¿Que tipo de persona eres? <span class="required-input">*</span></p>
                    <div class="content-select" style="margin-bottom: 1em">
                      <select formControlName="persona_moral" class="form-control" id="persona_moral" [ngClass]="{ 'alert-shadow-border': !cuestionarioForm.controls.persona_moral.value, 'success-shadow-border': cuestionarioForm.controls.persona_moral.value }" [(ngModel)]="personaMoral">
                        <option [ngValue]="null" selected disabled>{{ "general.selectOption" | translate }}</option>
                        <option [ngValue]="1">Sociedad por Acciones Simplificada</option>
                        <option [ngValue]="2">Sociedad Anónima</option>
                        <option [ngValue]="3">Sociedad de Responsabilidad Limitada</option>
                        <option [ngValue]="4">Sociedad Cooperativa</option>
                      </select>
                    </div>
                  </div>
                </dt>
                <dt class="col-sm-12">
                  <div class="form-group">
                    <p>¿Bajo qué régimen fiscal tributas?<span class="required-input">*</span></p>
                    <div class="content-select" style="margin-bottom: 1em">
                      <select formControlName="regimen" class="form-control" id="regimen" [ngClass]="{ 'alert-shadow-border': !selectRegimen, 'success-shadow-border': selectRegimen }" [(ngModel)]="selectRegimen">
                        <option [ngValue]="null" selected disabled>{{ "general.selectOption" | translate }}</option>
                        <option [ngValue]="1">Régimen Simplificado de Confianza</option>
                        <option [ngValue]="2">Régimen de Actividades Empresariales con ingresos a través de Plataformas Tecnológicas</option>
                        <option [ngValue]="3">Régimen de Arrendamiento</option>
                        <option [ngValue]="4">Régimen de Actividades Empresariales y Profesionales</option>
                        <option [ngValue]="5">General de Ley Personas Morales</option>
                        <option [ngValue]="6">Personas Morales con Fines No Lucrativos</option>
                        <option [ngValue]="7">Residentes en el Extranjero sin Establecimiento Permanente en México</option>
                        <option [ngValue]="8">Sociedades Cooperativas de Producción que optan por diferir sus ingresos</option>
                        <option [ngValue]="9">Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras</option>
                        <option [ngValue]="10">Coordinados</option>
                        <option [ngValue]="11">Ingresos por Dividendos (socios y accionistas)</option>
                        <option [ngValue]="12">Ingresos por intereses</option>
                        <option [ngValue]="13">Régimen de los ingresos por obtención de premios</option>
                        <option [ngValue]="14">Sueldos y Salarios e Ingresos Asimilados a Salarios</option>
                        <option [ngValue]="15">Incorporación Fiscal</option>
                      </select>
                      <div *ngIf="cuestionarioForm.controls.regimen.errors?.required && !cuestionarioForm.controls.regimen.pristine" class="alert alert-danger">El regimen es requerido</div>
                    </div>
                  </div>
                </dt>
                <dt class="col-sm-12">
                  <div class="form-group">
                    <p>¿A qué te dedicas, es decir, cuál es tu actividad económica? Profundiza lo más posible en tu respuesta.<span class="required-input">*</span></p>
                    <textarea formControlName="actividad_detalle" class="form-control" id="actividad_detalle" [ngClass]="{ 'alert-shadow-border': !cuestionarioForm.controls.actividad_detalle.value, 'success-shadow-border': cuestionarioForm.controls.actividad_detalle.value }"></textarea>
                  </div>
                </dt>
                <dt class="col-sm-12">
                  <div class="form-group">
                    <p>¿Cuáles son tus obligaciones fiscales?</p>
                    <fieldset class="form-control" style="height: auto" [ngClass]="getCheckboxFieldsetClass()">
                      <div class="concepto-cell concepto-cell-row col-sm-12" style="justify-content: flex-start">
                        <input type="checkbox" class="form-control check" id="declara_iva" formControlName="declara_iva" />
                        <label for="declara_iva" id="declara_iva_lab">Declaración de proveedores de IVA</label>
                      </div>
                      <div class="concepto-cell concepto-cell-row col-sm-12" style="justify-content: flex-start">
                        <input type="checkbox" class="form-control check" id="pago_def_men_iva" formControlName="pago_def_men_iva" />
                        <label for="pago_def_men_iva" id="pago_def_men_iva_lab">Pago definitivo mensual de IVA</label>
                      </div>
                      <div class="concepto-cell concepto-cell-row col-sm-12" style="justify-content: flex-start">
                        <input type="checkbox" class="form-control check" id="pago_pro_men_isr" formControlName="pago_pro_men_isr" />
                        <label for="pago_pro_men_isr" id="pago_pro_men_isr_lab">Pago provisional mensual de ISR</label>
                      </div>
                      <div class="concepto-cell concepto-cell-row col-sm-12" style="justify-content: flex-start">
                        <input type="checkbox" class="form-control check" id="ajus_an_isr" formControlName="ajus_an_isr" />
                        <label for="ajus_an_isr" id="ajus_an_isr_lab">Ajuste anual de ISR correspondiente a la declaración anual</label>
                      </div>
                      <div class="concepto-cell concepto-cell-row col-sm-12" style="justify-content: flex-start">
                        <input type="checkbox" class="form-control check" id="declara_isr_anual" formControlName="declara_isr_anual" />
                        <label for="declara_isr_anual" id="declara_isr_anual_lab">Declaración anual de ISR</label>
                      </div>
                      <div class="concepto-cell concepto-cell-row col-sm-12" style="justify-content: flex-start">
                        <input type="checkbox" class="form-control check" id="rete_men_isr" formControlName="rete_men_isr" />
                        <label for="rete_men_isr" id="rete_men_isr_lab">Entero de retenciones mensuales de ISR por sueldos y salarios</label>
                      </div>
                      <div class="concepto-cell concepto-cell-row col-sm-12" style="justify-content: flex-start">
                        <input type="checkbox" class="form-control check" id="declara_info_anual" formControlName="declara_info_anual" />
                        <label for="declara_info_anual" id="declara_info_anual_lab">Declaración informativa de IVA con la anual de ISR</label>
                      </div>
                      <div class="concepto-cell concepto-cell-row col-sm-12" style="justify-content: flex-start">
                        <p>Otra:</p>
                        <input type="text" class="form-control" id="otra_obl" formControlName="otra_obl" style="width: inherit; margin-left: 1em" />
                      </div>
                    </fieldset>
                  </div>
                </dt>
                <dt class="col-sm-12">
                  <div class="form-group">
                    <p>¿Cuántas facturas realizas al mes aproximadamente?<span class="required-input">*</span></p>
                    <div class="content-select" style="margin-bottom: 1em">
                      <select formControlName="no_facturas" class="form-control" id="no_facturas" [ngClass]="{ 'alert-shadow-border': !noFacturas, 'success-shadow-border': noFacturas }" [(ngModel)]="noFacturas">
                        <option [ngValue]="null" selected disabled>{{ "general.selectOption" | translate }}</option>
                        <option [ngValue]="1">De 1 a 10</option>
                        <option [ngValue]="2">De 11 a 50</option>
                        <option [ngValue]="3">De 50 a 100</option>
                        <option [ngValue]="4">Mas de 100</option>
                      </select>
                      <div *ngIf="cuestionarioForm.controls.no_facturas.errors?.required && !cuestionarioForm.controls.no_facturas.pristine" class="alert alert-danger">El número de faturas es requrido</div>
                    </div>
                  </div>
                </dt>
                <dt class="col-sm-12">
                  <div class="form-group">
                    <p>Normalmente ¿cómo emites tus facturas de ingresos, PUE o PPD?</p>
                    <fieldset class="form-control shadow-border">
                      <ng-container *ngFor="let value of ingresos">
                        <label> <input type="radio" [value]="value" name="ingreso" [(ngModel)]="tipoIngreso" [ngModelOptions]="{ standalone: true }" />{{ value }} </label></ng-container
                      >
                    </fieldset>
                  </div>
                </dt>
                <dt class="col-sm-12">
                  <div class="concepto-cell concepto-cell-row col-sm-12" style="justify-content: flex-start">
                    <label for="is_gral">¿Realizas facturas a PUBLICO en GENERAL?</label>
                    <input type="checkbox" class="form-control check" id="is_gral" formControlName="is_gral" />
                  </div>
                </dt>
                <dt class="col-sm-12">
                  <div class="form-group">
                    <p>¿Tienes trabajadores o asimilados a salarios?<span class="required-input">*</span></p>
                    <div class="content-select" style="margin-bottom: 1em">
                      <select formControlName="no_trab" class="form-control" id="no_trab" [ngClass]="{ 'alert-shadow-border': !noTrabajadores, 'success-shadow-border': noTrabajadores }" [(ngModel)]="noTrabajadores">
                        <option [ngValue]="null" selected disabled>{{ "general.selectOption" | translate }}</option>
                        <option [ngValue]="1">De 1 a 5</option>
                        <option [ngValue]="2">De 5 a 10</option>
                        <option [ngValue]="3">De 10 a 50</option>
                        <option [ngValue]="4">Mas de 50</option>
                      </select>
                      <div *ngIf="cuestionarioForm.controls.no_trab.errors?.required && !cuestionarioForm.controls.no_trab.pristine" class="alert alert-danger">El número de trabajadores es requerido</div>
                    </div>
                  </div>
                </dt>
                <dt class="col-sm-12">
                  <div class="form-group">
                    <p>A tu consideración ¿cómo categorizarías tu rotación de personal, baja, media, alta?<span class="required-input">*</span></p>
                    <div class="content-select" style="margin-bottom: 1em">
                      <select formControlName="rotacion" class="form-control" id="rotacion" [ngClass]="{ 'alert-shadow-border': !selectRotacion, 'success-shadow-border': selectRotacion }" [(ngModel)]="selectRotacion">
                        <option [ngValue]="null" selected disabled>{{ "general.selectOption" | translate }}</option>
                        <option [ngValue]="1">Baja</option>
                        <option [ngValue]="2">Media</option>
                        <option [ngValue]="3">Alta</option>
                      </select>
                      <div *ngIf="cuestionarioForm.controls.rotacion.errors?.required && !cuestionarioForm.controls.rotacion.pristine" class="alert alert-danger">El tipo de rotación del personal es requerido</div>
                    </div>
                  </div>
                </dt>
                <dt class="col-sm-12">
                  <div class="concepto-cell concepto-cell-row col-sm-12" style="justify-content: flex-start">
                    <label for="is_inventario">¿Manejas inventario?</label>
                    <input type="checkbox" class="form-control check" id="is_inventario" formControlName="is_inventario" />
                  </div>
                </dt>
              </dl>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-opcion btn-raised" data-dismiss="modal">Cancelar</button>
          <button type="button" (click)="onSubmitAgregar(paqueteSelected)" class="btn btn-colorblue btn-raised" *ngIf="paqueteSelected == 'Lite'">Aceptar</button>
          <button type="submit" class="btn btn-colorblue btn-raised" *ngIf="paqueteSelected != 'Lite'"  [attr.disabled]="!cuestionarioForm.valid ? true : null">Aceptar</button>
        </div>
      </form>
    </div>
  </div>
</div>
