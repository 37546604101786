import { Injectable } from "@angular/core";
import { HttpParams, HttpClient } from "@angular/common/http";
import { Trabajador } from "../entidades/trabajador";
import { HttpHelper } from "../http-helper";
import "rxjs/add/operator/toPromise";

@Injectable()
export class TrabajadorService {
  private headers = HttpHelper.headersJSON;
  private trabajadorUrl = HttpHelper.url + "trabajador";

  constructor(private http: HttpClient) {}

  private handleError(error: any): Promise<any> {
    let resp = error;
    if (resp.error) {
      console.error("TrabajadorService - ERROR: ", resp.error);
      return Promise.reject(resp.error);
    }
    return Promise.reject(error.message || error);
  }

  getDetalle(id: number): Promise<Trabajador> {
    const url = `${this.trabajadorUrl}/detalle/${id}`;
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .get<any>(url, { headers: this.headers })
        .toPromise()
        .then((response) => response as Promise<Trabajador>)
        .catch(this.handleError);
    }
  }

  update(dataTrabajador: Trabajador): Promise<any> {
    const url = `${this.trabajadorUrl}/update`;
    let token = localStorage.getItem("token");
    this.headers = this.headers.set("token", token);
    return this.http
      .post<any>(url, JSON.stringify(dataTrabajador), { headers: this.headers })
      .toPromise()
      .then((res) => res as Promise<any>)
      .catch(this.handleError);
  }

  create(dataTrabajador: Trabajador): Promise<any> {
    const url = `${this.trabajadorUrl}/crear`;
    let token = localStorage.getItem("token");
    this.headers = this.headers.set("token", token);

    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .post<any>(url, JSON.stringify(dataTrabajador), { headers: this.headers })
        .toPromise()
        .then((res) => res as Promise<any>)
        .catch(this.handleError);
    }
  }

  remove(id: number): Promise<any> {
    const url = `${this.trabajadorUrl}/eliminar/${id}`;
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .get<any>(url, { headers: this.headers })
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  getTrabajadores(filterQuery: string, sortBy: string, sortOrder: string, rowsOnPage: number, page: number) {
    const url = `${this.trabajadorUrl}/list`;
    let params = new HttpParams();
    if (filterQuery) {
      params = params.append("filterQuery", filterQuery);
    }

    if (sortBy) {
      params = params.append("sortBy", sortBy);
    }

    if (sortOrder) {
      params = params.append("sortOrder", sortOrder);
    }
    if (rowsOnPage) {
      params = params.append("rowsOnPage", rowsOnPage + "");
    }
    if (page) {
      params = params.append("page", page + "");
    }

    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      let options = { headers: this.headers, params: params }; // Create a request option
      return this.http
        .get<any>(url, options)
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  autocomplete(filterQuery: string) {
    let params = new HttpParams();
    const url = `${this.trabajadorUrl}/autocomplete`;
    if (filterQuery) {
      params = params.append("filterQuery", filterQuery);
    }
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      let options = { headers: this.headers, params: params }; // Create a request option
      return this.http
        .get<any>(url, options)
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  autocompleteRFC(filterQuery: string) {
    let params = new HttpParams();
    const url = `${this.trabajadorUrl}/autocompleteRFC`;
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      let options = { headers: this.headers, params: params }; // Create a request option
      return this.http
        .get<any>(url, options)
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  autocompleteTipoContrato(filterQuery: string) {
    let params = new HttpParams();
    const url = `${this.trabajadorUrl}/autocompleteTipoContrato`;
    if (filterQuery) {
      params = params.append("filterQuery", filterQuery);
    }
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      let options = { headers: this.headers, params: params }; // Create a request option
      return this.http
        .get<any>(url, options)
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  autocompleteTipoJornada(filterQuery: string) {
    let params = new HttpParams();
    const url = `${this.trabajadorUrl}/autocompleteTipoJornada`;
    if (filterQuery) {
      params = params.append("filterQuery", filterQuery);
    }
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      let options = { headers: this.headers, params: params }; // Create a request option
      return this.http
        .get<any>(url, options)
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  obtenerJornadas() {
    let params = new HttpParams();
    const url = `${this.trabajadorUrl}/autocompleteTipoJornada`;
    params = params.append("filterQuery", "@@@");
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      let options = { headers: this.headers, params: params }; // Create a request option
      return this.http
        .get<any>(url, options)
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  autocompletePeriodicidadPago(filterQuery: string) {
    let params = new HttpParams();
    const url = `${this.trabajadorUrl}/autocompletePeriodicidadPago`;
    if (filterQuery) {
      params = params.append("filterQuery", filterQuery);
    }
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      let options = { headers: this.headers, params: params }; // Create a request option
      return this.http
        .get<any>(url, options)
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  getCSVInfoTrabajador() {
    let token = localStorage.getItem("token");
    if (token) {
      const url = `${this.trabajadorUrl}/trabajadorCSV/${token}`;
      window.location.href = url;
    }
  }
}
