<div class="row">
  <div class="col-12">
    <h1 class="titlletext"><em class="big flaticon-cliente icon-engrane"></em> Clientes</h1>
  </div>
  <div class="col-12">
    <div class="button">
      <button class="btn btn-colorblue btn-raised esp font" (click)="prepareAdd()" data-toggle="modal" data-target="#Clientes"><em class="fa fa-plus"></em> Agregar Clientes</button>
      <div class="dropdown righta">
        <button class="btn btn-option btn-raised font dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><em class="fa fa-cog"></em> Opciones</button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item" (click)="exportarExcel()">Exportar<br />Clientes</a>
        </div>
      </div>
    </div>
  </div>
</div>
<br />
<br />
<hr />
<div class="container-fluid">
  <div class="table-responsive">
    <table class="table table-striped table-vcenter table-bordered tableDatosGenerales">
      <thead class="">
        <tr>
          <td colspan="8">
            <div class="top_table row padSearch">
              <div class="col-xs-8 col-sm-8 col-md-4">
                <form [formGroup]="formularioFilterQuery" novalidate>
                  <div class="input-group">
                    <span class="input-group-addon">
                      <em class="fa fa-search"></em>
                    </span>
                    <input type="search" class="form-control" placeholder="Buscar en esta lista..." formControlName="queryCliente" />
                  </div>
                </form>
              </div>
              <div class="col-xs-4 col-sm-4 col-md-4 offset-md-4">
                <div class="float-right">
                  <div class="content-select">
                    <select name="usuarios_data_length" class="form-control" [(ngModel)]="rowsOnPage" (change)="onChangeSize()">
                      <option [value]="10">10</option>
                      <option [value]="20">20</option>
                      <option [value]="30">30</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="clearfix"></div>
          </td>
        </tr>
        <tr class="bor_inf">
          <th>
            <sorter-table by="id" [currentSort]="sortBy" (onSort)="onSort($event)">ID</sorter-table>
          </th>
          <th>
            <sorter-table by="nombre" [currentSort]="sortBy" (onSort)="onSort($event)">Nombre</sorter-table>
          </th>
          <th>
            <sorter-table by="tipoCliente" [currentSort]="sortBy" (onSort)="onSort($event)">Tipo de cliente </sorter-table>
          </th>
          <th>
            <sorter-table by="rfc" [currentSort]="sortBy" (onSort)="onSort($event)">RFC</sorter-table>
          </th>
          <th>
            <sorter-table by="correo" [currentSort]="sortBy" (onSort)="onSort($event)">Correo</sorter-table>
          </th>
          <th>
            <sorter-table by="calle" [currentSort]="sortBy" (onSort)="onSort($event)">Direccion </sorter-table>
          </th>
          <th>
            <sorter-table by="sucursal" [currentSort]="sortBy" (onSort)="onSort($event)">Sucursales </sorter-table>
          </th>

          <th style="min-width: 100px">Acciones</th>
        </tr>
      </thead>
      <tbody class="tableDatosGenerales">
        <tr class="bor" *ngFor="let item of data |paginate: { itemsPerPage: rowsOnPage, currentPage: page, totalItems: totalCliente }">
          <td scope="row">{{item.id}}</td>
          <td>{{item.nombre}}</td>
          <td *ngIf="item.tipoCliente == 1">Nacional</td>
          <td *ngIf="item.tipoCliente == 2">Extranjero</td>
          <td>{{item.rfc}}</td>
          <td>{{item.correo}}</td>
          <td>{{item.calle}}</td>
          <td>{{item.sucursal != null && item.sucursal != undefined ? item.sucursal.nombre : "" }}</td>
          <td class="text-center">
            <button type="button" (click)="prepareEdit(item)" class="btn btn-secondary btn-sm" data-toggle="modal" data-target="#Clientes">
              <em class="fa fa-pencil"></em>
            </button>
            <button type="button" (click)="onClick_elim(item)" class="btn btn-secondary btn-sm" data-toggle="modal" data-target="#eliminar">
              <em class="fa fa-trash"></em>
            </button>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="8">
            <nav aria-label="Page navigation example">
              <pagination-template #p="paginationApi" (pageChange)="pageChanged($event)">
                <ul class="pagination float-left padNum">
                  <li class="page-item">
                    <span> 1 - </span>
                    <span> {{rowsOnPage}} </span>
                    <span> de </span>
                    <span> {{totalCliente}} </span>
                  </li>
                </ul>
                <ul class="pagination float-right">
                  <li class="page-item" [class.active]="p.isFirstPage()">
                    <a class="page-link boton espa" *ngIf="!p.isFirstPage()" (click)="p.previous()" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                      <span class="sr-only">Previous</span>
                    </a>
                  </li>

                  <li class="page-item" *ngFor="let page of p.pages" [class.active]="p.getCurrent() === page.value">
                    <a class="page-link redonditos boton espa" (click)="p.setCurrent(page.value)"> {{ page.label }}</a>
                  </li>

                  <li class="page-item" [class.active]="p.isLastPage()">
                    <a class="page-link boton espa" *ngIf="!p.isLastPage()" (click)="p.next()" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                      <span class="sr-only">Next</span>
                    </a>
                  </li>
                </ul>
              </pagination-template>
            </nav>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
  <div class="clearfix"></div>
</div>

<div class="modal fade" id="Clientes" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title" id="exampleModalLabel">{{!isEdit?'Agregar Cliente':'Editar Cliente'}}</h1>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <form [formGroup]="formularioClientes" (ngSubmit)="onSubmitClientes()" novalidate>
        <div class="modal-body">
          <hr />
          <dl class="row">
            <dt class="col-sm-12">
              <div class="form-group">
                Nombre
                <span class="required-input">*</span>
                <input type="text" formControlName="nombre" class="form-control" id="nombre" required [ngClass]="{'alert-shadow-border': !formularioClientes.controls.nombre.value, 'success-shadow-border': formularioClientes.controls.nombre.value }"/>
                <div *ngIf="formularioClientes.controls.nombre.errors?.required && !formularioClientes.controls.nombre.pristine" class="alert alert-danger">Nombre es requerido</div>
              </div>
            </dt>

            <dt class="col-sm-12">
              <div class="form-group">
                Correo
                <span class="required-input">*</span>
                <input type="email" formControlName="correo" class="form-control" id="correo" required [ngClass]="{'alert-shadow-border': !formularioClientes.controls.correo.value, 'success-shadow-border': formularioClientes.controls.correo.value }"/>
                <div *ngIf="formularioClientes.controls.correo.errors?.required && !formularioClientes.controls.correo.pristine" class="alert alert-danger">Correo es requerido</div>
              </div>
            </dt>
            <dt class="col-sm-12">
              <div class="form-group">
                Tipo de cliente
                <span class="required-input">*</span>
                <div class="content-select">
                  <select formControlName="t_cliente" class="form-control" id="t_cliente" (change)="selectTipo($event)" required [ngClass]="{'alert-shadow-border': !formularioClientes.controls.t_cliente.value, 'success-shadow-border': formularioClientes.controls.t_cliente.value }">
                    <option [ngValue]="null">{{'general.selectOption'|translate}}</option>
                    <option [ngValue]="1">NACIONAL</option>
                    <option [ngValue]="2">EXTRANJERO</option>
                  </select>
                </div>
                <div *ngIf="formularioClientes.controls.t_cliente.errors?.required && !formularioClientes.controls.t_cliente.pristine" class="alert alert-danger">Tipo de cliente es requerido</div>
              </div>
            </dt>
            <dt class="col-sm-12" *ngIf="showIdExtranjero">
              <div class="form-group">
                ID extranjero
                <input type="text" formControlName="idExtranjero" class="form-control" id="idExtranjero" (focusout)="onKey($event)" onkeyup="this.value=this.value.toUpperCase();" />
              </div>
            </dt>
            <dt class="col-sm-12">
              <div class="form-group">
                RFC
                <span class="required-input">*</span>
                <input type="text" formControlName="rfc" class="form-control rfc" id="rfc" minlength="10" maxlength="13" required (focusout)="onKey($event)" onkeyup="this.value=this.value.toUpperCase();" [ngClass]="{'alert-shadow-border': !formularioClientes.controls.rfc.value, 'success-shadow-border': formularioClientes.controls.rfc.value }"/>
                <div *ngIf="formularioClientes.controls.rfc.errors?.required && !formularioClientes.controls.rfc.pristine" class="alert alert-danger">RFC es requerido</div>
              </div>
            </dt>
            <dt class="col-sm-12">
              <div class="form-group">
                Tipo Régimen
                <dl class="row">
                  <dt class="col-sm-9">
                    <autocomplete-generic minsearch="2" entidad="regimenes" required (onIdSelected)="onIdRegimenesSelected($event)" [enabled]="true" textplaceholder="Buscar regimen" #autocompleteRegimenes> </autocomplete-generic>
                  </dt>
                  <dt class="col-sm-3">
                    <button type="button" class="btn btn-colorblue" (click)="addRegimen()"><em class="fa fa-plus"></em>Agregar</button>
                  </dt>
                </dl>
              </div>
            </dt>
            <dt class="col-sm-12" *ngIf="regimenLisst != null && regimenLisst.length > 0">
              <div class="form-group">
                <dl class="row">
                  <div class="card-block">
                    <div class="table-responsive">
                      <table class="table table-bordered table-striped table-vcenter">
                        <tbody>
                          <tr *ngFor="let regimen of regimenLisst; let i=index">
                            <td style="display: flex; align-items: flex-end">
                              <label>{{regimen.tipoRegimen.nombre}}</label>
                            </td>
                            <td>
                              <label> Por defecto </label>
                              <input type="checkbox" id="regimenDefaultid_{{i}}" (change)="regimenDefault(i, $event)" [checked]="regimen.regimenDefault" />
                              <button type="button" (click)="eliminarRegimen(i)" class="btn btn-secondary btn-sm" data-toggle="modal" data-target="#eliminarRegimen"><em class="fa fa-trash"></em></button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </dl>
              </div>
            </dt>
            <dt class="col-sm-12">
              <div class="form-group">
                Sucursal
                <autocomplete-generic minsearch="1" entidad="sucursal" (onIdSelected)="onIdSucursalSelected($event)" [enabled]="true" textplaceholder="Buscar Sucursal" #autocompleteSucursalService required> </autocomplete-generic>
              </div>
            </dt>
            <dt class="col-sm-12">
              <div class="form-group">
                Calle
                <span class="required-input">*</span>
                <input type="text" formControlName="calle" class="form-control" id="calle" required [ngClass]="{'alert-shadow-border': !formularioClientes.controls.calle.value, 'success-shadow-border': formularioClientes.controls.calle.value }"/>
                <div *ngIf="formularioClientes.controls.calle.errors?.required && !formularioClientes.controls.calle.pristine" class="alert alert-danger">Calle es requerido</div>
              </div>
            </dt>
            <dt class="col-sm-6">
              <div class="form-group">
                Núm. exterior
                <span class="required-input">*</span>
                <input type="text" formControlName="n_exterior" class="form-control" id="n_exterior" required [ngClass]="{'alert-shadow-border': !formularioClientes.controls.n_exterior.value, 'success-shadow-border': formularioClientes.controls.n_exterior.value }"/>
                <div *ngIf="formularioClientes.controls.n_exterior.errors?.required && !formularioClientes.controls.n_exterior.pristine" class="alert alert-danger">Número Exterior es requerido</div>
              </div>
            </dt>
            <dt class="col-sm-6">
              <div class="form-group">
                Núm. interiror

                <input type="text" formControlName="n_interior" class="form-control" id="n_interiror" />
              </div>
            </dt>

            <dt class="col-sm-6">
              <div class="form-group">
                Código Postal
                <span class="required-input">*</span>
                <autocomplete-generic minsearch="3" entidad="codigopostal" (onIdSelected)="onCodigoPostalSelected($event)" [enabled]="true" textplaceholder="Buscar código postal" #autocompleteCodigoPostal required [ngClass]="{'alert-shadow-border-completer': !formularioClientes.controls.cp.value, 'success-shadow-border-completer': formularioClientes.controls.cp.value }"> </autocomplete-generic>
              </div>
            </dt>
            <dt class="col-sm-6">
              <div class="form-group">
                Colonia
                <span class="required-input">*</span>
                <div class="content-select">
                  <select formControlName="colonia" class="form-control" id="colonia" required [ngClass]="{'alert-shadow-border': !formularioClientes.controls.colonia.value, 'success-shadow-border': formularioClientes.controls.colonia.value }">
                    <option [ngValue]="null">{{'general.selectOption'|translate}}</option>
                    <option *ngFor="let colonia of colonias" [ngValue]="colonia">{{colonia}}</option>
                  </select>
                </div>
                <div *ngIf="formularioClientes.controls.colonia.errors?.required && !formularioClientes.controls.colonia.pristine" class="alert alert-danger">Colonia es requerido</div>
              </div>
            </dt>
            <dt class="col-sm-6">
              <div class="form-group">
                Localidad
                <input type="text" formControlName="localidad" class="form-control" id="localidad" />
              </div>
            </dt>
            <dt class="col-sm-6">
              <div class="form-group">
                Delegación o municipio
                <span class="required-input">*</span>
                <input type="text" formControlName="municipio" class="form-control" id="municipio" disabled required [ngClass]="{'alert-shadow-border': !formularioClientes.controls.municipio.value, 'success-shadow-border': formularioClientes.controls.municipio.value }"/>
                <div *ngIf="formularioClientes.controls.municipio.errors?.required && !formularioClientes.controls.municipio.pristine" class="alert alert-danger">Delegación o municipio es requerido</div>
              </div>
            </dt>
            <dt class="col-sm-6">
              <div class="form-group">
                Estado
                <span class="required-input">*</span>
                <input type="text" formControlName="estado" class="form-control" id="estado" disabled required [ngClass]="{'alert-shadow-border': !formularioClientes.controls.estado.value, 'success-shadow-border': formularioClientes.controls.estado.value }"/>
                <div *ngIf="formularioClientes.controls.estado.errors?.required && !formularioClientes.controls.estado.pristine" class="alert alert-danger">Estado es requerido</div>
              </div>
            </dt>
            <dt class="col-sm-6">
              <div class="form-group">
                País
                <span class="required-input">*</span>
                <input type="text" formControlName="pais" class="form-control" id="pais" disabled required [ngClass]="{'alert-shadow-border': !formularioClientes.controls.pais.value, 'success-shadow-border': formularioClientes.controls.pais.value }"/>
                <div *ngIf="formularioClientes.controls.pais.errors?.required && !formularioClientes.controls.pais.pristine" class="alert alert-danger">País es requerido</div>
              </div>
            </dt>
            <dt class="col-sm-12">
              <div class="form-group">
                Comentarios
                <input type="text" formControlName="comentarios" class="form-control" id="comnentarios" />
              </div>
            </dt>
          </dl>
          <hr />
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-option" data-dismiss="modal">Cancelar</button>
          <button type="submit" class="btn btn-colorblue" [attr.disabled]="!formularioClientes.valid ? true : null">Guardar</button>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="modal fade" id="eliminar" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title" id="exampleModalLabel">Borrar cliente</h1>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">¿Estás seguro de querer borrar el cliente {{entidad_elimar?.nombre}}?</div>
      <div class="modal-footer">
        <button type="button" class="btn btn-option" data-dismiss="modal">Cancelar</button>
        <button type="button" (click)="remove_cliente(entidad_elimar?.id)" class="btn btn-colorblue">Borrar</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="clientError" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title mx-r" style="position: center" id="tituloAlerta">Error</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body mx-auto">
        <p style="height: 0px">error</p>
      </div>
      <div class="modal-footer">
        <button class="btn btn-secondary mx-auto" id="buttonAlert" data-dismiss="modal"></button>
      </div>
    </div>
  </div>
</div>
