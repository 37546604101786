import { Component, ViewContainerRef } from "@angular/core";
import { GlobalState } from "./global.state";
import { BaImageLoaderService, BaThemePreloader, BaThemeSpinner } from "./theme/services";
import { BaThemeConfig } from "./theme/theme.config";

/*
 * App Component
 * Top Level Component
 */
@Component({
  selector: "app",
  styleUrls: ["./app.component.scss"],
  template: `
    <main [class.menu-collapsed]="isMenuCollapsed" baThemeRun>
      <div class="additional-bg"></div>
      <router-outlet></router-outlet>
    </main>
  `
})
export class App {
  isMenuCollapsed: boolean = false;

  constructor(private _state: GlobalState, private _imageLoader: BaImageLoaderService, private _spinner: BaThemeSpinner, private viewContainerRef: ViewContainerRef, private themeConfig: BaThemeConfig) {
    themeConfig.config();
    this._loadImages();
    this._state.subscribe("menu.isCollapsed", (isCollapsed) => {
      setTimeout(() => {
        this.isMenuCollapsed = isCollapsed;
      });
    });
  }

  public ngAfterViewInit(): void {
    BaThemePreloader.load().then((values) => {
      this._spinner.hide();
    });
  }

  private _loadImages(): void {
    BaThemePreloader.registerLoader(this._imageLoader.load("assets/img/sky-bg.jpg"));
  }
}
