import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Certificado } from "app/entidades/certificado";
import { CertificadoService } from "app/servicios-backend/certificado.service";
import { Empresa } from "app/entidades/empresa";
import { UntypedFormGroup, AbstractControl, UntypedFormBuilder, Validators } from "@angular/forms";
import { EmpresaService } from "app/servicios-backend/empresa.service";
import { RegimenFiscalService } from "app/servicios-backend/regimen-fiscal.service";
import { Subject } from "rxjs/Subject";
import { DropzoneService } from "app/servicios-backend/dropzone.service";
import { SharedService } from "app/shared.service";

@Component({
  selector: "app-registra-csd",
  templateUrl: "./registra-csd.component.html",
  styleUrls: ["./registra-csd.component.scss"],
})
export class RegistraCSDComponent implements OnInit {
  certificado: Certificado = new Certificado();
  public valor: number;
  public archivoCer: AbstractControl;
  public numeroCertificado: AbstractControl;
  public validoDesde: AbstractControl;
  public validoHasta: AbstractControl;
  public archivoLlave: AbstractControl;
  public password: AbstractControl;
  public formCertificado: UntypedFormGroup;
  public submitted: boolean = false;
  public error: boolean;
  empresa: Empresa = new Empresa();
  rowsOnPage: number = 10;
  sortBy: string;
  sortOrder: string = "asc";
  page: number = 1;
  totalCertificados: number = 0;
  totalRegimenes: number = 0;
  query: string = "";
  public eventosDropzone: Subject<{ id: string; evento: string; valor: string }> = new Subject();
  public tArchivo: string = ".cer";
  public arAcept: string = ".cer";
  public tArchivoa: string = ".key";
  public arAcepta: string = ".key";
  public tipo = "/upload";
  public proyecto = "3";
  public modulo = "1";
  public submodulo = "csd"; // carpeta
  public idDZa = "#dZUploadCSDCER"; // Archivo con extension .cer
  public idDZb = "#dZUploadCSDKEY"; // Archivo con extension .key
  public nombreCert: string;
  public nombreKey: string;
  public cond: boolean = false; // Verdadero para renombrar, Falso para no renombrar
  public certCorrect: Certificado;
  public idCert: number;
  public endLoad: any;
  readyUpload: boolean = false;
  @Input() progress = "";
  @Input() progressVal = 0;
  @Input() step: number = 0;
  @Output() updateProgrees = new EventEmitter<{ dataEmp: any; totCer: number; totReg: number }>();
  public nameButton = "";
  constructor(private certificadoService: CertificadoService, fb: UntypedFormBuilder, private empresaService: EmpresaService, private regimenFiscalService: RegimenFiscalService, private dropzoneService: DropzoneService, private sharedService: SharedService) {
    this.formCertificado = fb.group({
      archivoCer: ["", Validators.required],
      archivoLlave: ["", Validators.required],
      password: ["", Validators.required],
    });
    this.archivoCer = this.formCertificado.controls["archivoCer"];
    this.archivoLlave = this.formCertificado.controls["archivoLlave"];
    this.password = this.formCertificado.controls["password"];
  }

  ngOnInit() {
    this.certCorrect = new Certificado();
    this.dropzone();
    this.eventosDropzone.subscribe((data: { id: string; evento: string; valor: string }) => {
      console.log(data.evento);
      if (data.evento != undefined) {
        switch (data.evento) {
          case "rename":
            break;
          case "success":
            break;
          case "sending":
            let extFile: string[] = data.valor.split(".");

            if (extFile[extFile.length - 1] == "cer") {
              this.certCorrect.archivoCertificado = this.nombreCert;
              this.certCorrect.archivoLlave = "cer.key";
              this.certCorrect.empresa = new Empresa();

              this.certCorrect.validoDesde = 1408774713046;
              this.certCorrect.validoHasta = 1508774713046;
              this.certCorrect.password = "123456";
              //ESTA FUNCIÓN ES LA QUE DUPLICA EL DOBLE DE REGISTRO...
              this.dropzoneService.getDropZone(this.idDZb).processQueue();
            }
            if (extFile[extFile.length - 1] == "key") {
              this.certCorrect.archivoCertificado = this.nombreCert;
              this.certCorrect.archivoLlave = "cer.key";
              this.certCorrect.empresa = new Empresa();

              this.certCorrect.validoDesde = 1408774713046;
              this.certCorrect.validoHasta = 1508774713046;
              this.certCorrect.password = "123456";
              //ESTA FUNCIÓN ES LA QUE DUPLICA EL DOBLE DE REGISTRO...
              let cert = new Certificado();
              cert.empresa = new Empresa();
              cert.archivoCertificado = this.nombreCert;
              cert.archivoLlave = this.nombreKey;

              let password = this.formCertificado.get("password").value;
              let truePassword = password.replace(/(^\s+|\s+$)/g, "");
              cert.password = truePassword;
              console.log(cert);
              this.certificadoService
                .create(cert)
                .then(() => {
                  this.readyUpload = true;
                  console.log("Certificado agregado correctamente", cert);
                })
                .catch((error) => {
                  console.log("Certificado agregado incorrectamente", error);
                });
            }

            break;
          case "removedfile":
            break;
          case "addedfile":
            if (data.id == this.idDZa) {
              this.nombreCert = data.valor;
            }
            if (data.id == this.idDZb) {
              this.nombreKey = data.valor;
            }
            this.certCorrect.archivoCertificado = this.nombreCert;
            this.certCorrect.archivoLlave = "cer.key";
            this.certCorrect.empresa = new Empresa();

            this.certCorrect.validoDesde = 1408774713046;
            this.certCorrect.validoHasta = 1508774713046;
            this.certCorrect.password = "123456";
            break;
          case "error":
            break;
          default:
            console.log("default: dropzone");
            break;
        }
      }
    });
  }
  // Modulo de archivos
  //   0 - Certificado_sello
  dropzone() {
    this.empresaService
      .getDetalle()
      .then((data) => {
        if (data != undefined && data.id != undefined) {
          let id = data.id;
          this.dropzoneService.dropzone(this.idDZa, this.tArchivo, this.arAcept, this.tipo, 0, id, this.eventosDropzone, false, false);
          this.dropzoneService.dropzone(this.idDZb, this.tArchivoa, this.arAcepta, this.tipo, 0, id, this.eventosDropzone, false, false);
        }
      })
      .catch();
  }

  btnNext() {
    this.empresaService
      .getDetalle()
      .then((dataEmp: any) => {
        this.certificadoService
          .getCertidicados(this.query, this.sortBy, this.sortOrder, this.rowsOnPage, this.page)
          .then((dataCert) => {
            this.totalCertificados = dataCert.total;
            this.regimenFiscalService
              .getRegimenes(this.query, this.sortBy, this.sortOrder, this.rowsOnPage, this.page)
              .then((dataReg) => {
                this.totalRegimenes = dataReg.total;
                this.empresa = dataEmp;
                this.updateProgrees.emit({ dataEmp: this.empresa, totCer: this.totalCertificados, totReg: this.totalRegimenes });
                let condicionRF = this.totalRegimenes == 0;
                if (condicionRF) {
                  $("#csdModal").modal("hide");
                  $("#rfModal").modal({ backdrop: "static", keyboard: false }, "show");
                } else {
                  $("#csdModal").modal("hide");
                  this.sharedService.callParentMethod("reloadEmpresa");
                  //window.location.reload();
                }
              })
              .catch(() => {});
          })
          .catch((error) => {
            console.log("Hubo un error", error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  btnPrev() {
    let cfdiCondition: boolean = false;
    this.empresaService
      .getDetalle()
      .then((dataEmp: any) => {
        this.certificadoService
          .getCertidicados(this.query, this.sortBy, this.sortOrder, this.rowsOnPage, this.page)
          .then((dataCert) => {
            this.totalCertificados = dataCert.total;
            this.regimenFiscalService
              .getRegimenes(this.query, this.sortBy, this.sortOrder, this.rowsOnPage, this.page)
              .then((dataReg) => {
                this.totalRegimenes = dataReg.total;
                this.empresa = dataEmp;
                let dRFC = dataEmp.rfc == undefined || dataEmp.rfc == null || dataEmp.rfc == 0 || dataEmp.rfc == " ";
                let dRePa = dataEmp.registroPatronal == undefined || dataEmp.registroPatronal == null || dataEmp.registroPatronal == 0 || dataEmp.registroPatronal == " ";
                let dRiPu = dataEmp.riesgoPuesto == undefined || dataEmp.riesgoPuesto == null || dataEmp.riesgoPuesto.id == undefined || dataEmp.riesgoPuesto.id == null || dataEmp.riesgoPuesto.id == 0 || dataEmp.riesgoPuesto.id == " ";
                let dLuEx = dataEmp.lugarExpedicion == undefined || dataEmp.lugarExpedicion == null || dataEmp.lugarExpedicion == 0 || dataEmp.lugarExpedicion == " ";
                let dLogo = dataEmp.logo == undefined || dataEmp.logo == null || dataEmp.logo == 0 || dataEmp.logo == " ";
                let dCeId = dataEmp.cedulaIdentificacionFiscal == undefined || dataEmp.cedulaIdentificacionFiscal == null || dataEmp.cedulaIdentificacionFiscal == 0 || dataEmp.cedulaIdentificacionFiscal == " ";
                let condicionDFE = dRFC && dRePa && dRiPu && dLuEx;
                let condicionCFDI = dLogo && dCeId;
                let condicionRF = this.totalRegimenes == 0;
                if (dataEmp.logo != undefined && dataEmp.cedulaIdentificacionFiscal != undefined) {
                  cfdiCondition = true;
                }
                if (cfdiCondition) {
                  $("#csdModal").modal("hide");
                  $("#cfdiModal").modal({ backdrop: "static", keyboard: false }, "show");
                } else if ((condicionCFDI && condicionDFE) || condicionRF) {
                  $("#csdModal").modal("hide");
                  $("#dfeModal").modal({ backdrop: "static", keyboard: false }, "show");
                } else {
                  $("#csdModal").modal("hide");
                }
              })
              .catch((error) => {
                console.log("Hubo un error", error);
              });
          })
          .catch((error) => {
            console.log("Hubo un error", error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  btnOM() {
    $("#csdModal").modal("hide");
  }
  onSubmitAgregar() {
    this.submitted = true;
    let password = this.formCertificado.get("password").value;
    if (this.nombreCert && this.nombreKey && password) {
      this.dropzoneService.getDropZone(this.idDZa).processQueue();
    } else {
      this.error = true;
    }
  }
  next() {
    this.btnNext();
  }
}
