import { HttpHeaders } from "@angular/common/http";
export class HttpHelper {
  /**
   * Comentar o descomentar segun el servidor a apuntar

  /**localhost local */
  static url = HttpHelper.getUrl();
  static urlFILEREST = HttpHelper.getUrlFILEREST(); // Url servicio archivos produccion

  /**produccion */
  //static url: string = "https://api.empro.mx/empro-service/empro/";
  //static urlFILEREST: string = "https://api.empro.mx/empro-service/empro/files";
  //"https://api.empro.mx/file-service";
  /* FILEService */

  static headersJSON: HttpHeaders = new HttpHeaders().set("Content-Type", "application/json; charset=UTF-8").set("Access-Control-Allow-Origin", "*");

  static isLocalhost(): boolean {
    return window.location.hostname === "localhost";
  }

  static getUrl(): string {
    if (HttpHelper.isLocalhost()) {
      return "http://localhost:8080/empro-service/empro/";
    } else {
      return "https://api.empro.mx/empro-service/empro/";
    }
  }

  static getUrlFILEREST(): string {
    if (HttpHelper.isLocalhost()) {
      return "http://localhost:8080/empro-service/empro/files";
    } else {
      return "https://api.empro.mx/empro-service/empro/files";
    }
  }
}
