import { Injectable } from "@angular/core";
import { HttpParams, HttpClient } from "@angular/common/http";
import { Usuario, Recuperacion } from "../entidades/usuario";
import { HttpHelper } from "../http-helper";
import "rxjs/add/operator/toPromise";
import { Empresa } from "app/entidades/empresa";

@Injectable()
export class UsuarioService {
  private headers = HttpHelper.headersJSON;
  private usuarioUrl = HttpHelper.url + "usuario";

  private permisosUrl = {
    bancos: HttpHelper.url + "empresa/permisoBancos",
    nomina: HttpHelper.url + "empresa/permisoNominas",
    dashboard: HttpHelper.url + "empresa/permisoDashboard",
    reportes: HttpHelper.url + "empresa/permisoReportes",
    listaIngresos: HttpHelper.url + "empresa/permisoIngresos",
    listaEgresos: HttpHelper.url + "empresa/permisoEgresos",
    comprobantesFiscales: HttpHelper.url + "empresa/permisoCFDI",
    miempresa: HttpHelper.url + "empresa/permisoEmpresa",
    parqueVehicular: HttpHelper.url + "empresa/permisoParqueVehicular",
    usuarios: HttpHelper.url + "empresa/permisoUsuarios",
  };

  constructor(private http: HttpClient) {}

  getLogin(usuario: Usuario): Promise<Usuario> {
    const url = `${this.usuarioUrl}/login`;
    return this.http
      .post<any>(url, JSON.stringify(usuario), { headers: this.headers })
      .toPromise()
      .then((response) => response as Promise<Usuario>)
      .catch(this.handleError);
  }

  empresasByUser(id: number): Promise<Usuario> {
    const url = `${this.usuarioUrl}/empresasByUser/${id}`;
    return this.http
      .get<any>(url, { headers: this.headers })
      .toPromise()
      .then((response) => response as Promise<Empresa>)
      .catch(this.handleError);
  }

  recoverPassword(data: Recuperacion): Promise<Recuperacion> {
    const url = `${HttpHelper.url}mail/correoRecuperacion`;
    return this.http
      .post<any>(url, JSON.stringify(data), { headers: this.headers })
      .toPromise()
      .then((response) => response as Promise<Recuperacion>)
      .catch(this.handleError);
  }

  confirmar(mtoken: string): Promise<any> {
    const url = `${this.usuarioUrl}/confirmar`;
    const token = mtoken;
    this.headers = this.headers.set("token", token);
    return this.http
      .get<any>(url, { headers: this.headers })
      .toPromise()
      .then((response) => response as Promise<any>)
      .catch(this.handleError);
  }

  handleError(error: any): Promise<any> {
    let resp = error;
    if (resp.error) {
      console.error("usuarioService - ERROR:s ", resp.error);
      return Promise.reject(resp.error);
    }
    return Promise.reject(error.message || error);
  }

  authenticated(): Promise<boolean> {
    const url = `${this.usuarioUrl}/valida`;
    const token = localStorage.getItem("token");
    if (!token) {
      return Promise.resolve<boolean>(false);
    } else {
      this.headers = this.headers.set("token", token);
      return this.http
        .post<any>(url, {}, { headers: this.headers })
        .toPromise()
        .then((response) => {
          if (response.ok) {
            if (response.token) {
              localStorage.setItem("token", response.token);
            }
            return Promise.resolve<boolean>(true);
          } else {
            return Promise.resolve<boolean>(false);
          }
        })
        .catch(this.handleError);
    }
  }

  roleAuth(view: string): Promise<boolean> {
    const url = this.permisosUrl[view];
    const token = localStorage.getItem("token");
    if (!token) {
      return Promise.resolve<boolean>(false);
    } else {
      this.headers = this.headers.set("token", token);
      return this.http
        .get<any>(url, { headers: this.headers })
        .toPromise()
        .then((response) => {
          if (response.ok) {
            return Promise.resolve<boolean>(true);
          } else {
            return Promise.resolve<boolean>(false);
          }
        })
        .catch((error) => {
          return Promise.resolve<boolean>(false);
        });
    }
  }

  logout(): Promise<boolean> {
    const url = `${this.usuarioUrl}/logout`;
    const token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .post<any>(url, {}, { headers: this.headers })
        .toPromise()
        .then((response) => {
          localStorage.clear();
          return Promise.resolve<boolean>(true);
        })
        .catch(this.handleError);
    }
    return Promise.resolve<boolean>(false);
  }

  getDetalle(id: number): Promise<Usuario> {
    const url = `${this.usuarioUrl}/detalle/${id}`;
    const token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .get<any>(url, { headers: this.headers })
        .toPromise()
        .then((response) => response as Promise<Usuario>)
        .catch(this.handleError);
    }
  }

  update(dataUsuario: Usuario): Promise<any> {
    const url = `${this.usuarioUrl}/update`;
    const token = localStorage.getItem("token");
    this.headers = this.headers.set("token", token);
    return this.http
      .post<any>(url, JSON.stringify(dataUsuario), { headers: this.headers })
      .toPromise()
      .then((res) => res as Promise<any>)
      .catch(this.handleError);
  }

  changepass(dataUsuario: Usuario, mtoken: string): Promise<any> {
    const url = `${this.usuarioUrl}/changepass`;
    const token = mtoken;
    this.headers = this.headers.set("token", token);
    return this.http
      .post<any>(url, JSON.stringify(dataUsuario), { headers: this.headers })
      .toPromise()
      .then((res) => res as Promise<any>)
      .catch(this.handleError);
  }

  create(dataUsuario: Usuario): Promise<any> {
    const url = `${this.usuarioUrl}/crear`;
    let token = localStorage.getItem("token");
    this.headers = this.headers.set("token", token);
    return this.http
      .post<any>(url, JSON.stringify(dataUsuario), { headers: this.headers })
      .toPromise()
      .then((res) => res as Promise<any>)
      .catch(this.handleError);
    // }
  }

  remove(id: number): Promise<any> {
    const url = `${this.usuarioUrl}/eliminar/${id}`;
    const token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .get<any>(url, { headers: this.headers })
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  getUsuarios(filterQuery: string, sortBy: string, sortOrder: string, rowsOnPage: number, page: number) {
    const url = `${this.usuarioUrl}/list`;
    let params = new HttpParams();
    if (filterQuery) params = params.append("filterQuery", filterQuery);

    if (sortBy) params = params.append("sortBy", sortBy);

    if (sortOrder) {
      params = params.append("sortOrder", sortOrder);
    }
    if (rowsOnPage) {
      params = params.append("rowsOnPage", rowsOnPage + "");
    }
    if (page) {
      params = params.append("page", page + "");
    }
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      let options = { headers: this.headers, params: params }; // Create a request option
      return this.http
        .get(url, options)
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }else {
      console.log("No token found");
    }
  }

  autocomplete(filterQuery: string): Promise<any> {
    console.log(filterQuery);
    const url = `${this.usuarioUrl}/autocomplete/${filterQuery}`;
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .get<any>(url, { headers: this.headers })
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  registrate(dataUsuario: Usuario): Promise<any> {
    const url = `${this.usuarioUrl}/registrate`;
    return this.http
      .post<any>(url, JSON.stringify(dataUsuario), { headers: this.headers })
      .toPromise()
      .then((response) => response as Promise<any>)
      .catch(this.handleError);
  }

  getDetalleUsuarioSesion(): Promise<Usuario> {
    const url = `${this.usuarioUrl}/detalleUsuarioSesion`;
    const token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .get<any>(url, { headers: this.headers })
        .toPromise()
        .then((response) => response as Promise<Usuario>)
        .catch(this.handleError);
    }
  }

  getCSVInfo() {
    let token = localStorage.getItem("token");
    if (token) {
      const url = `${this.usuarioUrl}/usuarioCSV/${token}`;
      window.location.href = url;
    }
  }

  urlImageServer() {
    let token = localStorage.getItem("token");
    const urlImage = `${HttpHelper.url}files/download/3/imgProfile.jpg/${token}`;
    if (token) {
      return urlImage;
    }
  }
}
