<div class="modal fade" id="dfeModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" style="overflow-y: auto">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close color" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="progress">
            <div class="progress-bar progress-bar-danger progress-bar-striped active" role="progressbar" [attr.aria-valuenow]="progressVal" aria-valuemin="0" aria-valuemax="100" [style.width.%]="progressVal">
              {{ progress }}
            </div>
          </div>
          <br />
          <h1>
            <span class="color">{{ step }}. Datos fiscales de tu empresa</span>
          </h1>
          <br />
          <h4>
            Es importante completar la información de tu empresa para utilizar EMPRO al 100%, poder facturar, registrar egresos con CFDI´s y timbrar nómina.
            <br />
            <a>
              <u>Me gustaría recibir asesoría para llenar estos datos.</u>
            </a>
          </h4>
          <br />
          <form [formGroup]="formDFE" (ngSubmit)="onSubmitDFE()" class="align-middle" novalidate>
            <hr />
            <div>
              <dl class="row">
                <dt class="col-sm-12">
                  <div class="form-group">
                    RFC de la empresa
                    <div style="display: inline">
                      <a class="" tooltip-location="right" tooltip-animate tooltip="El RFC debe tener el siguiente formato: VECJ880326XXX donde XXX representan la Homoclave">
                        <em class="fa fa-question-circle fa-lg text-primary"></em>
                      </a>
                    </div>
                    <input formControlName="rfc" type="text" class="form-control rfc" id="resultRFC" minlength="10" maxlength="13" required (focusout)="onKey($event)" onkeyup="this.value=this.value.toUpperCase();" placeholder="VECJ880326XXX" disabled />
                  </div>
                </dt>
                <dt class="col-sm-12">
                  <div class="form-group">
                    Registro Patronal
                    <div style="display: inline">
                      <a class="" tooltip-location="right" tooltip-animate tooltip="Clave alfanumérica asignada por el IMSS a todo patrón persona moral o retenedor de cuotas obrero patronales y debe tener el siguiente formato: A10 10101 10 1">
                        <em class="fa fa-question-circle fa-lg text-primary"></em>
                      </a>
                    </div>
                    <input formControlName="registroPatronal" type="text" class="form-control" maxlength="20" placeholder="A10 10101 10 1" (keyup)="activeRiesgo($event)" onkeyup="this.value=this.value.toUpperCase();" />
                  </div>
                </dt>
                <dt class="col-sm-12">
                  <div class="form-group">
                    Reconocimiento de Validez Oficial de Estudios (RVOE)
                    <input type="text" formControlName="rvoeEmpresa" class="form-control" id="rvoeEmpresa" />
                  </div>
                </dt>
                <dt class="col-sm-12">
                  <div class="form-group concepto-cell concepto-cell-row" style="justify-content: flex-start">
                    <input type="checkbox" class="form-control check" id="isDonatariasCheck" formControlName="isDonatariasCheck" (change)="checkDonatarias($event)" [ngModel]="isDonatarias"/>
                    <label for="isDonatariasCheck">Es una donataria autorizada </label>
                  </div>
                </dt>
                <dt *ngIf="isDonatarias" class="col-sm-6">
                  <div class="form-group">
                    Fecha de autorización
                    <input type="date" class="form-control" formControlName="fechaAutorizacion" id="fechaAutorizacion" [ngModel]="fechaAut" required />
                  </div>
                </dt>
                <dt *ngIf="isDonatarias" class="col-sm-6">
                  <div class="form-group">
                    No de autorización
                    <input type="text" formControlName="noAutorizacion" class="form-control" id="noAutorizacion" />
                  </div>
                </dt>
                <dt *ngIf="isDonatarias" class="col-sm-6">
                  <div class="form-group">
                    Leyenda de Donatarias
                    <textarea type="text" formControlName="leyendaDon" class="form-control" id="leyendaDon"></textarea>
                  </div>
                </dt>
                <dt class="col-sm-12">
                  <div class="form-group">
                    Objeto de impuesto
                    <span class="required-input">*</span>
                    <div class="content-select" style="margin-bottom: 1em">
                      <select formControlName="objImpuesto" class="form-control" id="objImpuesto" [ngClass]="{ 'alert-shadow-border': !formDFE.controls.objImpuesto.value, 'success-shadow-border': formDFE.controls.objImpuesto.value }">
                        <option [ngValue]="0" selected disabled>{{ "general.selectOption" | translate }}</option>
                        <option [ngValue]="2">Sí objeto del impuesto</option>
                        <option [ngValue]="1">No objeto de impuesto</option>
                        <option [ngValue]="3">Sí objeto del impuesto y no obligado al desglose</option>
                        <option [ngValue]="4">Si objeto del impuesto y no causa impuesto</option>
                      </select>
                      <div *ngIf="formDFE.controls.objImpuesto.errors?.required && !formDFE.controls.objImpuesto.pristine" class="alert alert-danger">Objeto de impuesto es requerida</div>
                    </div>
                  </div>
                </dt>
                <div class="form-group concepto-cell concepto-cell-row" style="justify-content: flex-start">
                  <input type="checkbox" class="form-control check" id="isTransportCheck" formControlName="isTransportCheck" (change)="checkTransporte($event)" />
                  <label for="isTransportCheck">¿Cuentas con o contratas equipo de transporte para mover tus mercancías? </label>
                </div>
                <dt *ngIf="isactiveriesgo" class="col-sm-6">
                  <div class="form-group">
                    Riesgo Puesto
                    <div style="display: inline">
                      <a class="" tooltip-location="right" tooltip-animate tooltip="Clasificación establecida por el IMSS para cubrir el seguro de riesgos de trabajo según la actividad de la empresa">
                        <em class="fa fa-question-circle fa-lg text-primary"></em>
                      </a>
                    </div>
                    <div class="content-select">
                      <select class="form-control" formControlName="riesgoPuesto" placeholder="Buscar riesgo" disabled>
                        <option value="0">Seleccione una opcion</option>
                        <option value="1">Clase I</option>
                        <option value="2">Clase II</option>
                        <option value="3">Clase III</option>
                        <option value="4">Clase IV</option>
                        <option value="5">Clase V</option>
                      </select>
                    </div>
                  </div>
                </dt>
                <dt class="col-sm-12">
                  <div class="form-group">
                    <hr class="punteado" />
                  </div>
                </dt>
                <dt class="col-sm-12">
                  <div class="form-group">
                    <span class="text-primary">Dirección fiscal de la empresa</span>
                    <div style="display: inline">
                      <a class="" tooltip-location="right" tooltip-animate tooltip="Es el domicilio del contribuyente o con el que se registro la empresa ante el SAT">
                        <em class="fa fa-question-circle fa-lg text-primary"></em>
                      </a>
                    </div>
                  </div>
                </dt>
                <dt class="col-sm-6">
                  <div class="form-group">
                    Calle
                    <input formControlName="calle" type="text" size="61" class="form-control" maxlength="100" />
                  </div>
                </dt>
                <dt class="col-sm-3">
                  <div class="form-group">
                    Núm. Exterior
                    <input formControlName="numExterior" type="text" size="61" class="form-control" maxlength="10" />
                  </div>
                </dt>
                <dt class="col-sm-3">
                  <div class="form-group">
                    Núm. Interior
                    <input formControlName="numInterior" type="text" size="61" class="form-control" maxlength="10" />
                  </div>
                </dt>
                <dt class="col-sm-4">
                  <div class="form-group">
                    Código Postal
                    <span class="required-input">*</span>
                    <autocomplete-generic minsearch="3" entidad="codigopostal" (onIdSelected)="onCodigoPostalSelected($event)" [enabled]="true" textplaceholder="Buscar código postal" #autocompleteCodigoPostal required></autocomplete-generic>
                  </div>
                </dt>
                <dt class="col-sm-4">
                  <div class="form-group">
                    Colonia
                    <span class="required-input">*</span>
                    <div class="content-select">
                      <select formControlName="colonia" class="form-control" id="colonia" [(ngModel)]="coloniaEmpresa" [ngClass]="{ 'alert-shadow-border': !coloniaEmpresa, 'success-shadow-border': coloniaEmpresa }">
                        <option [ngValue]="null">{{ "general.selectOption" | translate }}</option>
                        <option *ngFor="let colonia of colonias" [ngValue]="colonia">{{ colonia }}</option>
                      </select>
                    </div>
                  </div>
                </dt>
                <dt class="col-sm-4">
                  <div class="form-group">
                    Delegación o municipio
                    <input type="text" formControlName="municipio" class="form-control disabled-input" id="municipio" disabled [readonly]="cliente != null" />
                  </div>
                </dt>
                <dt class="col-sm-6">
                  <div class="form-group">
                    Estado
                    <input type="text" formControlName="estado" class="form-control" id="estado" disabled [readonly]="cliente != null" />
                  </div>
                </dt>
                <dt class="col-sm-6">
                  <div class="form-group">
                    País
                    <input type="text" formControlName="pais" class="form-control" id="pais" disabled [readonly]="cliente != null"/>
                  </div>
                </dt>
                <dt class="col-sm-12">
                  <div class="form-group">
                    <hr class="punteado" />
                    <br />
                  </div>
                </dt>
                <dt class="col-sm-12">
                  <div class="form-group">
                    ¿Tienes varias sucursales?
                    <span class="required-input">*</span>
                    <input type="radio" value="" (click)="checkSucursal()" name="optradio" />Si <input type="radio" value="" (click)="checkSucursal()" name="optradio"  />No
                  </div>
                </dt>
              </dl>
            </div>
            <div class="align-middle">
              <button type="button" class="btn btn-warning" disabled>Regresar</button>
              <button type="submit" class="btn btn-primary">{{ step < 4 ? "Continuar" : "Finalizar" }}</button>
            </div>
            <br />
            <div class="align-middle">
              <button type="button" class="btn btn-cancelar" (click)="btnOM()">Hacerlo en otro momento</button>
            </div>
          </form>
          <br />
          <br />
          <br />
          <div class="alert alert-warning" *ngIf="error == true">
            <label for=""
              >{{ mensajeError }}
              <span *ngIf="showRed == si" class="required-input">*</span>
            </label>
          </div>
        </div>
      </div>
      <div class="modal-footer"></div>
    </div>
  </div>
</div>
