import { Routes, RouterModule } from "@angular/router";
import { PagesComponent } from "./pages.component";
import { ModuleWithProviders } from "@angular/core";
import { RoleGuard } from "../auth/role-guard";
import { NgaModule } from "app/theme/nga.module";

export const routes: Routes = [
  {
    path: "login",
    loadChildren: () => import("app/pages/login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "register",
    loadChildren: () => import("app/pages/register/register.module").then((m) => m.RegisterModule),
  },
  {
    path: "confirm-mail",
    loadChildren: () => import("app/pages/confirm-mail/confirm-mail.module").then((m) => m.ConfirmMailModule),
  },
  {
    path: "recoverypassword",
    loadChildren: () => import("app/pages/recoverypassword/recoverypassword.module").then((m) => m.RecoverypasswordModule),
  },
  {
    path: "pages",
    component: PagesComponent,
    children: [
      { path: "", redirectTo: "dashboard", pathMatch: "full" },
      {
        path: "dashboard",
        loadChildren: () => import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
        canActivate: [RoleGuard],
        data: {
          path: "dashboard",
        },
      },
      {
        path: "miempresa",
        loadChildren: () => import("./empresa/empresa.module").then((m) => m.EmpresaModule),
        canActivate: [RoleGuard],
        data: {
          path: "miempresa",
        },
      },
      {
        path: "bancos",
        loadChildren: () => import("./bancos/bancos.module").then((m) => m.BancosModule),
        canActivate: [RoleGuard],
        data: { path: "bancos" },
      },
      {
        path: "editors",
        loadChildren: () => import("./editors/editors.module").then((m) => m.EditorsModule),
      },
      {
        path: "components",
        loadChildren: () => import("./components/components.module").then((m) => m.ComponentsModule),
      },
      {
        path: "charts",
        loadChildren: () => import("./charts/charts.module").then((m) => m.ChartsModule),
      },
      { path: "ui", loadChildren: () => import("./ui/ui.module").then((m) => m.UiModule) },
      {
        path: "listaEgresos",
        loadChildren: () => import("./egresos/egresos.module").then((m) => m.EgresosModule),
        canActivate: [RoleGuard],
        data: { path: "listaEgresos" },
      },
      {
        path: "nomina",
        loadChildren: () => import("./trabajadores/trabajadores.module").then((m) => m.TrabajadoresModule),
        canActivate: [RoleGuard],
        data: { path: "nomina" },
      },
      {
        path: "listaIngresos",
        loadChildren: () => import("./ingresos/ingresos.module").then((m) => m.IngresosModule),
        canActivate: [RoleGuard],
        data: { path: "listaIngresos" },
      },
      {
        path: "usuarios",
        loadChildren: () => import("./usuarios/usuarios.module").then((m) => m.UsuariosModule),
        canActivate: [RoleGuard],
        data: { path: "usuarios" },
      },
      {
        path: "comprobantesFiscales",
        loadChildren: () => import("./comprobantesfiscales/comprobantesfiscales.module").then((m) => m.ComprobantesfiscalesModule),
        canActivate: [RoleGuard],
        data: { path: "comprobantesFiscales" },
      }
    ],
  },
];

export const routing: ModuleWithProviders<NgaModule> = RouterModule.forChild(routes);
