import { Injectable } from "@angular/core";
import { HttpParams, HttpClient } from "@angular/common/http";
import { ComprobanteFiscal } from "../entidades/comprobante-fiscal";
import { HttpHelper } from "../http-helper";
import "rxjs/add/operator/toPromise";
import { ComprobanteCancelacion } from "app/entidades/comprobante-cancelacion";

@Injectable()
export class ComprobanteFiscalService {
  private headers = HttpHelper.headersJSON;
  private comprobanteFiscalUrl = HttpHelper.url + "comprobanteFiscal";

  constructor(private http: HttpClient) {}

  private handleError(error: any): Promise<any> {
    let resp = error;
    console.error("ComprobanteFiscalService - ERROR: ", resp);
    if (resp.error) {
      return Promise.reject(resp.error);
    }
    return Promise.reject(error.message || error);
  }

  getDetalle(id: number): Promise<ComprobanteFiscal> {
    const url = `${this.comprobanteFiscalUrl}/detalle/${id}`;
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .get<any>(url, { headers: this.headers })
        .toPromise()
        .then((response) => response as Promise<ComprobanteFiscal>)
        .catch(this.handleError);
    }
  }

  getUuidRelation(id: number): Promise<any> {
    const url = `${this.comprobanteFiscalUrl}/getUuidRelation/${id}`;
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .get<any>(url, { headers: this.headers })
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  update(dataComprobanteFiscal: ComprobanteFiscal): Promise<any> {
    const url = `${this.comprobanteFiscalUrl}/update`;
    let token = localStorage.getItem("token");
    this.headers = this.headers.set("token", token);
    return this.http
      .post<any>(url, JSON.stringify(dataComprobanteFiscal), { headers: this.headers })
      .toPromise()
      .then((res) => res as Promise<any>)
      .catch(this.handleError);
  }

  create(dataComprobanteFiscal: ComprobanteFiscal): Promise<any> {
    const url = `${this.comprobanteFiscalUrl}/crear`;
    let token = localStorage.getItem("token");
    this.headers = this.headers.set("token", token);
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .post<any>(url, JSON.stringify(dataComprobanteFiscal), { headers: this.headers })
        .toPromise()
        .then((res) => res as Promise<any>)
        .catch(this.handleError);
    }
  }

  crearComprobanteUpload(dataComprobanteFiscal: ComprobanteFiscal, nameXml: string): Promise<any> {
    let params = new HttpParams();
    if (nameXml) {
      params = params.append("nameXml", nameXml);
    }
    const url = `${this.comprobanteFiscalUrl}/crear`;
    let token = localStorage.getItem("token");
    this.headers = this.headers.set("token", token);
    if (token) {
      this.headers = this.headers.set("token", token);
      let options = { headers: this.headers, params: params };
      return this.http
        .post<any>(url, JSON.stringify(dataComprobanteFiscal), options)
        .toPromise()
        .then((res) => res as Promise<any>)
        .catch(this.handleError);
    }
  }

  timbrado(id: number) {
    const url = `${this.comprobanteFiscalUrl}/timbrar/${id}`;
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .get<any>(url, { headers: this.headers })
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  cancelar(id: number, comprobanteC: ComprobanteCancelacion) {
    const url = `${this.comprobanteFiscalUrl}/cancelar/${id}`;
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .post<any>(url, JSON.stringify(comprobanteC), { headers: this.headers })
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  remove(id: number): Promise<any> {
    const url = `${this.comprobanteFiscalUrl}/eliminar/${id}`;
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .get<any>(url, { headers: this.headers })
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  getComprobanteFiscales(filterQuery: string, sortBy: string, sortOrder: string, rowsOnPage: number, page: number) {
    const url = `${this.comprobanteFiscalUrl}/list`;
    let params = new HttpParams();
    if (filterQuery) {
      params = params.append("filterQuery", filterQuery);
    }

    if (sortBy) {
      params = params.append("sortBy", sortBy);
    }

    if (sortOrder) {
      params = params.append("sortOrder", sortOrder);
    }
    if (rowsOnPage) {
      params = params.append("rowsOnPage", rowsOnPage + "");
    }
    if (page) {
      params = params.append("page", page + "");
    }

    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      let options = { headers: this.headers, params: params }; // Create a request option
      return this.http
        .get<any>(url, options)
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  getUsosCFDI(sortBy: string, sortOrder: string) {
    const url = `${this.comprobanteFiscalUrl}/listUsoCdi`;
    let params = new HttpParams();

    if (sortBy) {
      params = params.append("sortBy", sortBy);
    }

    if (sortOrder) {
      params = params.append("sortOrder", sortOrder);
    }

    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      let options = { headers: this.headers, params: params }; // Create a request option
      return this.http
        .get<any>(url, options)
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  getFormaPagos() {
    const url = `${this.comprobanteFiscalUrl}/listFormasPago`;
    let params = new HttpParams();
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      let options = { headers: this.headers, params: params }; // Create a request option
      return this.http
        .get<any>(url, options)
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  getTipoFacturas() {
    const url = `${this.comprobanteFiscalUrl}/listTipoFactura`;
    let params = new HttpParams();
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      let options = { headers: this.headers, params: params }; // Create a request option
      return this.http
        .get<any>(url, options)
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  getMetodoPago() {
    const url = `${this.comprobanteFiscalUrl}/listMetodoPago`;
    let params = new HttpParams();
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      let options = { headers: this.headers, params: params }; // Create a request option
      return this.http
        .get<any>(url, options)
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  getTipoRelacion() {
    const url = `${this.comprobanteFiscalUrl}/listTipoRelacion`;
    let params = new HttpParams();
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      let options = { headers: this.headers, params: params }; // Create a request option
      return this.http
        .get<any>(url, options)
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  getMotivosCancelacion() {
    const url = `${this.comprobanteFiscalUrl}/listMotivoCancelacion`;
    let params = new HttpParams();
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      let options = { headers: this.headers, params: params }; // Create a request option
      return this.http
        .get<any>(url, options)
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  getTipoMonedas() {
    const url = `${this.comprobanteFiscalUrl}/listMoneda`;
    let params = new HttpParams();
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      let options = { headers: this.headers, params: params }; // Create a request option
      return this.http
        .get<any>(url, options)
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  getAcuse(id: number) {
    const url = `${this.comprobanteFiscalUrl}/checarAcuse/${id}`;
    let params = new HttpParams();
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      let options = { headers: this.headers, params: params }; // Create a request option
      return this.http
        .get<any>(url, options)
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  autocomplete(filterQuery: string) {
    let params = new HttpParams();
    const url = `${this.comprobanteFiscalUrl}/autocomplete`;
    if (filterQuery) {
      params = params.append("filterQuery", filterQuery);
    }
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      let options = { headers: this.headers, params: params }; // Create a request option
      return this.http
        .get<any>(url, options)
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  autocompleteUsoCfdi(filterQuery: string) {
    let params = new HttpParams();
    const url = `${this.comprobanteFiscalUrl}/autocompleteUsoCfdi`;
    if (filterQuery) {
      params = params.append("filterQuery", filterQuery);
    }
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      let options = { headers: this.headers, params: params }; // Create a request option
      return this.http
        .get<any>(url, options)
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  crearXml(id: number) {
    const url = `${this.comprobanteFiscalUrl}/generaXML/${id}`;
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .get<any>(url, { headers: this.headers })
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  downloadXML(id: number) {
    let token = localStorage.getItem("token");
    if (token) {
      const url = `${HttpHelper.url}files/download/4/${id}.xml/${token}`;
      window.location.href = url;
    }
  }

  crearPDF(id: number) {
    const url = `${this.comprobanteFiscalUrl}/crearPDF/${id}`;
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .get<any>(url, { headers: this.headers })
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  downloadPDF(id: number) {
    let token = localStorage.getItem("token");
    if (token) {
      const url = `${HttpHelper.url}files/download/4/${id}.pdf/${token}`;
      window.location.href = url;
    }
  }
  getCSVInfo(fields, clienteId: number) {
    let token = localStorage.getItem("token");
    if (token) {
      var url = `${this.comprobanteFiscalUrl}/exportReport/${token}`;
      if (fields) {
        fields.forEach((field, ind) => {
          let lett = ind > 0 ? "&" : "?";
          url = url + lett + field + "=" + encodeURIComponent("true");
        });
      }
      url = url + "&" + "cliente=" + clienteId;
      console.log(url);
      window.location.href = url;
    }
  }
}
