import { Empresa } from "./empresa";
import { Usuario } from "./usuario";
export class Sucursal {
  id: number;
  nombre: string;
  empresa: Empresa;
  fechaCreacion: number;
  fechaModificacion: number;
  responsable: Usuario;
  calle: string;
  cp: string;
  numeroInterior: string;
  numeroExterior: string;
  colonia: string;
  localidad: string;
  municipio: string;
  estado: string;
  pais: string;
  isMatriz: boolean;
  ver: boolean;
}
