import { Injectable } from "@angular/core";
import { HttpParams, HttpClient } from "@angular/common/http";
import { ClienteProveedor } from "../entidades/cliente-proveedor";
import { HttpHelper } from "../http-helper";
import "rxjs/add/operator/toPromise";

@Injectable()
export class ClienteProveedorService {
  private headers = HttpHelper.headersJSON;
  private clienteProveedorUrl = HttpHelper.url + "clienteProveedor";

  constructor(private http: HttpClient) {}

  private handleError(error: any): Promise<any> {
    let resp = error;
    if (resp.error) {
      console.error("ConceptoService - ERROR: ", resp.error);
      return Promise.reject(resp.error);
    }
    return Promise.reject(error.message || error);
    
  }

  getDetalle(id: number) {
    const url = `${this.clienteProveedorUrl}/detalle/${id}`;
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .get<any>(url, { headers: this.headers })
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  update(dataClienteProveedor: ClienteProveedor): Promise<any> {
    const url = `${this.clienteProveedorUrl}/update`;
    let token = localStorage.getItem("token");
    this.headers = this.headers.set("token", token);
    return this.http
      .post<any>(url, JSON.stringify(dataClienteProveedor), { headers: this.headers })
      .toPromise()
      .then((res) => res as Promise<any>)
      .catch(this.handleError);
  }

  create(dataClienteProveedor: ClienteProveedor): Promise<any> {
    //para crear clientes en la variable tipo pandar 1 y para proveedores 2
    const url = `${this.clienteProveedorUrl}/crear`;
    let token = localStorage.getItem("token");
    this.headers = this.headers.set("token", token);
    return this.http
      .post<any>(url, JSON.stringify(dataClienteProveedor), { headers: this.headers })
      .toPromise()
      .then((res) => res as Promise<any>)
      .catch(this.handleError);
  }

  remove(id: number): Promise<any> {
    const url = `${this.clienteProveedorUrl}/eliminar/${id}`;
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .get<any>(url, { headers: this.headers })
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  getClienteProveedor(filterQuery: string, sortBy: string, sortOrder: string, rowsOnPage: number, page: number, tipo: number) {
    //NOTA: MANDAR EN TIPO 1 PARA OBTENER LISTADO DE CLIENTES Y 2 PARA OBTENER LISTADO DE PROVEEDORES
    const url = `${this.clienteProveedorUrl}/list`;
    let params = new HttpParams();
    if (filterQuery) params = params.append("filterQuery", filterQuery);

    if (sortBy) params = params.append("sortBy", sortBy);

    if (sortOrder) {
      params = params.append("sortOrder", sortOrder);
    }
    if (rowsOnPage) {
      params = params.append("rowsOnPage", rowsOnPage + "");
    }
    if (page) {
      params = params.append("page", page + "");
    }
    if (tipo) {
      params = params.append("tipo", tipo + ""); //para obtener el listado de clientes mandar en la variable tipo 1 y para listar proveedores 2
    }
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      let options = { headers: this.headers, params: params }; // Create a request option
      return this.http
        .get<any>(url, options)
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }
  
  autocomplete(filterQuery: string, tipo: number) {
    //para el autocomplete de clientes mandar en la variable tipo 1 y para el autocomplete de proveedores 2
    let params = new HttpParams();
    const url = `${this.clienteProveedorUrl}/autocomplete/${filterQuery}/${tipo}`;
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      let options = { headers: this.headers, params: params }; // Create a request option
      return this.http
        .get<any>(url, options)
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  getCSVInfoCliente() {
    let token = localStorage.getItem("token");
    if (token) {
      const url = `${this.clienteProveedorUrl}/clienteCSV/${token}`;
      window.location.href = url;
    }
  }

  getCSVInfoProveedor() {
    let token = localStorage.getItem("token");
    if (token) {
      const url = `${this.clienteProveedorUrl}/proveedorCSV/${token}`;
      window.location.href = url;
    }
  }

  autocompleteRFC(filterQuery: string, tipo: number) {
    //para el autocomplete de clientes mandar en la variable tipo 1 y para el autocomplete de proveedores 2
    let params = new HttpParams();
    const url = `${this.clienteProveedorUrl}/autocompleteRFC/${filterQuery}/${tipo}`;
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      let options = { headers: this.headers, params: params }; // Create a request option
      return this.http
        .get<any>(url, options)
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }
}
