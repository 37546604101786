import { ClienteProveedor } from "./cliente-proveedor";
import { Empresa } from "./empresa";
import { TipoRegimen } from "./tipo-regimen";
export class RegimenFiscal {
  id: number;
  tipoRegimen: TipoRegimen;
  fechaRegistro: number;
  fechaModificacion: number;
  empresa: Empresa;
  clienteProveedor: ClienteProveedor;
  regimenDefault: boolean; //variable para saber si se envia correo , true -> envia correo de Bienvenida,false -> cualquier otro caso
  ver: boolean;
}
