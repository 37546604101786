import { Empresa } from "./empresa";
export class Certificado {
  id: number;
  archivoCertificado: string;
  numeroCertificado: string;
  validoDesde: number;
  validoHasta: number;
  archivoLlave: string;
  fechaRegistro: number;
  fechaModificacion: number;
  empresa: Empresa;
  password: string;
  ver: boolean;
}
