import { ObligacionesFiscalesPerfil } from "./obligaciones-fiscales-perfil";

export class PerfilEmpresa{
    id: number;
    fechaCreacion: number;
    persona: string;
    tipoPersona: string;
    regimenFiscal: string;
    actividadEconomica: string;
    noFacturas: string;
    tipoIngresos: string;
    facturasPublicoGral: boolean;
    noTrabajadores: string;
    rotacionPersonal: string;
    manejaInventario: boolean;
    obligacionesFiscalesPerfilList: Array<ObligacionesFiscalesPerfil>;
}