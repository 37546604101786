import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { Empresa } from "./entidades/empresa";

@Injectable({
  providedIn: "root",
})
export class SharedService {
  public empresa: Empresa;

  private callParentMethodSource = new Subject<string>();
  callParentMethod$ = this.callParentMethodSource.asObservable();
  constructor() {}

  callParentMethod(metodo) {
    this.callParentMethodSource.next(metodo);
  }

  public getEmpresa() {
    return this.empresa;
  }

  public setEmpresa(empresa: Empresa) {
    this.empresa = empresa;
  }
}
