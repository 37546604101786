import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HttpHelper } from "app/http-helper";

@Injectable()
export class CartaPorteService {
  private headers = HttpHelper.headersJSON;
  private cartaPorteUrl = HttpHelper.url + "cartaporte";

  constructor(private http: HttpClient) {}

  private handleError(error: any): Promise<any> {
    let resp = error;
    if (resp.error) {
      console.error("CartaPorteService - ERROR: ", resp.error);
      return Promise.reject(resp.error);
    }
    return Promise.reject(error.message || error);
  }

  listResidenciaFiscal(sortBy: string, sortOrder: string) {
    const url = `${this.cartaPorteUrl}/listResidenciaFiscal`;
    let params = new HttpParams();

    if (sortBy) {
      params = params.append("sortBy", sortBy);
    }

    if (sortOrder) {
      params = params.append("sortOrder", sortOrder);
    }

    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      let options = { headers: this.headers, params: params }; // Create a request option
      return this.http
        .get<any>(url, options)
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  listEmbalajes(sortBy: string, sortOrder: string) {
    const url = `${this.cartaPorteUrl}/listEmbalajes`;
    let params = new HttpParams();

    if (sortBy) {
      params = params.append("sortBy", sortBy);
    }

    if (sortOrder) {
      params = params.append("sortOrder", sortOrder);
    }

    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      let options = { headers: this.headers, params: params }; // Create a request option
      return this.http
        .get<any>(url, options)
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  listUnidadPeso(sortBy: string, sortOrder: string) {
    const url = `${this.cartaPorteUrl}/listUnidadPeso`;
    let params = new HttpParams();

    if (sortBy) {
      params = params.append("sortBy", sortBy);
    }

    if (sortOrder) {
      params = params.append("sortOrder", sortOrder);
    }

    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      let options = { headers: this.headers, params: params }; // Create a request option
      return this.http
        .get<any>(url, options)
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  autocompleteFraccionArancelaria(filterQuery: string): Promise<any> {
    const url = `${this.cartaPorteUrl}/autocompleteFraccionArancelaria/${filterQuery}`;
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .get<any>(url, { headers: this.headers })
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  getDetalleFraccionArancelaria(id: number) {
    const url = `${this.cartaPorteUrl}/detalleFraccionArancelaria/${id}`;
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .get<any>(url, { headers: this.headers })
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }

  getDetalle(id: number) {
    const url = `${this.cartaPorteUrl}/detalle/${id}`;
    let token = localStorage.getItem("token");
    if (token) {
      this.headers = this.headers.set("token", token);
      return this.http
        .get<any>(url, { headers: this.headers })
        .toPromise()
        .then((response) => response as Promise<any>)
        .catch(this.handleError);
    }
  }
}
